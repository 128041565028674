import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ProjectWrapper from "./ProjectWrapper";
import Footer from "components/common/Footer";
function ProjectsPage() {
  return (
    <>
      <Breadcrumb pageName="Projects" />
      <ProjectWrapper />
      <Footer/>
    </>
  );
}

export default ProjectsPage;

import Table from 'components/common/table/Table';
import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';
import { instance } from 'index';
import { useSelector } from 'react-redux';
import Loader from 'components/common/Loader';

export default function Payments() {
  const { auth } = useSelector((state) => state);
  const {access} = useSelector((state) => state.auth.userInfo)
  const [isLoading, setIsLoading] = useState(false);
  const [userPayment, setUserPayment] = useState(null);
  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
            >
              {cellProps.row.original.status}
            </div>
          );
        },
      },
      {
        Header: 'Channel',
        accessor: 'channel',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
      },
      {
        Header: 'Recipiet',
        accessor: 'receipt',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
        Cell: (cellProps) => {
          return (
            <div className='d-flex align-items-center justify-content-center'>
            <a
              href={cellProps.row.original.receipt}
              target='_blank'
              className='text-white text-decoration-underline bg-transparent border-0'
            >
              Download
            </a>
          </div>
          );
        },
      },
    ],
    []
  );

  const getAllPayments = async (userId) => {
    
    setIsLoading(true);
    try {
     
      const result = await instance.get(`employee-payment/`,{
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      
      if (result?.status === 200) {
        setIsLoading(false);
        const paymentDataResponse = result?.data.results.filter(
          (item) => item.user.id === userId
        )
        setUserPayment(paymentDataResponse); 
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };
  useEffect(() => {
    if (auth?.userInfo?.user?.id) 
    getAllPayments(auth?.userInfo?.user?.id);
  }, []);
  return (
		<>
            {isLoading && <Loader />}
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white PaytableHeading'>Payment Dashbord</h6>
			</div>

			<div className='row'>
				<div className='col-12'>
					{userPayment && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={userPayment}
							total={userPayment?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={
								() => console.log('Next')
							}
							prevHandler={
								() => console.log('Prev')
							}
						/>
					)}
				</div>
			</div>
		</>
	)
}

import { instance } from 'index'
import Header from '../../common/Header'
import Footer from 'components/common/Footer'
import React, { useState, useEffect, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { icons } from 'contants'
import Breadcrumb from 'components/common/Breadcrumb'
import Loader2 from 'components/common/Loader2'


const Career = () => {
	const [benefits, setBenefits] = useState()
	const [career, setCareer] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [resume, setResume] = useState()
	const history = useHistory()

	const getBenefits = async () => {
		try {
			const result = await instance.get('benefits/')

			if (result?.status === 200) {
				setIsLoading(false)
				setBenefits(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const getCareer = async () => {
		try {
			const result = await instance.get('career/')

			if (result?.status === 200) {
				setIsLoading(false)
				setCareer(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

	useEffect(() => {
		getBenefits()
		getCareer()
	}, [])

	const getApplication = (id) => {
		history.push({
			pathname: '/career/application',
			state: { message: id },
		})
		scrollTop()
	}

	return (
		<>
			{/* <Header /> */}

			{/* <Breadcrumb pageName='Career' /> */}
			
			<div className='container mb-2 padding-20'>
				<div className='mt-2'>
					<h1 className='feed-heading'> Career </h1>
					<h1 className='main-title '>Open Position</h1>
					<p className='desc '>Let's boost your skill together</p>
				</div>
				<section className='career__wrapper'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<div className='card bg-transparent table-card overflow-auto tbl' >
									<div
										className='table-responsive-md bd-table'
										style={{
											height: 'fit-content',
											maxHeight: '450px',
											overflowY: 'auto',
										}}
									>
									{isLoading && <Loader2 />}
									{career?.results && career.results.length === 0 ? (
										   <p style={{textAlign:'center', fontSize:'30px', color:'red'}}>Keep looking for the latest jobs.</p>
										   ) : (	
										<table className='table table-borderless career-table'>
											<thead>
												<tr className='table__headRow'>
													<th scope='col-sm-1'>
														<p className='text-start'>Position</p>
													</th>
													<th scope='col-sm-5'>
														<p>Description</p>
													</th>
													<th scope='col-sm-2'>
														<p className='text-start'>Type</p>
													</th>
													<th scope='col-sm-2'>
														<p>Location</p>
													</th>
													<th scope='col-sm-2'>
														<p>Status</p>
													</th>
												</tr>
											</thead>
											<tbody>
												{career?.results?.map((data, index) => (
													<Fragment key={data?.id}>
														<tr key={data?.id}>
															<td className='position'>
																<div>
																	<p>{data?.title}</p>
																</div>
															</td>
															<td className='desc'>
																<p>
																	{data?.description?.slice(0, 190)}{' '}
																	{data?.description > 190 ? '...' : ''}
																</p>
															</td>
															<td className='position'>
																<div>
																	<p style={{ color: '#b9b7b7' }}>
																		{data?.type}{' '}
																	</p>
																</div>
															</td>
															<td className='location'>
																<p>{data?.location}</p>
															</td>
															<td className='d-flex'>
																{/* <div className='status'>Open</div> */}
																{data?.is_active === true ? (
																	// <Link
																	//   className='btn apply-btn me-3 align-items-center d-flex'
																	//   to={'/career/application'} state={{data.id}}
																	// >
																	//   Apply
																	// </Link>
																	<button
																		className='btn apply-btn me-3 align-items-center d-flex'
																		onClick={() => getApplication(data?.id)}
																	>
																		Apply
																	</button>
																) : (
																	''
																)}
															</td>
														</tr>
														<tr className='tr__border'>
															<td
																colSpan={5}
																style={{ padding: '10px 0', margin: 0 }}
															>
																<hr
																	className='m-0 p-0'
																	style={{
																		background:
																			'linear-gradient(90deg, #d90a2c 1.05%, #730000 100%)',
																		height: '2px',
																	}}
																/>
															</td>
														</tr>
													</Fragment>
												))}
											</tbody>
										</table>
										   )}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className='benefits-container'>
				<div className='row'>
					<div className='col-12'>
						<div className='section-heading text-center'>
							<p className='main-title'>
								Perks and <span style={{ color: '#F50808' }}>Benefits</span>
							</p>
							<p className='description'>
								Bugdev cares about the financial and emotional well-being of its
								employees. While working <br />
								with Bugdev, you get the following perks and benefits:
							</p>
						</div>
					</div>
					
					<div className='col-md-12 col-lg-12 col-xl-12 mt-4 mb-5'>
						<div className='row '>
						
							{benefits?.results?.map((data, index) => {
								return (
									
									<div
										className='col-sm-6 col-md-6 col-lg-4 col-xl-4 '
										key={data?.id}
									>
										{isLoading && <Loader2 />}
										<div className='single-service'>
											<span className='count'>{index + 1}</span>
											<div className='service-icon'>
												<i>
													<img src={data?.image} alt='images' />
												</i>
											</div>
											<div className='service-content'>
												<h4>{data?.name}</h4>
												<p>{data?.description}</p>
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Career

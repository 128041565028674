import React, { useMemo, useState, useEffect } from 'react';
import Table from './components/RequestTable'
import { timeTable } from 'contants/data'
import moment from 'moment'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { useSelector } from 'react-redux'
import { instance } from 'index'
import { toast } from 'react-toastify'
import Loader from 'components/common/Loader'
import { useHistory } from 'react-router-dom'

export default function Request() {
	const [tableList, setTableList] = useState(timeTable)
	const [modal, setModal] = React.useState(false)
	const toggle = () => {
		setModal(!modal)
		setIsCheck(true)
		setFormValues({
			title: '',
			description: '',
		})
	}
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(true)
	const [requestsList, setRequestsList] = useState([])
	const history = useHistory()
	const [getId, setGetId] = useState('')
	const [isCheck, setIsCheck] = useState(true)

	const [formValues, setFormValues] = useState({ title: '', description: '' })
	const [isBtnBg, setIsBtnBg] = useState({
		my_request: false,
		emp_request: true,
	})

	const getRequest = async (userId) => {
		setIsLoading(true)
		try {
			const result = await instance.get('internal-requests/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setRequestsList([
					...result?.data?.results
						?.filter((item) => item.user.id === userId)
						?.map((request) => {
							return {
								...request,
								client: `${request?.user?.first_name} ${request?.user?.last_name}`,
							}
						}),
				])
			}
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}


	const getDelete = async (itemId) => {
		setIsLoading(true)
		try {
			const result = await instance.delete(`internal-requests/${itemId}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			setIsLoading(false)
			if (result?.status === 204) {
				setIsLoading(false)
				getRequest(auth?.userInfo?.user?.id)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const editToggle = (idData) => {
		setFormValues({
			...formValues,
			description: idData?.row?.original?.description,
			title: idData?.row?.original?.title,
		})
		setGetId(idData)
		setModal(!modal)
		setIsCheck(false)
	}

	const getEmployeeRequest = async (userId) => {
		setIsLoading(true)
		try {
			const result = await instance.get('internal-requests/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setRequestsList([
					...result?.data?.results
						?.filter((item) => item.user.id !== userId)
						?.map((request) => {
							return {
								...request,
								client: `${request?.user?.first_name} ${request?.user?.last_name}`,
							}
						}),
				])
			}
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	const getRequestItem = async (id) => {
		history.push({
			pathname: '/account-dashboard/request-detail',
			state: { message: id },
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'client',
				filterable: false,
				width: 300,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Title',
				accessor: 'title',
				filterable: false,
				width: 300,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Date',
				accessor: 'created_at',
				filterable: false,
				width: 300,
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
						: '',
			},
			{
				Header: 'Status',
				accessor: 'status',
				filterable: false, 
				width: 300,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
						>
							{cellProps.row.original.status}
						</div>
					)
				},
			},

			{
				Header: 'Action',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div className='d-flex align-items-center justify-content-center gap-2'>
							{cellProps.row.original.user.id === auth?.userInfo?.user?.id && (
								<>
									<button
										className='border-0 bg-transparent'
										onClick={() => getDelete(cellProps.row.original.id)}
									>
										<img
											src={process.env.PUBLIC_URL + '/images/delete-icon.png'}
											alt='images'
											style={{ cursor: 'pointer' }}
										/>
									</button>
									<button
										className='border-0 bg-transparent'
										onClick={() => editToggle(cellProps)}
									>
										<img
											src={process.env.PUBLIC_URL + '/images/edit-icon.png'}
											alt='images'
											style={{ cursor: 'pointer' }}
										/>
									</button>
								</>
							)}
							<button
								className='border-0 bg-transparent'
								onClick={() => getRequestItem(cellProps.row.original.id)}
							>
								<i
									className='fa fa-eye'
									style={{
										color: 'white',
										cursor: 'pointer',
										fontSize: '22px',
									}}
								></i>
							</button>
						</div>
					)
				},
			},
		],
		[]
	)

	const sendRequest = async () => {
		if (!formValues?.title || !formValues?.description)
			return toast.error('All fields are required!')
		const data = {	...formValues,
			user: auth?.userInfo?.user?.id,
			is_active:true
		}
		try {
			const result = await instance.post('internal-requests/',data, {
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 201) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getRequest(auth?.userInfo?.user?.id)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getEdit = async () => {
		const data = {...formValues,
			user: auth?.userInfo?.user?.id,
			is_active:true
		}
		try {
			const result = await instance.patch(
				`internal-requests/${getId?.row?.original?.id}/`,data,
				{
					headers: {
						'Authorization': `Bearer ${access}`,
					  },
				}
			)

			if (result?.status === 200) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getRequest(auth?.userInfo?.user?.id)
				setFormValues({
					...formValues,
					description: '',
					title: '',
				})
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const handleEmployeeRequest = () => {
		setIsBtnBg({
			my_request: true,
			emp_request: false,
		})
		getEmployeeRequest(auth?.userInfo?.user?.id)
	}

	const handleMyRequest = () => {
		setIsBtnBg({
			my_request: false,
			emp_request: true,
		})
		getRequest(auth?.userInfo?.user?.id)
	}

	useEffect(() => {
		getRequest(auth?.userInfo?.user?.id)
	}, [])

	return (
		<>
			{isLoading && <Loader />}
			<div className='container'>
				<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
					<h6 className='text-white tableHeading'>Request Dashbord</h6>
					<div className='cmn-btn'>
						<button className='mb-0 mt-1' onClick={toggle}>
							Post Request
						</button>
					</div>
				</div>
				{isCheck ? (
					<div style={{ display: 'block' }}>
						<Modal
							isOpen={modal}
							toggle={() => setModal(true)}
							className='addtask-popups'
						>
							<ModalBody>
								<h3 className='text-center text-white'>Submit Your Request</h3>
								<div className='addtask-body'>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Title:
										</label>
										<input
											type='text'
											className='form-control'
											value={formValues?.title}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, title: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='message-text' className='col-form-label'>
											Description:
										</label>
										<textarea
											className='form-control'
											value={formValues?.description}
											onChange={(e) =>
												setFormValues({
													...formValues,
													description: e.target.value,
												})
											}
											style={{ height: '150px', borderRadius: '20px' }}
											id='message-text'
										></textarea>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='cmn-btn'>
									<button onClick={toggle}>Close</button>
								</div>
								<div className='cmn-btn'>
									<button onClick={sendRequest}>Submit</button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				) : (
					<div style={{ display: 'block' }}>
						<Modal
							isOpen={modal}
							toggle={() => setModal(true)}
							className='addtask-popups'
						>
							<ModalBody>
								<h3 className='text-center text-white'>Edit Your Request</h3>
								<div className='addtask-body'>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Title:
										</label>
										<input
											type='text'
											className='form-control'
											value={formValues?.title}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, title: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='message-text' className='col-form-label'>
											Description:
										</label>
										<textarea
											className='form-control'
											value={formValues?.description}
											onChange={(e) =>
												setFormValues({
													...formValues,
													description: e.target.value,
												})
											}
											style={{ height: '150px', borderRadius: '20px' }}
											id='message-text'
										></textarea>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='cmn-btn'>
									<button onClick={toggle}>Close</button>
								</div>
								<div className='cmn-btn'>
									<button onClick={getEdit}>Submit</button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				)}
				<div className='row'>
					<div className='col-12 px-4'>
						{isLoading ? (
							<div className='w-100 d-flex align-items-center justify-content-center height300'>
								<div className='spinner-border text-danger' role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							<Table
								tableStyles={{ tableLayout: 'fixed' }}
								columns={columns}
								initialState={{
									columnVisibility: { conversion: false },
								}}
								handleMyRequest={handleMyRequest}
								handleEmployeeRequest={handleEmployeeRequest}
								isBtnBg={isBtnBg}
								data={requestsList}
								total={requestsList?.length}
								customPageSize={1}
								divclassName='table-responsive mb-1'
								tableclassName='align-middle table-nowrap'
								theadclassName='table-light text-muted'
								getRowId={(row, relativeIndex, parent) => row?.id}
								fetchData={() => console.log('fetchData')}
								pageCount={3}
								currentPage={1}
								isNextPage={2}
								isPrevPage={null}
								nextHandler={() => console.log('Next')}
								prevHandler={() => console.log('Prev')}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export const tableData = [
  {
    id: 1,
    position: 'Frontend Developer',
    time: 'Full Time',
    description:
      'We are looking for proactive Frontend Developer to join our development team. The ideal candidate should be a team player, passionate about Frontend tasks with a customer-oriented approach.',
    location: 'Lahore',
    status: 'Open',
    date: '15/07/2021',
  },
  {
    id: 2,
    position: 'Backend Developer',
    time: 'Full Time',
    description:
      'We are looking for proactive Backend Developer to join our development team. The ideal candidate should be a team player, passionate about Backend tasks with a customer-oriented approach.',
    location: 'Islamabad',
    status: 'Open',
    date: '05/09/2021',
  },
  {
    id: 3,
    position: 'Full Stack Developer',
    time: 'Full Time',
    description:
      'We are looking for proactive Full Stack Developer to join our development team. The ideal candidate should be a team player, passionate about Full Stack tasks with a customer-oriented approach.',
    location: 'Karachi',
    status: 'Open',
    date: '10/10/2021',
  },
  {
    id: 4,
    position: 'Laravel Developer',
    time: 'Full Time',
    description:
      'We are looking for proactive Laravel Developer to join our development team. The ideal candidate should be a team player, passionate about Laravel tasks with a customer-oriented approach.',
    location: 'KPK',
    status: 'Open',
    date: '25/11/2022',
  },
];

export const timeTable = [
  {
    id: 1,
    project: 'Project',
    client: 'John Paul',
    title: 'Advance Salary',
    previous_time: '',
    over_time: '2:00',
    no_hours: '03',
    date: '3/1/22',
    status: 'approve',
    amount: '450',
    projectStatus: 'Deliverd',
    clientEmail: 'john@bugdev.co.uk',
    tag: 'VIP',
    desigenation: 'Frontend Developer',
    department: 'Tech',
    performance: 'Good',
    time_in: '9:30 AM',
    time_out: '5:30 PM',
    work_time: '8:00 hour',
    progress: '90%',
    resource: "Jack",
    project_status:'On Track',
  },
  {
    id: 2,
    project: 'Like-Minded',
    client: 'Derick Morono',
    title: 'Leave Application',
    previous_time: '',
    over_time: '1:00',
    no_hours: '05',
    date: '5/7/22',
    status: 'awaiting',
    amount: '400',
    projectStatus: 'New',
    clientEmail: 'derick@bugdev.co.uk',
    tag: 'Standard',
    desigenation: 'Python Developer',
    department: 'Marketing',
    performance: 'Moderate',
    time_in: '9:30 AM',
    time_out: '3:30 PM',
    work_time: '6:00 hour',
    progress: '70%',
    resource: "Lee",
    project_status:'Bug Fixing',
  },
  {
    id: 3,
    project: 'Ecosystem',
    client: 'Mark Philips',
    title: 'Loan Application',
    previous_time: '',
    over_time: '2:00',
    no_hours: '35',
    date: '7/9/22',
    status: 'reject',
    amount: '150',
    projectStatus: 'Pending',
    clientEmail: 'mark@bugdev.co.uk',
    tag: 'Standard',
    desigenation: 'Full Stack Developer',
    department: 'Design',
    performance: 'Poor',
    time_in: '11:30 AM',
    time_out: '5:30 PM',
    work_time: '6:00 hour',
    progress: '85%',
    resource: "Michel",
    project_status:'Block',
  },
  {
    id: 4,
    project: 'Bugdev',
    client: 'John Anderson',
    title: 'Application Design',
    previous_time: '',
    over_time: '4:00',
    no_hours: '15',
    date: '7/10/22',
    status: 'onboarding',
    amount: '490',
    projectStatus: 'Pending',
    clientEmail: 'john@bugdev.co.uk',
    tag: 'Mondatory',
    desigenation: 'Backend Developer',
    department: 'Tech',
    performance: 'Moderate',
    time_in: '10:30 AM',
    time_out: '5:30 PM',
    work_time: '7:00 hour',
    progress: '60%',
    resource: "Derick",
    project_status:'On Track',
  },
  {
    id: 5,
    project: 'ECY',
    client: 'Derick Morono',
    title: 'Leave Application',
    previous_time: '',
    over_time: '5:00',
    no_hours: '25',
    date: '7/11/22',
    status: 'awaiting',
    amount: '350',
    projectStatus: 'New',
    clientEmail: 'derick@bugdev.co.uk',
    tag: 'VIP',
    desigenation: 'Frontend Developer',
    department: 'Design',
    performance: 'Good',
    time_in: '1:00 PM',
    time_out: '9:30 PM',
    work_time: '9:30 hour',
    progress: '90%',
    resource: "John Paul",
    project_status:'Block',
  },
  {
    id: 6,
    project: 'Bugdev',
    client: 'Mark Philips',
    title: 'Application Design',
    previous_time: '',
    over_time: '12:00',
    no_hours: '55',
    date: '8/12/22',
    status: 'approve',
    amount: '400',
    projectStatus: 'Progress',
    clientEmail: 'mark@bugdev.co.uk',
    tag: 'Mondatory',
    desigenation: 'Backend Developer',
    department: 'Marketing',
    performance: 'Poor',
    time_in: '11:30 AM',
    time_out: '5:30 PM',
    work_time: '6:00 hour',
    progress: '90%',
    resource: "Michel",
    project_status:'On Track',
  },
];

import Table from './components/Table';
import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';
import { instance } from 'index';
import { useSelector } from 'react-redux';
import Loader from 'components/common/Loader';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

export default function Payments() {
  const { auth } = useSelector((state) => state);
  const {access} = useSelector((state)=> state.auth.userInfo)
  const [isLoading, setIsLoading] = useState(false);
  const [userPayment, setUserPayment] = useState(null);
  const [modal, setModal] = React.useState(false)
  const [isCheck, setIsCheck] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null)
  const [user, setUser] = useState()
  const [formValues, setFormValues] = useState({ 
	title: '', 
	description: '',
	receipt:null,
	amount:'' ,
	status:'',
	user:null,
	total_paid_loan:'',
	remaining_loan:'',
	this_month_paid_loan:'',
	channel:''
  })


	const toggle = () => {
		setModal(!modal)
		setIsCheck(true)
		setFormValues({
			title: '', 
	        description: '',
	        receipt:null,
	        amount:'' ,
	        status:'',
	        user:null,
	        total_paid_loan:'',
	        remaining_loan:'',
	        this_month_paid_loan:'',
	        channel:''
		})
	}
  const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'title',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							{`${cellProps.row.original.user.first_name} ${cellProps.row.original.user.last_name}`}
						</div>
					)
				},
			},
			{
				Header: 'Department',
				accessor: '',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							{`${cellProps?.row?.original?.user?.department?.name}`}
						</div>
					)
				},
			},
			{
				Header: 'Salary',
				accessor: 'amount',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Month',
				accessor: 'created_at',
				filterable: false,
				width: 350,
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MMMM')
						: '',
			},
			{
				Header: 'Channel',
				accessor: 'channel',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
						>
							{cellProps.row.original.status}
						</div>
					)
				},
			},
		],
		[]
	)

  const getAllPayments = async (userId) => {
    
    setIsLoading(true);
    try {
     
      const result = await instance.get(`employee-payment/`,{
		headers: {
			'Authorization': `Bearer ${access}`,
		  },
	  });
      
      if (result?.status === 200) {
        setIsLoading(false);
        const paymentDataResponse = result?.data.results.filter(
          (item) => item.user.id !== userId
        )
        setUserPayment(paymentDataResponse); 
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };
  useEffect(() => {
    if (auth?.userInfo?.user?.id) 
    getAllPayments(auth?.userInfo?.user?.id);
  }, []);

  const getUser = async () =>{
	try{
		const result = await instance.get('user-role/',{
			headers: {
				'Authorization': `Bearer ${access}`,
			  },
		})
		if(result.status === 200)
		{
			setUser(result?.data)
			console.log("user data :", result?.data)
		}
	} catch(error){
		console.log({error})
	}
  }

  const sendRequest = async () => {
	if (!formValues?.title || !formValues?.description)
		return toast.error('All fields are required!')
	try {
		const data = new FormData()
		data.append('title', formValues?.title)
		data.append('description', formValues?.description)
		data.append('amount', formValues?.amount)
		data.append('status', formValues?.status)
		data.append('total_paid_loan', formValues?.total_paid_loan)
		data.append('remaining_loan', formValues?.remaining_loan)
		data.append('this_month_paid_loan', formValues?.this_month_paid_loan)
		data.append('channel', formValues?.channel)
		data.append('receipt', selectedImage)
		data.append('user', formValues?.user)
		data.append('is_active', true)
		const result = await instance.post('employee-payment/',data, {
			headers: {
				'Authorization': `Bearer ${access}`,
				'content-type': 'multipart/form-data',
			  },
		})
		if (result?.status === 201) {
			toast.success('Request sent sucessfully!')
			setModal(false)
			getAllPayments(auth?.userInfo?.user?.id);
		}
	} catch (error) {
		console.log({ error })
	}
}

const handleImageChange = (event) => {
	setSelectedImage(event.target.files[0])
}

useEffect(()=>{
	getUser()
},[])

  return (
		<>
            {isLoading && <Loader />}
			<div className='myTime d-sm-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white PaytableHeading'>Salaries Dashbord</h6>
				<div className='cmn-btn'>
						<button className='mb-0 mt-1' onClick={toggle}>
							Add Salary
						</button>
					</div>
			</div>
			{isCheck && (
					<div style={{ display: 'block' }}>
						<Modal
							isOpen={modal}
							toggle={() => setModal(true)}
							className='addtask-popups'
						>
							<ModalBody>
								<h3 className='text-center text-white'>Add Employe Salary</h3>
								<div className='addtask-body'>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Title:
										</label>
										<input
											type='text'
											className='form-control'
											value={formValues?.title}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, title: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='message-text' className='col-form-label'>
											Description:
										</label>
										<textarea
											className='form-control'
											value={formValues?.description}
											onChange={(e) =>
												setFormValues({
													...formValues,
													description: e.target.value,
												})
											}
											style={{ height: '150px', borderRadius: '20px' }}
											id='message-text'
										></textarea>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Amount:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.amount}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, amount: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Channel:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.channel}
											style={{ 
												borderRadius: '20px', 
												height: '3rem',  
												border:'none' ,
												background:'#212121',
												color:'white'
												}}
											onChange={(e) =>
												setFormValues({ ...formValues, channel: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>paypal</option>
                                        <option style={{color:'white'}}>wise</option>
                                        <option style={{color:'white'}}>remitly</option>
                                        <option style={{color:'white'}}>direct bank</option>
										<option style={{color:'white'}}>other</option>
									</select>
									
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Status:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.status}
											style={{ 
												borderRadius: '20px', 
												height: '3rem',  
												border:'none' ,
												background:'#212121',
												color:'white'
												}}
											onChange={(e) =>
												setFormValues({ ...formValues, status: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>selct status</option>	
										<option style={{color:'white'}}>completed</option>
                                        <option style={{color:'white'}}>in-progress</option>
                                        <option style={{color:'white'}}>paused</option>
                                        <option style={{color:'white'}}>cancel</option>
									</select>
									
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Total Loan:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.total_paid_loan}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, total_paid_loan: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											This month paid:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.this_month_paid_loan}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, this_month_paid_loan: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Remaining Loan:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.remaining_loan}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, remaining_loan: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='file' className='col-form-label'>
											Upload Recipet:
										</label>
										<input
											type='file'
											className='form-control'
											id='file'
											style={{ borderRadius: '20px' }}
											onChange={handleImageChange}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											User:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.user}
											style={{ 
										    borderRadius: '20px', 
											height: '3rem',  
											border:'none' ,
											background:'#212121',
											color:'white'
										    }}
											onChange={(e) =>
												setFormValues({ ...formValues, user: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>select user</option>
										{
											user?.results?.map((item,id)=>{
												return( 
												<option value={item?.id} style={{color:'white'}}>
												{item?.first_name}
												</option>
											)})
										}
									</select>
									
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='cmn-btn'>
									<button onClick={toggle}>Close</button>
								</div>
								<div className='cmn-btn'>
									<button onClick={sendRequest}>Submit</button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				)}
			<div className='row'>
				<div className='col-12'>
					{userPayment && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={userPayment}
							total={userPayment?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={
								() => console.log('Next')
							}
							prevHandler={
								() => console.log('Prev')
							}
						/>
					)}
				</div>
			</div>
		</>
	)
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto'; // Import from 'chart.js/auto' for the latest version
import { instance } from 'index';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const ProjectSourcesChart = () => {
  const {access} = useSelector((state)=> state.auth.userInfo)
  const [projectresource, setProjectResource] = useState()
  const chartRef = useRef(null);

  const getProjectResource = async () => {
    try {
      const result = await instance.get('projects/', {
        headers: {
          Authorization: `Bearer ${access}`
        }
      });
  
      if (result?.status === 200) {
        const projects = result?.data.results;

        const resourceCounts = {
          linkedin: 0,
          fiver: 0,
          upwork: 0,
          freelance:0
        };
  
        projects.forEach((project) => {
          const resource = project.resource; 
          const sourceKey = resource;
  
          if (resourceCounts.hasOwnProperty(sourceKey)) {
            resourceCounts[sourceKey]++;
          }
        });
        
        setProjectResource(resourceCounts)
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    if (projectresource) {
      const projectSources = {
        Fiverr: [projectresource.fiver],
        Upwork: [projectresource.upwork],
        LinkedIn: projectresource.linkedin,
        Freelancer: projectresource.freelance
      };
      console.log("Upwork data:", projectresource.upwork);
      console.log("Linkedin data:", projectresource.linkedin);
      
      const chartData = {
        labels: Object.keys(projectSources),
        datasets: [
          {
            data: Object.values(projectSources),
            backgroundColor: ['blue', 'green', 'orange', 'purple'],
          },
        ],
      };
  
      const ctx = chartRef.current.getContext('2d');
  
      new Chart(ctx, {
        type: 'doughnut',
        data: chartData,
      });
    }
  }, [projectresource]);
  

  useEffect(()=>{
    getProjectResource()
  },[])
  
  return (
    <div className='satisfaction-chart'>
      <canvas ref={chartRef} width={200} height={200} />
    </div>
  );
};

export default ProjectSourcesChart;

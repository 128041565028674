/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import { AiFillProject } from 'react-icons/ai'
import { MdComputer } from 'react-icons/md'
import { BsNewspaper, BsThreeDotsVertical } from 'react-icons/bs'
import { TransactionChart } from './TransactionChart'
import { Link, useRouteMatch } from 'react-router-dom'
import { instance } from 'index'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Dashbord = () => {
	const { path } = useRouteMatch()
	const {access} = useSelector((state) => state.auth.userInfo)
	const [empSummary, setEmpSummary] = useState(0)
	const [projectsLength, setPorjectsLength] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [userPayment, setUserPayment] = useState([])
	const [salary, setSalary] = useState()

	const employeeData = async () => {
		try {
			const result = await instance.get('profiles/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setEmpSummary(result?.data?.results?.length)
			}
		} catch (error) {
			console.log({ error })
		}
	}


	const projectsData = async () => {
		try {
			const result = await instance.get('projects/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setPorjectsLength(result?.data?.results?.length)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getAllPayments = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get('employee-payment/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				const paymentDataResponse = result?.data.results
				const monthlyPayments = {}
				const currentYear = new Date().getFullYear()

				paymentDataResponse.forEach((payment) => {
					const createdDate = new Date(payment.created_at)
					const paymentYear = createdDate.getFullYear()
					const currentMonth = new Date().toLocaleString('default', { month: 'long' })

					if (paymentYear === currentYear) {
						const month = createdDate.toLocaleString('en-US', { month: 'long' })
						const salary = parseFloat(payment.amount.replace(/[^0-9.-]+/g, ''))

						if (monthlyPayments[month]) {
							monthlyPayments[month] += salary
						} else {
							monthlyPayments[month] = salary
						}
					}

					
				})

				const allMonths = Array.from({ length: 12 }, (_, i) => {
					const monthName = new Date(null, i).toLocaleString('en-US', {
						month: 'long',
					})
					if (monthName  === currentMonth){
						{
							console.log(" this month salary", monthlyPayments[monthName])
							setSalary(monthlyPayments[monthName])
						}
					}
					return {
						month: monthName,
						totalSalary: monthlyPayments[monthName] || 0,
						
					}
				})

				setUserPayment(allMonths)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	console.log("user payment :", userPayment)
	useEffect(() => {
		getAllPayments()
	}, [])

	const currentMonth = new Date().toLocaleString('default', { month: 'long' })
	useEffect(() => {
		projectsData()
		employeeData()
	}, [])

	return (
		<>
			<section className='dashboard__task__wrapper'>
				<div className='container'>
					<div className='row mb-5'>
						<div className='col-12 d-flex align-items-center justify-content-between'>
							<p className='title'>Dashboard</p>
						</div>
					</div>
					<div className='row mb-5'>
						<div className='col-md-4'>
							<Link to={`${path}/employee`}>
								<div className='dashboardCard'>
									<div className='title'>
										<p className=''>
											<AiFillProject />
										</p>
										<button>
											{/* <BsThreeDotsVertical /> */}
										</button>
									</div>
									<h6>Total Employee</h6>
									<p>{empSummary}</p>
								</div>
							</Link>
						</div>
						<div className='col-md-4'>
							<Link to={`${path}/projects`}>
								<div className='dashboardCard'>
									<div className='title'>
										<p className=''>
											<MdComputer />
										</p>
										<button>
											{/* <BsThreeDotsVertical /> */}
										</button>
									</div>
									<h6>Total Projects</h6>
									<p>{projectsLength}</p>
								</div>
							</Link>
						</div>
						<div className='col-md-4'>
							<Link to={`${path}/monthly-detail`}>
								<div className='dashboardCard'>
									<div className='title'>
										<p className=''>
											<BsNewspaper />
										</p>
										<button>
											{/* <BsThreeDotsVertical /> */}
										</button>
									</div>
									<h6>Total Paid</h6>
									<p>{currentMonth} {salary} PKR</p>
								</div>
							</Link>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='card  table-card overflow-auto' style={{backgroundColor:'transparent'}}>
								<TransactionChart />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Dashbord
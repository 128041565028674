import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import PaymentDetail from './PaymentDetail'

const PaymentDetails = () => {
	const location = useLocation()

	const message = location.state?.message
	return (
		<>
			<section className='dashboard__task__wrapper'>
				<div className='container'>
					<div className='row mb-5'>
						<div className='col-12 d-flex align-items-center justify-content-between'>
							<p className='title'>Payment Detail</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='card  h-100 task-card' style={{backgroundColor:'transparent'}}>
								<PaymentDetail message={message} />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default PaymentDetails

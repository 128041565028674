/* eslint-disable react-hooks/exhaustive-deps */
import Table from './components/ApplicationTable'
import React, { useMemo, useState, useEffect } from 'react'
import { instance } from 'index'
import { useSelector } from 'react-redux'
import Loader2 from 'components/common/Loader2'
import moment from 'moment/moment'

export default function JobApplications() {
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state) => state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(false)
	const [jobApplication, setJobApplication] = useState()
	const [career, setCareer] = useState();
	const [selectitem, setSelectItem] = useState(career)
	const columns = useMemo(
		() => [
			{
				Header: 'Applicant Name',
				accessor: 'name',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Date Applied',
				accessor: 'created_at',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
						: '',
			},
			{
				Header: 'Position',
				accessor: 'career.title',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Type',
				accessor: 'career.type',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Expected Salary',
				accessor: 'expected_salary',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? `${cell.value} PKR`
						: '',
			},
			{
				Header: 'Resume',
				accessor: 'file',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							className='cmn-btn justify-content-end document-buttons'
							style={{ margin: '0px' }}
						>
							<a className='mb-0  ' href={cellProps?.value} target='_blank'>
								View
							</a>
						</div>
					)
				},
			},
		],
		[]
	)

	const getRequest = async(e)=>{
		setSelectItem(e.target.value)
		console.log("job id", e.target.value)
		getGroupByApplication( e.target.value)
	}

	const getAllApplications = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get(`job-application/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})

			if (result?.status === 200) {
				setIsLoading(false)
				setJobApplication(result?.data.results)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const getCareer = async()=>{
		try{
		const result = await instance.get('career/',{
			headers: {
				'Authorization': `Bearer ${access}`,
			  },
		})
		if (result.status === 200)
		{
			setCareer(result?.data)
			console.log("career data ", result?.data)
		}
		} catch(error){
			console.log({error})
		}
	}

	const getGroupByApplication =async (id)=>{
		console.log("job id", id)
		try{
			const result = await instance.get(`job-applications/${id}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
		if(result.status === 200)
		{
			console.log("console data ../", result?.data)
			setJobApplication(result?.data)
			
		}
		} catch(error){
			console.log({error})
		}
	}
	useEffect(()=>{
		console.log("new console data,", jobApplication)
	},[jobApplication])

	useEffect(()=>{
	getCareer();
	},[])

	useEffect(() => {
		if (auth?.userInfo?.user?.id) getAllApplications(auth?.userInfo?.user?.id)
	}, [])
	return (
		<>
			<div className='myTime d-sm-flex justify-content-between m-5 mb-2 align-items-center mb-5'>
				<h6 className='text-white PaytableHeading'>Job Application</h6>
				 <select  
                    className='sidebar_select '
                     value={selectitem}
                     onChange={getRequest}
                 >
			<option>select job</option>
              {
                career?.results?.map((item)=>{
                return(
                <option key={item?.id} value={item?.id}>
					{item?.title}
				</option>
                )
               })
              }
              </select>
			</div>
			

			<div className='row position-relative'>
				{isLoading && <Loader2 />}
				<div className='col-12'>
					{jobApplication && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={jobApplication}
							total={jobApplication?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={() => console.log('Next')}
							prevHandler={() => console.log('Prev')}
						/>
					)}
				</div>
			</div>
		</>
	)
}

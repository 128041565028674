import Table from 'components/common/table/Table'
import React, { useMemo, useState, useEffect } from 'react'
import { instance } from 'index'
import { useSelector } from 'react-redux'
import Loader2 from 'components/common/Loader2'

export default function Employee() {
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(false)
	const [employeeData, setEmployeeData] = useState()
	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'client',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Email',
				accessor: 'user.email',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Designation',
				accessor: 'intro_text',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Date of Joining',
				accessor: 'joining_date',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
		],
		[]
	)

	const getAllEmployee = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get(`profiles/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})

			if (result?.status === 200) {
				setIsLoading(false)
				setEmployeeData([
					...result?.data?.results?.map((request) => {
						return {
							...request,
							client: `${request?.user?.first_name} ${request?.user?.last_name}`,
						}
					}),
				])
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}


	useEffect(() => {
		if (auth?.userInfo?.user?.id) getAllEmployee(auth?.userInfo?.user?.id)
	}, [])
	return (
		<>
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white PaytableHeading'>Employee Dashbord</h6>
			</div>

			<div className='row position-relative'>
				{isLoading && <Loader2 />}
				<div className='col-12'>
					{employeeData && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={employeeData}
							total={employeeData?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={() => console.log('Next')}
							prevHandler={() => console.log('Prev')}
						/>
					)}
				</div>
			</div>
		</>
	)
}

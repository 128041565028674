import { instance } from 'index'
import React from 'react'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
const Form = () => {
	const { auth } = useSelector((state) => state)
	const [isRequestSent, setIsRequestSent] = useState(false)

	const [signatureImage, setSignatureImage] = useState(null)
	const [thumbImage, setThumbImage] = useState(null)

	const [signatureImage2, setSignatureImage2] = useState(null)
	const [thumbImage2, setThumbImage2] = useState(null)

	const [applicantSgnatureImage, setApplicantSignatureImage] = useState(null)
	const [applicantThumbImage, setApplicantThumbImage] = useState(null)

	const handleSignatureChange = (event) => {
		const file = event.target.files[0]
		setSignatureImage(URL.createObjectURL(file))
		setFormValues((prev) => {
			return { ...prev, annexure_1_signature: file }
		})
	}

	const handleThumbChange = (event) => {
		const file = event.target.files[0]
		setThumbImage(URL.createObjectURL(file))
		setFormValues((prev) => {
			return { ...prev, annexure_1_thumb: file }
		})
	}
	const handleSignatureChange2 = (event) => {
		const file = event.target.files[0]
		setSignatureImage2(URL.createObjectURL(file))
		setFormValues((prev) => {
			return { ...prev, annexure_2_signature: file }
		})
	}

	const handleThumbChange2 = (event) => {
		const file = event.target.files[0]
		setThumbImage2(URL.createObjectURL(file))
		setFormValues((prev) => {
			return { ...prev, annexure_2_thumb: file }
		})
	}

	const handleApplicantSignatureChange = (event) => {
		const file = event.target.files[0]
		setApplicantSignatureImage(URL.createObjectURL(file))
		setFormValues((prev) => {
			return { ...prev, applicant_signature: file }
		})
	}

	const handleApplicantThumbChange = (event) => {
		const file = event.target.files[0]
		setApplicantThumbImage(URL.createObjectURL(file))
		setFormValues((prev) => {
			return { ...prev, applicant_thumb: file }
		})
	}

	const [formValues, setFormValues] = useState({
		date: '',
		loan_expected_by_date: null,
		loan_expected_by_date: '',
		desired_loan_amount: null,
		loan_purpose: null,
		annexure_1_name: '',
		annexure_1_phone: '',
		annexure_1_cnic: '',
		annexure_1_gender: null,
		annexure_1_relationship: '',
		annexure_1_address: '',
		annexure_1_signature: '',
		annexure_1_thumb: '',
		annexure_2_name: '',
		annexure_2_phone: '',
		annexure_2_cnic: '',
		annexure_2_gender: null,
		annexure_2_relationship: '',
		annexure_2_address: '',
		annexure_2_signature: '',
		annexure_2_thumb: '',
		applicant_signature: '',
		applicant_thumb: '',
	})

	const handleSubmit = async (e) => {
		e.preventDefault()
		console.log('form submitted', formValues)

		try {
			setIsRequestSent(true)

			const result = await instance.post(
				'loan-application/',
				{
					...formValues,
					user: auth?.userInfo?.user?.id,
				},
				{
					headers: {
						'content-type': 'multipart/form-data',
					},
				}
			)
			if (result?.status === 201) {
				toast.success('Request sent sucessfully!')
				setFormValues({
					date: '',
					loan_expected_by_date: null,
					loan_expected_by_date: '',
					desired_loan_amount: null,
					loan_purpose: null,
					annexure_1_name: '',
					annexure_1_phone: '',
					annexure_1_cnic: '',
					annexure_1_gender: null,
					annexure_1_relationship: '',
					annexure_1_address: '',
					annexure_1_signature: '',
					annexure_1_thumb: '',
					annexure_2_name: '',
					annexure_2_phone: '',
					annexure_2_cnic: '',
					annexure_2_gender: null,
					annexure_2_relationship: '',
					annexure_2_address: '',
					annexure_2_signature: '',
					annexure_2_thumb: '',
					applicant_signature: '',
					applicant_thumb: '',
				})
				setApplicantSignatureImage(null)
				setThumbImage2(null)
				setApplicantThumbImage(null)
				setSignatureImage2(null)
				setSignatureImage(null)
				setThumbImage(null)
				setIsRequestSent(false)
			}
		} catch (error) {
			setIsRequestSent(false)
			console.log({ error })
		}
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target
		if (name === 'serviceType') {
			setFormValues((prev) => {
				return { ...prev, loan_purpose: value }
			})
		} else {
			setFormValues((prev) => {
				return { ...prev, [name]: value }
			})
		}
	}

	return (
		<div className='contact-information'>
			<div className='container'>
				<form onSubmit={handleSubmit}>
					<div className='row justify-content-center'>
						<div
							className='col-lg-12 col-xl-12 bg-white '
							style={{ padding: 0, borderRadius: '15px' }}
						>
							<div className='loan-form row padding2040 form__border-bottom'>
								<div className='form-div ' style={{ flex: 1, margin: 0 }}>
									<img
										src={process.env.PUBLIC_URL + '/images/logo.png'}
										alt=''
									/>
								</div>
								<div className='form-div '>
									<h3>Bugdev (Pvt) Ltd</h3>
									<p>accounts@bugdev.co.uk</p>
								</div>
							</div>
							<div className='loan-form column padding2040 form__border-bottom'>
								<div className='form-div form-div_date row'>
									<h3>LOAN APPLICATION FORM</h3>
									<div className='date-input'>
										<span>Date </span>{' '}
										<input
											required
											onChange={handleInputChange}
											type='date'
											name='date'
											value={formValues?.date}
										/>
									</div>
								</div>

								<div className='loan-form row'>
									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Desired Loan Amount</label>
										<input
											required
											onChange={handleInputChange}
											type='number'
											id='amount'
											name='desired_loan_amount'
											value={formValues?.desired_loan_amount}
										/>
									</div>

									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Loan expected by date</label>
										<input
											required
											onChange={handleInputChange}
											value={formValues?.loan_expected_by_date}
											type='date'
											id='amount'
											name='loan_expected_by_date'
										/>
									</div>
								</div>

								<div className='loan-form column'>
									<span className='big-heading'>Loan will be used for</span>
									<div class='mb-3 m-0 position-relative row mt-3'>
										<div class='col-lg-4'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='serviceType'
														value='Business Launching'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														Business Launching
													</span>
												</label>
											</div>
										</div>
										<div class='col-lg-4'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='serviceType'
														value='Marriage'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														Marriage
													</span>
												</label>
											</div>
										</div>
										<div class='col-lg-4'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3 '>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='serviceType'
														value='House Buying'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														House Buying
													</span>
												</label>
											</div>
										</div>
										<div class='col-lg-4'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='serviceType'
														value='Investment'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														Investment
													</span>
												</label>
											</div>
										</div>
										<div class='col-lg-4'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='serviceType'
														value='Other'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														Other
													</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='loan-form row padding2040 form__border-bottom'>
								<p className='annexues'>ANNEXURES</p>
								<p className='annexues'>Annexues 1</p>
								<div className='loan-form row'>
									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Name</label>
										<input
											required
											onChange={handleInputChange}
											type='text'
											id='amount'
											name='annexure_1_name'
											value={formValues?.annexure_1_name}
										/>
									</div>

									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Phone</label>
										<input
											required
											onChange={handleInputChange}
											type='text'
											id='amount'
											name='annexure_1_phone'
											value={formValues?.annexure_1_phone}
										/>
									</div>
								</div>
								<div className='loan-form row'>
									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>CNIC</label>
										<input
											required
											onChange={handleInputChange}
											type='text'
											id='amount'
											name='annexure_1_cnic'
											value={formValues?.annexure_1_cnic}
										/>
									</div>

									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Gender</label>
										<div class='col-lg-2'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='annexure_1_gender'
														value='Male'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>Male</span>
												</label>
											</div>
										</div>
										<div class='col-lg-2'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='annexure_1_gender'
														value='Female'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														Female
													</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className='relation-input' style={{ display: 'flex' }}>
									<label htmlFor='' style={{ margin: 0 }}>
										Relationship with Her
									</label>
									<input
										required
										onChange={handleInputChange}
										type='text'
										name='annexure_1_relationship'
										value={formValues?.annexure_1_relationship}
										style={{ flex: 1 }}
									/>
								</div>
								<div className='address-input'>
									<label htmlFor='address'>Address</label>
									<textarea
										required
										onChange={handleInputChange}
										class='textbox-lines'
										rows='6'
										name='annexure_1_address'
										value={formValues?.annexure_1_address}
									/>
								</div>
								<div className='loan-form row'>
									<div className='form-div border_bottom form-div_amount form-flex04'>
										<label htmlFor='signature'>Signature</label>
										<input
											required
											type='file'
											style={{ opacity: 0 }}
											id='signature'
											name='signature'
											onChange={handleSignatureChange}
										/>
										{signatureImage && (
											<img
												src={signatureImage}
												alt='Signature Preview'
												style={{
													maxWidth: '100%',
													height: '80px',
													objectFit: 'cover',
												}}
											/>
										)}
									</div>

									<div className='form-div border_bottom form-div_amount form-flex04'>
										<label htmlFor='thumb'>Thumb</label>
										<input
											required
											type='file'
											style={{ opacity: 0 }}
											id='thumb'
											name='thumb'
											onChange={handleThumbChange}
										/>
										{thumbImage && (
											<img
												src={thumbImage}
												alt='Thumb Preview'
												style={{
													maxWidth: '100%',
													height: '80px',
													objectFit: 'cover',
												}}
											/>
										)}
									</div>
								</div>
							</div>
							<div className='loan-form row padding2040 form__border-bottom'>
								<p className='annexues'>Annexues 2</p>
								<div className='loan-form row'>
									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Name</label>
										<input
											required
											onChange={handleInputChange}
											type='text'
											id='amount'
											name='annexure_2_name'
											value={formValues?.annexure_2_name}
										/>
									</div>

									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Phone</label>
										<input
											required
											onChange={handleInputChange}
											type='number'
											id='amount'
											name='annexure_2_phone'
											value={formValues?.annexure_2_phone}
										/>
									</div>
								</div>
								<div className='loan-form row'>
									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>CNIC</label>
										<input
											required
											onChange={handleInputChange}
											type='text'
											id='amount'
											name='annexure_2_cnic'
											value={formValues?.annexure_2_cnic}
										/>
									</div>

									<div className='form-div form-div_amount form-flex04'>
										<label for='amount'>Gender</label>
										<div class='col-lg-2'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='annexure_2_gender'
														value='Male'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>Male</span>
												</label>
											</div>
										</div>
										<div class='col-lg-2'>
											<div class='ContactForm-module--staffCheck--6db88'>
												<label className='d-flex gap-3'>
													<input
														required
														onChange={handleInputChange}
														type='radio'
														name='annexure_2_gender'
														value='Female'
														style={{ height: '30px', width: '30px' }}
													/>
													<span className='text-black checkbox-text'>
														Female
													</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className='relation-input'>
									<label htmlFor=''>Relationship with Her</label>
									<input
										required
										onChange={handleInputChange}
										type='text'
										name='annexure_2_relationship'
										value={formValues?.annexure_2_relationship}
										style={{ flex: 1 }}
									/>
								</div>
								<div className='address-input'>
									<label htmlFor='address'>Address</label>
									<textarea
										required
										onChange={handleInputChange}
										class='textbox-lines'
										rows='6'
										name='annexure_2_address'
										value={formValues?.annexure_2_address}
									/>
								</div>
								<div className='loan-form row'>
									<div className='form-div border_bottom form-div_amount form-flex04'>
										<label htmlFor='signature'>Signature</label>
										<input
											required
											type='file'
											style={{ opacity: 0 }}
											id='signature'
											name='signature'
											onChange={handleSignatureChange2}
										/>
										{signatureImage2 && (
											<img
												src={signatureImage2}
												alt='Signature Preview'
												style={{
													maxWidth: '100%',
													height: '80px',
													objectFit: 'cover',
												}}
											/>
										)}
									</div>

									<div className='form-div border_bottom form-div_amount form-flex04'>
										<label htmlFor='thumb'>Thumb</label>
										<input
											required
											type='file'
											style={{ opacity: 0 }}
											id='thumb'
											name='thumb'
											onChange={handleThumbChange2}
										/>
										{thumbImage2 && (
											<img
												src={thumbImage2}
												alt='Thumb Preview'
												style={{
													maxWidth: '100%',
													height: '80px',
													objectFit: 'cover',
												}}
											/>
										)}
									</div>
								</div>
							</div>
							<div className='loan-form row padding2040 form__border-bottom'>
								<p className='annexues'>ELIGIBILITY CRITERIA</p>
								<p className='eligible-text'>
									You are eligible for Personal Finance if:
								</p>
								<ul className='eligible-ul'>
									<li>
										Minimum Two (02) Year relationship with Bugdev (Pvt) Ltd
									</li>
									<li>Minimum net monthly salary of Rs.40,000/- </li>
									<li>
										Maximum Loan amount will be decided on Monthly Salary{' '}
									</li>
								</ul>
							</div>
							<div className='loan-form row padding2040 form__border-bottom'>
								<p className='annexues'> DOCUMENTS</p>
								<ul className='eligible-ul'>
									<li>Employee ID Card Copy </li>
									<li>References ID Card Copy</li>
									<li>Passport Size Pic</li>
									<li>References Passport Size Pic </li>
									<li>Home Electricity Bill Copy last 3 months </li>
									<li>References Video Proof When you will getting loan </li>
									<li>Employee Video Proof When you will getting loan</li>
								</ul>
							</div>
							<div className='loan-form row padding2040 '>
								<p className='annexues'>UNDERTAKING</p>
								<ul className='eligible-ul undertaking'>
									<li>
										I/We the undersigned do hereby declare that I/we am/are
										Pakistani National(s) and undertake to maintain this status,
										in addition to dual nationality (if any) at least until full
										& final settlement of the financing from the Bugdev (Pvt)
										Ltd.{' '}
									</li>
									<li>
										I/We understand that in the event the Bugdev agrees to
										finance any amount for Personal Loan as admissible under its
										rules, regulations and procedures, I/we the undersigned do
										hereby declare and undertake that decision of the Bugdev in
										respect of the following shall be binding on me/us and I/we
										shall not be absolved from this undertaking any time during
										the period of the financing.
									</li>
									<li>
										I/We declare that all the information presented above is
										correct and complete to the best of my/our knowledge. I/We
										also hereby authorize the Bugdev, or it’s duly appointed
										agents, to contact my place of residence, work, and/or
										references to verify any of the information provided by me
										in this application. That I/we further declare that this
										application form along with its annexures, terms and
										conditions, undertaking and other relevant documents shall
										form part of and be deemed to have been incorporated in the
										Loan Agreement to be executed by me/us.
									</li>
									<li>
										I / We will inform Bugdev in case there is change in any of
										my / our telephone number/address.
									</li>
									<li>
										Bugdev (Pvt) Ltd at all times, reserves the right to reject
										the financing application at any time during the credit
										screening process.
									</li>
									<li>
										I / We are responsible for any type of taxes, stamp duties
										that will arise for agreement and other related documents.
									</li>
									<li>
										If I break the agreement with the company, the company
										reserves the right to take legal action
									</li>
									<li>
										I / We will accept all the terms and condition of Bugdev.
									</li>
								</ul>
								<div className='loan-form row'>
									<div className='form-div border_bottom form-div_amount form-flex04'>
										<label htmlFor='signature'>Applicant Signature</label>
										<input
											required
											type='file'
											style={{ opacity: 0 }}
											id='signature'
											name='signature'
											onChange={handleApplicantSignatureChange}
										/>
										{applicantSgnatureImage && (
											<img
												src={applicantSgnatureImage}
												alt='Signature Preview'
												style={{
													maxWidth: '100%',
													height: '80px',
													objectFit: 'cover',
												}}
											/>
										)}
									</div>

									<div className='form-div border_bottom form-div_amount form-flex04'>
										<label htmlFor='thumb'>Applicant Thumb</label>
										<input
											required
											type='file'
											style={{ opacity: 0 }}
											id='thumb'
											name='thumb'
											onChange={handleApplicantThumbChange}
										/>
										{applicantThumbImage && (
											<img
												src={applicantThumbImage}
												alt='Thumb Preview'
												style={{
													maxWidth: '100%',
													height: '80px',
													objectFit: 'cover',
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className='cmn-btn justify-content-end'>
							<button type='submit' className='my-2' disabled={isRequestSent}>
								{isRequestSent ? 'Sending Loan Request' : 'Send Loan Request'}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Form

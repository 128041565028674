const Loader2 = () => {
    return (
      <div className='loader2 w-100 d-flex align-items-center justify-content-center'>
        <div className='dot1 rounded-circle m-2'></div>
        <div className='dot2 rounded-circle m-2'></div>
        <div className='dot3 rounded-circle m-2'></div>
      </div>
    );
  };
  
  export default Loader2;
  
import React from 'react'
import Header from './Header'
import Breadcrumb from './Breadcrumb'
import Footer from './Footer'
import LetsTalkArea from './LetsTalkArea'
 const Support = () => {
  return (
    <>
    {/* <Header/> */}
    <Breadcrumb pageName='Support Policy'/>
    <div className="container">
        <div className="col-md-12 col-lg-12 col-xl-12">
            <div className='title mt-4'>
            <h1>Support Policy</h1>
            </div>
            <p className='text-white'>Thank you for visiting Bugdev. We need to collect some information 
                about you, and this Privacy Policy will describe what we collect 
                and why we do it. If you want to disable this, then there are a 
                few methods described below as well. This policy shall also 
                describe your rights and choices concerning your personal information.
                However, do note that by continuing to visit/stay on our Site, and 
                using the features made available to you on the Site, you are agreeing
                to the terms of this Privacy Policy. Our Website may also include links
                to other websites whose privacy policies can differ from those of Budev.
                If you submit information to any of the third-party links that you go through,
                your information will be handled according to their privacy statements. That 
                is why we encourage our visitors to carefully read the privacy statements of 
                all websites when they visit.</p>

            <div className='title mt-4'>
            <h1>Personal Information About Candidates</h1>
            </div>
            <p className='text-white'>Please note that we collect information about 
            candidates when they submit a job application to our Site, either by using 
            the Contact Form or through information email, which is hr@bugdev.co.uk. 
            For instance, we may collect an applicant’s first name and surname, phone number, 
            place of residence (city and country), email address, and other necessary information 
            which they may provide to us in the form of, but not limited to, cover letter, resume 
            or curriculum vitae (CV).</p>

            <div className='title mt-4'>
            <h1>Usage of Information</h1>
            </div>
            <p className='text-white'>Bugdev uses the information that it collects for many 
            different purposes, including but not limited to:</p>
            <ul className='text-white'>
                <li >Contact our clients, or prospects, and reply to information requests about Bugdev;</li>
                <li>Process and analyse job applications that we receive from potential employees;</li>
                <li>Site administration, troubleshooting technical or user issues, and to conduct internal auditing;</li>
                <li>Count and analyse visitors and their behaviour on our Site and evaluate how they use our pages;</li>
                <li>Comply with legal and/or regulatory requirements;</li>
            </ul>
            <p className='text-white mt-3'>We might combine the information that we collect on our Site, 
               along with additional information that the third-parties shall 
               collect to form an aggregate which shall limit your detectability. 
               We may use this combined information for purposes that include research, 
               improving the Site, data analysis and/or developing new features.</p>

            <div className='title mt-4'>
            <h1>What Are Cookies?</h1>
            </div>
            <p className='text-white'>As it is the common practice with almost all 
            professional websites this site also uses cookies, which are tiny files 
            that are downloaded to your computer to improve your experience. This 
            page describes what information they gather, how we use it and why we 
            sometimes need to store these cookies. We will also share how you can 
            prevent these cookies from being stored. However, this may downgrade 
            or ‘break’ certain elements of the site’s functionality.<br/> 
            Our cookies are created when you (user) load our Bugdev website onto 
            your browser. Our website sends information to the browser which then 
            creates the file we talked about above. Every time you visit our website, 
            your browser takes this file and sends it to our website server. These 
            cookies are not only created by our website, but they are also created 
            by the services that we are using, which includes widgets, ads 
            (if there are any), plugins, or other elements on the website pages.
            </p>

            <div className='title mt-4'>
            <h1>How We Use Cookies?</h1>
            </div>
            <p className='text-white'>
            We use cookies for a variety of reasons detailed below. 
            Unfortunately, in most cases, there are no industry 
            standard options for disabling cookies without completely 
            disabling the functionality and features they added to 
            this site. It is recommended that you leave it on for 
            all cookies if you are not sure whether you need them 
            or not, in case they are used to provide a service that you use.
            </p>

            <div className='title mt-4'>
            <h1>Data Access and Its Retention</h1>
            </div>
            <p className='text-white '>
            Bugdev will retain information for as long as necessary 
            to accomplish the processes and purposes described in this 
            Privacy Policy. Bugdev would also like to use and retain 
            information as necessary to comply with our legal obligations, 
            enforce agreements, and resolve disputes. However, rest assured 
            because we shall only process that personal information which 
            is relevant to our purpose for which it was collected in the 
            first place. To the extent necessary for those purposes, we 
            will take reasonable steps to ensure that personal information 
            is accurate, complete, current and reliable for its intended use.
            </p>

            <div className='title mt-4'>
            <h1>Children’s Privacy</h1>
            </div>
            <p className='text-white '>
            The Site is not directed to, and we do not intend to, 
            or knowingly, collect or solicit personal information 
            from children under the age of 18. If you are under the 
            age of 18, please do not use the Site or otherwise provide 
            us with any personal information either directly or by other 
            means. If a child under the age of 18 has provided personal 
            information to us, we encourage the child’s parent or guardian 
            to contact us to request that we remove the personal information
            from our systems.
            </p>

            <div className='title mt-4'>
            <h1>Changes to the Cookie Policy</h1>
            </div>
            <p className='text-white '>
            We may make changes to our Privacy and Policy from time 
            to time to reflect our changes to the methods of handling 
            information that you provide. Please visit this page regularly, 
            or whenever you visit the Website. We will also inform you 
            through our Website through a notification bar or alert. Your 
            continued visit to our Site and our Website thereafter constitutes 
            acceptance of the changes.
            </p>

            <div className='title mt-4'>
            <h1>Contact Us</h1>
            </div>
            <p className='text-white mb-5'>
            Hopefully, we have clarified to you our Privacy Policy and, 
            as was previously mentioned, if there is something that you 
            are not sure whether you need cookies or not it is usually 
            safer to leave them enabled in case it does interact with 
            one of the features you use on our Site. <br/>
            If you are still looking for more information or would like to 
            comment on or ask questions, then you can contact us through email on <span>
           <a>hr@bugdev.co.uk </a>
            </span>
            </p>
        </div>
        </div>
        <LetsTalkArea />
        <Footer/>
    </>
  )
}

export default Support

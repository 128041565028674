import React from 'react'

const BankDetail = ({ userData, handleInputChange, handleBankUpdate }) => {
	return (
		<div className='col-lg-9 col-md-12 right'>
x				<div className='card bg-dark' style={{padding:'20px'}}>
				<p className='title'>Welcome to the information</p>
				<p className='description'>
					{' '}
					Check or change your information as you want
				</p>
				<div className='row'>
					<div className='col-md-6 col-sm-12'>
						<label id='first-name' className='form-label' style={{color:'white'}}>
							Account Title
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.account_holder_name}
							name='account_holder_name'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='last-name' className='form-label' style={{color:'white'}}>
							Bank Name
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.bank_name}
							name='bank_name'
							onChange={handleInputChange}
						/>
					</div>

					<div className='col-md-6 col-sm-12'>
						<label id='birthday' className='form-label' style={{color:'white'}}>
							IBAN
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.account_iban}
							name='account_iban'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='phone' className='form-label'style={{color:'white'}}>
							City
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.city}
							name='city'
							onChange={handleInputChange}
						/>
					</div>
				</div>
				<div className='cmn-btn'>
					<button onClick={handleBankUpdate}>Update</button>
				</div>
			</div>
		</div>
	)
}

export default BankDetail

// import React from 'react'

// export default function BankDetail() {
//   return (
//     <>
//     <div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
//       <h6 className='text-white tableHeading'>User Dashbord</h6>
//     </div>

//     <div className='row'>
//       <div className='col-12'>
//         <div className='card bg-dark table-card overflow-auto'>
//         <h6 className='text-white tableHeading d-flex justify-content-center'>User Bank Detail</h6>
//                 <div className='row d-flex justify-content-around'>
//                   <div className='col-md-5 col-sm-12 '>
//                     <label id='title' className='form-label text-white ms-4'>
//                     Account Title
//                     </label>
//                     <input className='form-control bd-input' type={'text'} />
//                   </div>
//                   <div className='col-md-5 col-sm-12'>
//                     <label id='number' className='form-label text-white ms-4'>
//                     Account Number
//                     </label>
//                     <input className='form-control bd-input' type={'text'} />
//                   </div>
//                   <div className='col-md-5 col-sm-12'>
//                     <label id='iban' className='form-label text-white ms-4'>
//                     IBAN
//                     </label>
//                     <input className='form-control bd-input' type={'email'} />
//                   </div>
//                   <div className='col-md-5 col-sm-12'>
//                     <label id='bankName' className='form-label text-white ms-4'>
//                     Bank name
//                     </label>
//                     <input className='form-control bd-input' type={'text'} />
//                   </div>
//                   <div className='col-md-5 col-sm-12'>
//                     <label id='branchName' className='form-label text-white ms-4'>
//                     Branch Name
//                     </label>
//                     <input className='form-control bd-input' type={'text'} />
//                   </div>
//                   <div className='col-md-5 col-sm-12'>
//                     <label id='code' className='form-label text-white ms-4'>
//                     Branch Code
//                     </label>
//                     <input className='form-control bd-input' type={'text'} />
//                   </div>
//                 </div>
//                 <div className='d-flex justify-content-end gap-3'>
//                 <div className='cmn-btn'>
//                   <button className=''>Save</button>
//                 </div>
//                 <div className='cmn-btn'>
//                   <button className=''>Close</button>
//                 </div>
//                 </div>
//         </div>
//       </div>
//     </div>
//   </>
//   )
// }

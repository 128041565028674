import { instance } from 'index'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const HeroForm = () => {
	const [isFormSubmitting, setIsFormSubmitting] = useState(false)
	const [formData, setFormData] = useState({
		fullname: '',
		phone_number: '',
		type: '',
		email: '',
		subject: '',
		introduction: '',
		is_active: true,
	})
	const sendFormData = async () => {
		try {
			const result = await instance.post('hire-resources/', formData, {
				headers: {
					'content-type': 'application/json',
				},
			})
			if (result?.status === 201) {
				toast.success('Your Form Submitted sucessfully!')
				setFormData({
					fullname: '',
					phone_number: '',
					email: '',
					subject: '',
					introduction: '',
					type: '',
					is_active: true,
				})
				setIsFormSubmitting(false)
			}
		} catch (error) {
			toast.error('Something Went Wrong. Please try again to post')
			setIsFormSubmitting(false)
			console.log({ error })
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		setIsFormSubmitting(true)
		sendFormData()
	}

	const handleInputChange = (event) => {
		setFormData((prev) => {
			return { ...prev, [event.target.name]: event.target.value }
		})
	}

	return (
		<>
			<div className='contact-information'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-lg-8 col-xl-8'>
							<div className='contact-form '>
								<h3
									style={{
										marginBottom: '10px',
										fontWeight: '900',
										fontSize: '35px',
									}}
								>
									We Got Your Back!
								</h3>
								<h3 style={{ fontSize: '25px', fontWeight: '500' }}>
									Submit a short brief to build, launch and grow.
								</h3>
								<form onSubmit={handleSubmit} action='#' method='post'>
									<div className='row form-container'>
										<div className='mb-3 m-0 position-relative row'>
											<div className='col-lg-4'>
												<div className='ContactForm-module--staffCheck--6db88'>
													<label className='d-flex gap-3'>
														<input
															required
															type='radio'
															name='serviceType'
															value='staff-augmentation'
															style={{ height: '30px', width: '30px' }}
															onChange={(e) =>
																setFormData({
																	...formData,
																	type: e.target.value,
																})
															}
														/>
														<span className='text-white'>
															Staff Augmentation
														</span>
													</label>
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='ContactForm-module--staffCheck--6db88'>
													<label className='d-flex gap-3'>
														<input
															required
															type='radio'
															name='serviceType'
															value='dedicated-teams'
															style={{ height: '30px', width: '30px' }}
															onChange={(e) =>
																setFormData({
																	...formData,
																	type: e.target.value,
																})
															}
														/>
														<span className='text-white'>Dedicated Teams</span>
													</label>
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='ContactForm-module--staffCheck--6db88'>
													<label className='d-flex gap-3 '>
														<input
															required
															type='radio'
															name='serviceType'
															value='fixed-gigs'
															style={{ height: '30px', width: '30px' }}
															onChange={(e) =>
																setFormData({
																	...formData,
																	type: e.target.value,
																})
															}
														/>
														<span className='text-white'>Fixed Gigs</span>
													</label>
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='ContactForm-module--staffCheck--6db88'>
													<label className='d-flex gap-3'>
														<input
															required
															type='radio'
															name='serviceType'
															value='general-inquiry'
															style={{ height: '30px', width: '30px' }}
															onChange={(e) =>
																setFormData({
																	...formData,
																	type: e.target.value,
																})
															}
														/>
														<span className='text-white'>General Inquiry</span>
													</label>
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='ContactForm-module--staffCheck--6db88'>
													<label className='d-flex gap-3'>
														<input
															required
															type='radio'
															name='serviceType'
															value='career-opportunity'
															style={{ height: '30px', width: '30px' }}
															onChange={(e) =>
																setFormData({
																	...formData,
																	type: e.target.value,
																})
															}
														/>
														<span className='text-white'>
															Career Opportunity
														</span>
													</label>
												</div>
											</div>
										</div>
										<div className='col-xl-6'>
											<input
												type='text'
												name='fullname'
												placeholder='Full Name *'
												value={formData?.fullname}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className='col-xl-6'>
											<PhoneInput
												type='tel'
												name='phone_number'
												value={formData?.phone_number}
												onChange={(phone) =>
													setFormData({ ...formData, phone_number: phone })
												  }
												placeholder='Enter Mobile Number'
												style={{ display: 'flex', background: 'white', borderRadius: '20px', padding: '0px 10px' }}
												required
											/>

										</div>
										<div className='col-xl-6'>
											<input
												type='email'
												name='email'
												placeholder='Enter your email *'
												value={formData?.email}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className='col-xl-6'>
											<input
												type='text'
												name='subject'
												placeholder='Subject *'
												value={formData?.subject}
												onChange={handleInputChange}
												required
											/>
										</div>
										<div className='col-12'>
											<textarea
												name='introduction'
												cols={30}
												rows={10}
												value={formData?.introduction}
												onChange={handleInputChange}
												placeholder='Tells us what you need help with, the purpose of your project and the problem we’re solving. * '
												required
											/>
										</div>
										<div className='cmn-btn'>
											<button className='my-2' disabled={isFormSubmitting}>
												{isFormSubmitting ? 'Sending Message' : 'Send Message'}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default HeroForm

import { Fragment } from 'react';
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className='sidebar-search d-flex justify-content-between'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onChange(value);
        }}
      >
        <input
          type='search'
          name='search'
          className='px-3'
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder='Search Here'
		  style={{backgroundColor:'#383B3F', color:'white'}}
        />
        {/* <button type='submit'>
          <i className='bi bi-search' />
        </button> */}
      </form>
    </div>
  );
}

const ReactTable = ({
  tableStyles = {},
  columns,
  data,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  onItemClick,
  loading = false,
  withPagination = true,
  isPrevPage,
  prevHandler,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },

    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const [itemproject, setItemProject] = useState();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
		<div
			className='card  table-card overflow-auto'
			style={{ padding: '25px', backgroundColor:'transparent' }}
		>
			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={globalFilter}
				setGlobalFilter={setGlobalFilter}
			/>

			<div className='w-full'>
				<div className={`${divClass} table-responsive customTable `}>
					<table
						// hover
						// {...getTableProps().toString()}
						className={`${tableClass} table w-100`}
						style={tableStyles}
					>
						<thead className={theadClass}>
							{headerGroups.map((headerGroup) => (
								<tr
									className='table__headRow'
									key={headerGroup.id}
									{...headerGroup.getHeaderGroupProps()}

								>
									{headerGroup.headers.map((column) => (
										<th
											key={column.id}
											className={thClass}
											{...column.getHeaderProps({
												style: {
													minWidth: column.minWidth,
													width: column.width,
												},
											})}
											{...column.getHeaderProps(column.getSortByToggleProps())}
										>
											{column.render('Header')}
										</th>
									))}
								</tr>
							))}
						</thead>

						{!loading && (
							<tbody {...getTableBodyProps()} style={{border:'none'}}>
								{page.map((row) => {
									prepareRow(row)
									return (
										<Fragment key={row.getRowProps().key}>
											<tr
												className='clickable'
												onClick={() => onItemClick && onItemClick(row)}
											>
												{row.cells.map((cell) => {
													return (
														<td
															key={cell.id}
															{...cell.getCellProps({
																style: {
																	minWidth: cell.column.minWidth,
																	width: cell.column.width,
																	...(cell.column.cellStyle || {}),
																},
															})}
															className='text-white'
															onClick={() => onItemClick && onItemClick(row)}
														>
															{cell.render('Cell')}
														</td>
													)
												})}
											</tr>
											<tr className='tr__border'>
												<td
													colSpan={row?.cells.length}
													style={{ padding: '10px 0', margin: 0 }}
												>
													<hr
														className='m-0 p-0'
														style={{ background: 'red', height: '2px' }}
													/>
												</td>
											</tr>
										</Fragment>
									)
								})}
							</tbody>
						)}
					</table>
					{loading && (
						<div className='w-100 d-flex justify-content-center'>
							<div className='spinner-border text-danger' role='status'>
								<span className='visually-hidden text-white'>Loading...</span>
							</div>
						</div>
					)}
				</div>

				{withPagination && (
					<div className='row p-2 text-white table-responsive'>
						{data && (
							<div className='col-footer '>Total Results : {data?.length} </div>
						)}

						<div className='col col-md-auto'>
							<div className='d-flex gap-1'>
								<button
									className='btn btn-primary bg-black border-0'
									onClick={previousPage}
									disabled={!canPreviousPage}
								>
									{'<'}
								</button>
							</div>
						</div>
						<div className='col col-md-auto d-none d-md-block'>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>
						</div>
						<div className='div col-md-auto'>
							<input
								className='input form-control'
								type='number'
								min={1}
								style={{ width: 70 }}
								max={pageOptions.length}
								value={pageIndex + 1}
								onChange={onChangeInInput}
							/>
						</div>
						<div className='div col-md-auto'>
							<div className='d-flex gap-1'>
								<button
									className='btn btn-primary bg-black border-0'
									onClick={nextPage}
									disabled={!canNextPage}
								>
									{'>'}
								</button>
							</div>
						</div>

						{!isPrevPage || isPrevPage === null || isNaN(isPrevPage) ? null : (
							<div className='col col-md-auto'>
								<div className='d-flex gap-1'>
									<button className='btn btn-primary' onClick={prevHandler}>
										Prev
									</button>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
};

export default ReactTable;

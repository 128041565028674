import React, { useEffect, useMemo, useState } from 'react'
import Table from 'components/common/table/Table'
import moment from 'moment'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { instance } from 'index'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Loader from 'components/common/Loader'
import RequestTable from './components/RequestTable'

export default function Request() {
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [formValues, setFormValues] = useState({ title: '', description: '' })
	const [isLoading, setIsLoading] = useState(false)
	const [userRequest, setUserRequest] = useState(null)
	const [modal, setModal] = useState(false)
	const [isCheck, setIsCheck] = useState(true)
	const [getId, setGetId] = useState()
	const history = useHistory()
	const toggle = () => {
		setModal(!modal)
		setIsCheck(isCheck)
		setFormValues({
			...formValues,
			description: '',
			title: '',
		})
	}
	const getRequestItem = async (id) => {
		history.push({
			pathname: '/manager-dashboard/request-detail',
			state: { message: id },
		})
	}
	const editToggle = (idData) => {
		setFormValues({
			...formValues,
			description: idData?.row?.original?.description,
			title: idData?.row?.original?.title,
		})
		setGetId(idData)
		setModal(!modal)
		setIsCheck(!isCheck)
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Title',
				accessor: 'title',
				filterable: false,
				width: 300,
				render: ({ row }) => <Link to={'/request/request-detail'}></Link>,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				filterable: false,
				width: 300,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (

							<div
								key={cellProps.row.original?.id}
								className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
							>
								{cellProps.row.original.status}
							</div>
						
					)
				},
			},
			{
				Header: 'Date',
				accessor: 'created_at',
				filterable: false,
				width: 300,
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
						: '',
			},
			{
				Header: 'Action',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<>
							{isLoading && <Loader />}
							<div className='d-flex align-items-center justify-content-center gap-2'>
								<button
									className='border-0 bg-transparent'
									onClick={() => getDelete(cellProps.row.original.id)}
								>
									<img
										src={process.env.PUBLIC_URL + '/images/delete-icon.png'}
										alt='images'
										style={{ cursor: 'pointer' }}
									/>
								</button>
								<button
									className='border-0 bg-transparent'
									onClick={() => editToggle(cellProps)}
								>
									<img
										src={process.env.PUBLIC_URL + '/images/edit-icon.png'}
										alt='images'
										style={{ cursor: 'pointer' }}
									/>
								</button>
								<button
									className='border-0 bg-transparent'
									onClick={() => getRequestItem(cellProps.row.original.id)}
								>
									<i
										className='fa fa-eye'
										style={{
											color: 'white',
											cursor: 'pointer',
											fontSize: '22px',
										}}
									></i>
								</button>
							</div>
						</>
					)
				},
			},
		],
		[]
	)

	const sendRequest = async () => {
		if (!formValues?.title || !formValues?.description)
			return toast.error('All fields are required!')
		const data =  {
			...formValues,
			user: auth?.userInfo?.user?.id,
			is_active: true
		}

		try {
			const result = await instance.post('internal-requests/',data,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 201) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getAllRequest(auth?.userInfo?.user?.id)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getAllRequest = async (userId) => {
		setIsLoading(true)
		try {
			const result = await instance.get(`internal-requests/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)

				const requestDataResponse = result?.data.results.filter(
					(item) => item.user.id === userId
				)

				setUserRequest(requestDataResponse)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	const getDelete = async (itemId) => {
		setIsLoading(true)
		try {
			const result = await instance.delete(`internal-requests/${itemId}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			setIsLoading(false)
			if (result?.status === 204) {
				setIsLoading(false)
				getAllRequest(auth?.userInfo?.user?.id)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const getEdit = async () => {
		const data = {...formValues,
			user: auth?.userInfo?.user?.id,
			is_active:true
		}
		try {
			const result = await instance.patch(
				`internal-requests/${getId?.row?.original?.id}/`, data,
				{

					headers: {
						'Authorization': `Bearer ${access}`,
					  },
				}
			)
			console.log('edit data result', result)
			if (result?.status === 200) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getAllRequest(auth?.userInfo?.user?.id)
				setFormValues({
					...formValues,
					description: '',
					title: '',
				})
			}
		} catch (error) {
			console.log({ error })
		}
	}

	useEffect(() => {
		if (auth?.userInfo?.user?.id) getAllRequest(auth?.userInfo?.user?.id)
	}, [])

	return (
		<>
			{isLoading && <Loader />}
			<div className='myTime dashboard_header mb-3'>
				<h6 className='text-white tableHeading'>Request Dashbord</h6>
				<div className='cmn-btn'>
					<button className='mb-0 mt-1' onClick={toggle}>
						Post Request
					</button>
				</div>
			</div>
			{isCheck ? (
				<div style={{ display: 'block' }}>
					<Modal
						isOpen={modal}
						toggle={() => setModal(true)}
						className='addtask-popups'
					>
						{/* <ModalHeader toggle={toggle}>Add Request</ModalHeader> */}
						<ModalBody>
							<h3 className='text-center text-white'>Submit Your Request</h3>
							<div className='addtask-body'>
								<div className='form-group'>
									<label htmlFor='recipient-name' className='col-form-label'>
										Title:
									</label>
									<input
										type='text'
										className='form-control'
										value={formValues?.title}
										style={{ borderRadius: '20px', height: '3rem' }}
										onChange={(e) =>
											setFormValues({ ...formValues, title: e.target.value })
										}
										id='recipient-name'
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='message-text' className='col-form-label'>
										Description:
									</label>
									<textarea
										className='form-control'
										value={formValues?.description}
										onChange={(e) =>
											setFormValues({
												...formValues,
												description: e.target.value,
											})
										}
										style={{ height: '150px', borderRadius: '20px' }}
										id='message-text'
									></textarea>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn'>
								<button onClick={toggle}>Close</button>
							</div>
							<div className='cmn-btn'>
								<button onClick={sendRequest}>Submit</button>
							</div>
						</ModalFooter>
					</Modal>
				</div>
			) : (
				<div style={{ display: 'block' }}>
					<Modal
						isOpen={modal}
						toggle={() => setModal(true)}
						className='addtask-popups'
					>
						{/* <ModalHeader toggle={toggle}>Add Request</ModalHeader> */}
						<ModalBody>
							<h3 className='text-center text-white'>Submit Your Request</h3>
							<div className='addtask-body'>
								<div className='form-group'>
									<label htmlFor='recipient-name' className='col-form-label'>
										Title:
									</label>
									<input
										type='text'
										className='form-control'
										value={formValues?.title}
										style={{ borderRadius: '20px', height: '3rem' }}
										onChange={(e) =>
											setFormValues({ ...formValues, title: e.target.value })
										}
										id='recipient-name'
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='message-text' className='col-form-label'>
										Description:
									</label>
									<textarea
										className='form-control'
										value={formValues?.description}
										onChange={(e) =>
											setFormValues({
												...formValues,
												description: e.target.value,
											})
										}
										style={{ height: '150px', borderRadius: '20px' }}
										id='message-text'
									></textarea>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn'>
								<button onClick={toggle}>Close</button>
							</div>
							<div className='cmn-btn'>
								<button onClick={getEdit}>Submit</button>
							</div>
						</ModalFooter>
					</Modal>
				</div>
			)}

			<div className='row'>
				<div className='col-12'>
					{userRequest && (
						<RequestTable
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={userRequest}
							total={userRequest?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={
								() => console.log('Next')
							}
							prevHandler={
								() => console.log('Prev')
							}
						/>
					)}
				</div>
			</div>
		</>
	)
}

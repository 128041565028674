import { icons } from '../../../contants'
import Header from 'components/common/Header'
import Breadcrumb from 'components/common/Breadcrumb'
import Footer from 'components/common/Footer'
import { useLocation } from 'react-router-dom'
import { instance } from 'index'
import { useState, useEffect } from 'react'
import moment from 'moment'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Loader from 'components/common/Loader'
import { current } from '@reduxjs/toolkit'

export default function EventDetail() {
	const location = useLocation()
	const message = location.state?.message
	const [isLoading, setIsLoading] = useState(true)
	const [eventItem, setEventItem] = useState()
	const [event, setEvent] = useState()

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

	const getEventDetail = async (message) => {
		try {
			const result = await instance.get(`events-plans/${message}/`)
			if (result?.status === 200) {
				setIsLoading(false)
				setEventItem(result?.data)
				console.log()
			}
		} catch (error) {
			console.log({ error })
		}
		scrollTop()
	}

	const getAllEvents = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get('events-plans/')
			if (result?.status === 200) {
				setIsLoading(false)
				setEvent(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	useEffect(() => {
		getEventDetail(message)
		getAllEvents()
	}, [])

	return (
		<>
			{/* <Header /> */}
			<Breadcrumb pageName='Events' />
			{isLoading && <Loader />}
			<section className='career__wrapper mt-0 padding-20'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 main-heading text-center'>
							<p className='main-title' style={{ color: '#F50808' }}>
								Bugdev <span className='text-white'>Events</span>
							</p>
						</div>
						<div className='col-12 d-flex align-items-center justify-content-between ms-3'>
							<p className='title'>{eventItem?.name}</p>
						</div>
						{eventItem?.images?.map((item) => {
							return (
								<div className='d-flex justify-content-center mt-3 ' key={item?.id}>
									<img src={item?.image} alt='' className='event_fulimg' />
								</div>
							)
						})}

						<div className='col-lg-4 col-md-6 col-sm-12 w-100 mt-5 ms-3'>
							<p className='title'>Description</p>
							<p className='description'>{eventItem?.description}</p>
						</div>

						<div className='d-flex justify-content-between mt-3 '>
							<div className='d-flex gap-2 mt-2 flex-column'>
								<div className='d-flex gap-2'>
									<img
										src={icons.clock.default}
										alt=''
										className='profile mb-2'
										width='20px'
									/>
									<h6 className='text-white mt-2'>Start Date</h6>
								</div>
								<p style={{ marginTop: '-15px', marginLeft: '20px' }}>
									{' '}
									{moment
										.utc(eventItem?.created_at.toString())
										.format('DD-MM-YYYY')}{' '}
								</p>
							</div>

							<div className='d-flex gap-2 mt-2 flex-column'>
								<div className='d-flex gap-2'>
									<img
										src={icons.clock.default}
										alt=''
										className='profile mb-2'
										width='20px'
									/>
									<h6 className='text-white mt-2'>End Date</h6>
								</div>
								<p style={{ marginTop: '-15px', marginLeft: '20px' }}>
									{moment.utc(eventItem?.date.toString()).format('DD-MM-YYYY')}
								</p>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<div className='col-12'>
							<div className='comming_Events'>
								<p>Upcoming Events</p>
							</div>
						</div>
						<div className='col-12 mb-5'>
							<Slider {...settings}>
								{event?.results
									?.filter((item) => {
										return (
											new Date(item?.date) >= new Date() &&
											item?.id != eventItem?.id
										)
									})
									?.map((item) => (
										<div className='event-card' key={item?.id}>
											<div className='card-top'>
												<img src={item?.images[0]?.image} alt={'images'} />
												<h1>{item?.name}</h1>
											</div>
											<div className='card-bottom'>
												<span className='category'>
													{item?.description?.slice(0, 100)}{' '}
													{item?.description?.length > 100 ? '...' : ''}
												</span>
											</div>
											<div className='btn-container '>
												<button
													className='event-btn'
													onClick={() => getEventDetail(item?.id)}
												>
													Read more
												</button>
												<i>
													<img
														src={
															process.env.PUBLIC_URL +
															'/images/icons/arrow-circle.png'
														}
														alt='images'
														className='event-img'
													/>
												</i>
											</div>
										</div>
									))}
							</Slider>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}



import React,{useState} from "react";
import PriceBox from "./PriceBox";
import ProductDevelopment from "./ProductDevelopment";
import DedicatedTeam from "./DedicatedTeam";
import Modal from 'react-bootstrap/Modal'

function ServicePrice() {
	const [thanksShow, setThanksShow] = useState(false)
	const handleCloseThanks = () => setThanksShow(false)
	return (
		<>
			<Modal show={thanksShow} onHide={handleCloseThanks} animation={false}>
				<Modal.Body
					style={{
						background: 'linear-gradient(90deg, #744f4f 1.05%, #c30505 100%)',
					}}
				>
					<h3 className='text-center text-white'>
						Thank You For Reaching Out!
					</h3>
					<div className='text-center'>
						<img
							src={process.env.PUBLIC_URL + '/images/portfolio/completed.svg'}
							alt=''
							width='50%'
						/>
					</div>
					<div className='text-center mt-3'>
						<p className='h5 text-white'>
							We have noted your response successfuly!
						</p>
					</div>
					<p className='text-center mt-3 text-white'>
						One of our representatives will get back to you at the earliest.
					</p>

					<div className='cmn-btn'>
						<button
							style={{ marginLeft: '7.5rem' }}
							onClick={handleCloseThanks}
						>
							Return to Service Page
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<section className='pricing-plan sec-mar padding-20'>
				<div className='container'>
					<div className='row justify-content-between align-items-center '>
						{/* <div className='col-12 col-lg-6 col-xl-5 or2'>
							<ul
								className='nav nav-pills mb-3'
								id='pills-tab'
								role='tablist'
							></ul>
						</div> */}
						<div className='col-12 col-lg-6 col-xl-5 or1'>
							<div className='title'>
								<span>Pricing Plan</span>
								<h2>Join Now For Your Business.</h2>
							</div>
						</div>
					</div>
					<div className='tab-content' id='pills-tabContent'>
						<div
							className='tab-pane fade show active'
							id='pills-home'
							role='tabpanel'
							aria-labelledby='pills-home-tab'
						>
							<div className='row g-4 justify-content-center'>
								<PriceBox
									setThanksShow={setThanksShow}
									thanksShow={thanksShow}
								/>
								<ProductDevelopment
									setThanksShow={setThanksShow}
									thanksShow={thanksShow}
								/>
								<DedicatedTeam
									setThanksShow={setThanksShow}
									thanksShow={thanksShow}
								/>
							</div>
						</div>
						<div
							className='tab-pane fade'
							id='pills-profile'
							role='tabpanel'
							aria-labelledby='pills-profile-tab'
						>
							<div className='row g-4'>
								<PriceBox
									month='year'
									cartTitle='Professional'
									cartSubtitle='Small team '
									price='99.99'
									featurePages='116 Pages Responsive Website '
									featurePpc='12 PPC Campaigns '
									seo='18 SEO Keyword'
									facebookComplaigns='8 Facebook Camplaigns '
									videoCompligns='7 Video Camplaigns'
								/>
								<PriceBox
									month='year'
									cartTitle='Small Business'
									cartSubtitle='Single Business'
									price='150'
									featurePages='20 Pages Responsive Website '
									featurePpc='15 PPC Campaigns '
									seo='20 SEO Keyword'
									facebookComplaigns='15 Facebook Camplaigns '
									videoCompligns='11 Video Camplaigns'
								/>
								<PriceBox
									month='year'
									cartTitle='Small Business'
									cartSubtitle='Single Business'
									price='130'
									featurePages='20 Pages Responsive Website '
									featurePpc='15 PPC Campaigns '
									seo='50 SEO Keyword'
									facebookComplaigns='15 Facebook Camplaigns '
									videoCompligns='12 Video Camplaigns'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default ServicePrice;

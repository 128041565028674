/* eslint-disable no-lone-blocks */
import Loader from 'components/common/Loader'
import Table from 'components/common/table/Table'
import { timeTable } from 'contants/data'
import { instance } from 'index'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'

export default function MyTime() {
	const [tableList, setTableList] = useState(timeTable)
	const [time, setTime] = useState()
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(false)
	const [modal, setModal] = React.useState(false)
    const [isCheck, setIsCheck] = useState(true)
	const [user, setUser] = useState()
	const [getId, setGetId] = useState('')
	const [formValues, setFormValues] = useState({ 
		week: '', 
		overtime_hours: '',
		number_of_hours:'',
		user:null ,
		status: null,
	  })

	  const toggle = () => {
		setModal(!modal)
		setIsCheck(true)
		setFormValues({
		week: '', 
		overtime_hours: '',
		number_of_hours:'',
		user:null ,
		status: null,
		})
	}

	const editToggle = (idData) => {
		setFormValues({
			...formValues,
			week: idData?.row?.original?.week,
			overtime_hours: idData?.row?.original?.overtime_hours,
			number_of_hours: idData?.row?.original?.number_of_hours,
			user: idData?.row?.original?.user,
			status: idData?.row?.original?.status,
		})
		setGetId(idData)
		setModal(!modal)
		setIsCheck(false)
	}

	const getAttendance = async (userId) => {
		setIsLoading(true)

		try {
			const result = await instance.get('employee-attendance/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				const UserAttendanceData = result?.data.results.filter(
					(item) => item.user.id !== userId
				)
				setTime(UserAttendanceData)
				console.log('user Overtime', time)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(true)

			console.log({ error })
		}
	}

	useEffect(() => {
		if (auth?.userInfo?.user?.id) {
			getAttendance(auth?.userInfo?.user?.id)
		}
	}, [])

	const getUser = async () =>{
		try{
			const result = await instance.get('user-role/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if(result.status === 200)
			{
				setUser(result?.data)
				console.log("user data :", result?.data)
			}
		} catch(error){
			console.log({error})
		}
	  }

	  const sendRequest = async () => {
		try {
			const data = new FormData()
			data.append('week', formValues?.week)
			data.append('overtime_hours', formValues?.overtime_hours)
			data.append('number_of_hours', formValues?.number_of_hours)
			data.append('status', formValues?.status)
			data.append('user', formValues?.user)
			data.append('is_active', true)
			const result = await instance.post('employee-attendance/',data, {
				headers: {
					'Authorization': `Bearer ${access}`,
					'content-type': 'multipart/form-data',
				  },
			})
			if (result?.status === 201) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getAttendance (auth?.userInfo?.user?.id);
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getDelete = async (itemId) => {
		setIsLoading(true)
		try {
			const result = await instance.delete(`employee-attendance/${itemId}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			setIsLoading(false)
			if (result?.status === 204) {
				setIsLoading(false)
				getAttendance(auth?.userInfo?.user?.id)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}


	const getEdit = async () => {
		try {
			const data = {
				...formValues,
				is_active:true
			}
			const result = await instance.patch(
				`employee-attendance/${getId?.row?.original?.id}/`,data,{
					headers: {
						'content-type': 'multipart/form-data',
						'Authorization': `Bearer ${access}`,
					},
					}
			)
	
			if (result?.status === 200) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getAttendance()
				setFormValues({
					...formValues,
					week: '', 
		            overtime_hours: '',
		            number_of_hours:'',
		            user:null ,
		            status: null,
				})
			}
		} catch (error) {
			console.log({ error })
		}
	}

	  useEffect(()=>{
		getUser()
	  },[])
	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							{`${cellProps.row.original.user.first_name} ${cellProps.row.original.user.last_name}`}
						</div>
					)
				},
			},
			{
				Header: 'Weekly Hour',
				accessor: 'week',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							<span className='span-badge'></span>
							{cellProps.row.original?.week}
						</div>
					)
				},
			},
			{
				Header: 'Working Hour',
				accessor: 'number_of_hours',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							<span className='span-badge'></span>
							{cellProps.row.original.number_of_hours}
						</div>
					)
				},
			},
			{
				Header: 'OverTime',
				accessor: 'overtime_hours',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							<span className='span-badge'></span>
							{cellProps.row.original.overtime_hours}
						</div>
					)
				},
			},
			{
				Header: 'Date',
				accessor: 'created_at',
				filterable: false,
				width: 250,
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
						: '',
			},
			{
				Header: 'Action',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div className='d-flex align-items-center justify-content-center gap-2'>
							
								<>
									<button
										className='border-0 bg-transparent'
										onClick={() => getDelete(cellProps.row.original.id)}
									>
										<img
											src={process.env.PUBLIC_URL + '/images/delete-icon.png'}
											alt='images'
											style={{ cursor: 'pointer' }}
										/>
									</button>
									<button
										className='border-0 bg-transparent'
										onClick={() => editToggle(cellProps)}
									>
										<img
											src={process.env.PUBLIC_URL + '/images/edit-icon.png'}
											alt='images'
											style={{ cursor: 'pointer' }}
										/>
									</button>
								</>
						</div>
					)
				},
			},
		],
		[]
	)

	return (
		<>
			{isLoading && <Loader />}
			<div className='myTime d-sm-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white tableHeading'>Employee Time Dashbord</h6>
				<div className='cmn-btn'>
					<button className='mb-0 mt-1' onClick={toggle}>
						Add Time
					</button>
				</div>
			</div>
			{isCheck ? (
					<div style={{ display: 'block' }}>
						<Modal
							isOpen={modal}
							toggle={() => setModal(true)}
							className='addtask-popups'
						>
							<ModalBody>
								<h3 className='text-center text-white'>Add Employe Time</h3>
								<div className='addtask-body'>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Weekly Hours:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.week}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, week: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Working Hours:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.number_of_hours}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, number_of_hours: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Overtime:
										</label>
										<input
											type='text'
											className='form-control'
											value={formValues?.overtime_hours}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, overtime_hours: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Status:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.status}
											style={{ 
												borderRadius: '20px', 
												height: '3rem',  
												border:'none' ,
												background:'#212121',
												color:'white'
												}}
											onChange={(e) =>
												setFormValues({ ...formValues, status: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>rejected</option>
                                        <option style={{color:'white'}}>pending</option>
                                        <option style={{color:'white'}}>approve</option>
                                        
									</select>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											User:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.user}
											style={{ 
										    borderRadius: '20px', 
											height: '3rem',  
											border:'none' ,
											background:'#212121',
											color:'white'
										    }}
											onChange={(e) =>
												setFormValues({ ...formValues, user: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>select user</option>
										{
											user?.results?.map((item,id)=>{
												return( 
												<option value={item?.id} style={{color:'white'}}>
												{item?.first_name}
												</option>
											)})
										}
									</select>
									
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='cmn-btn'>
									<button onClick={toggle}>Close</button>
								</div>
								<div className='cmn-btn'>
									<button onClick={sendRequest}>Submit</button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				): (
					<div style={{ display: 'block' }}>
						<Modal
							isOpen={modal}
							toggle={() => setModal(true)}
							className='addtask-popups'
						>
							<ModalBody>
								<h3 className='text-center text-white'>Edit Employe Time</h3>
								<div className='addtask-body'>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Weekly Hours:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.week}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, week: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Working Hours:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.number_of_hours}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, number_of_hours: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Overtime:
										</label>
										<input
											type='text'
											className='form-control'
											value={formValues?.overtime_hours}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, overtime_hours: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Status:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.status}
											style={{ 
												borderRadius: '20px', 
												height: '3rem',  
												border:'none' ,
												background:'#212121',
												color:'white'
												}}
											onChange={(e) =>
												setFormValues({ ...formValues, status: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>rejected</option>
                                        <option style={{color:'white'}}>pending</option>
                                        <option style={{color:'white'}}>approve</option>
                                        
									</select>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											User:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.user}
											style={{ 
										    borderRadius: '20px', 
											height: '3rem',  
											border:'none' ,
											background:'#212121',
											color:'white'
										    }}
											onChange={(e) =>
												setFormValues({ ...formValues, user: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>select user</option>
										{
											user?.results?.map((item,id)=>{
												return( 
												<option value={item?.id} style={{color:'white'}}>
												{item?.first_name}
												</option>
											)})
										}
									</select>
									
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='cmn-btn'>
									<button onClick={toggle}>Close</button>
								</div>
								<div className='cmn-btn'>
									<button onClick={getEdit}>Submit</button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				)}
			<div className='row'>
				<div className='col-12'>
					{time && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={time}
							total={time?.length}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={() => console.log('Next')}
							prevHandler={() => console.log('Prev')}
						/>
					)}
				</div>
			</div>
		</>
	)
}

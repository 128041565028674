import React, { useEffect, useState } from 'react'
import { icons } from '../../../../../../src/contants'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { instance } from 'index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Loader2 from 'components/common/Loader2'
export default function Events() {
	const [modal, setModal] = useState(false)

	const toggle = () => {
		setModal(!modal)
		setIsCheck(true)
		setFormValues({
			name: '',
			description: '',
			date: '',
		})
		setImage([])
	}
	const [event, setEvent] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [image, setImage] = useState()
	const addPostImages = []
	const [addImage, setAddImage] = useState()
	const { auth } = useSelector((state) => state)
	const [isCheck, setIsCheck] = useState(true)
	const [getId, setGetId] = useState('')
	const {access} = useSelector((state)=> state.auth.userInfo)

	const history = useHistory()
	const [formValues, setFormValues] = useState({
		name: '',
		description: '',
		date: '',
	})

	const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

	const getDetail = (id) => {
		history.push({
			pathname: '/eventDetail',
			state: { message: id },
		})
		scrollTop()
	}

	const sendRequest = async (imgId) => {
		const form = {
			name: formValues?.name,
			description: formValues?.description,
			date: `${formValues?.date}T00:00:00Z`,
			images: [`${imgId}`],
			is_active: true,
			user: auth?.userInfo?.user?.id,
		}
		try {
			
			const result = await instance.post('events-plans/',form,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
		})
			if (result?.status === 201) {
				toast.success('Request sent sucessfully!')
				setModal(false)
			}
			getAllEvents()
		} catch (error) {
			console.log({ error })
		}
	}

	const sendImages = async () => {
		const form = {
			image: image,
			user: auth?.userInfo?.user?.id,
			is_active:true
		}
		try {
			
			const result = await instance.post('image/', form, {
				headers: {
					'content-type': 'multipart/form-data',
					'Authorization': `Bearer ${access}`,
				},
			})

			if (result?.status === 201) {
				setAddImage(result?.data)
				addPostImages.push(result?.data?.id)
				console.log('event Img Id', addPostImages)
				sendRequest(result?.data?.id)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getAllEvents = async () => {
		try {
			const result = await instance.get('events-plans/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setEvent(result?.data?.results)
				console.log(result?.data?.results)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getDelete = async (eventId) => {
		console.log('eventId', eventId)
		try {
			const result = await instance.delete(`events-plans/${eventId}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 204) {
				getAllEvents()
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const sendEditImage = async () => {
		const form = {
			image: image,
			is_active: true,
			user: auth?.userInfo?.user?.id,
		}
		try {
			
			const result = await instance.post('image/', form, {
				headers: {
					'content-type': 'multipart/form-data',
					'Authorization': `Bearer ${access}`,
				},
			})

			if (result?.status === 201) {
				setAddImage(result?.data)
				addPostImages.push(result?.data?.id)
				console.log('event Img Id', addPostImages)
				getEdit(result?.data?.id)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getEdit = async (imgId) => {
		const data = {	name: formValues?.name,
			description: formValues?.description,
			date: `${formValues?.date}T00:00:00Z`,
			images: [`${imgId}`],
			is_active:true

		}
		try {
			const result = await instance.patch(`events-plans/${getId}/`, data,{
			
				headers: {
					'content-type': 'multipart/form-data',
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getAllEvents()
				setFormValues({
					...formValues,
					description: '',
					title: '',
				})
			}
		} catch (error) {
			console.log({ error })
		}
	}
	const editToggle = (idData) => {
		setFormValues({
			...formValues,
			description: idData?.description,
			name: idData?.name,
			date: idData?.date,
		})
		console.log(idData)
		setGetId(idData?.id)
		setModal(!modal)
		setIsCheck(false)
	}

	useEffect(() => {
		getAllEvents()
	}, [])

	return (
		<>
		{isLoading && <Loader2 />}
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white tableHeading'>Events Dashbord</h6>
				<div className='cmn-btn'>
					<button className='mb-0 mt-1' onClick={toggle}>
						Add Event
					</button>
				</div>
			</div>
			<div style={{ display: 'block' }}>
				{isCheck ? (
					<Modal isOpen={modal} toggle={toggle} className='modal-popups'>
						<ModalBody>
							<h3 className='text-center text-white'>Add Event</h3>
							<div className='modal-body'>
								<form>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Title:
										</label>
										<input
											type='text'
											className='form-control'
											style={{ borderRadius: '20px', height: '3rem' }}
											id='recipient-name'
											value={formValues?.name}
											onChange={(e) =>
												setFormValues({ ...formValues, name: e.target.value })
											}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='message-text' className='col-form-label'>
											Description:
										</label>
										<textarea
											className='form-control'
											id='message-text'
											style={{ height: '100px', borderRadius: '20px' }}
											value={formValues?.description}
											onChange={(e) =>
												setFormValues({
													...formValues,
													description: e.target.value,
												})
											}
										></textarea>
									</div>

									<div className='form-group'>
										<label htmlFor='end-date' className='col-form-label'>
											End Date:
										</label>
										<input
											type='date'
											className='form-control picker-white'
											id='end-date'
											name='date'
											style={{ borderRadius: '20px', height: '3rem' }}
											value={formValues?.date}
											onChange={(e) =>
												setFormValues({
													...formValues,
													date: e.target.value,
												})
											}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='file' className='col-form-label'>
											Upload Image:
										</label>
										<input
											type='file'
											className='form-control'
											id='file'
											style={{ borderRadius: '20px' }}
											onChange={(e) => setImage(e.target.files[0])}
										/>
									</div>
								</form>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn bg-transparent border-0'>
								<button onClick={toggle}>Close</button>
							</div>
							<div className='cmn-btn bg-transparent border-0'>
								<button onClick={sendImages}>Save</button>
							</div>
						</ModalFooter>
					</Modal>
				) : (
					<Modal isOpen={modal} toggle={toggle} className='modal-popups'>
						<ModalBody>
							<h3 className='text-center text-white'>Edit Event</h3>
							<div className='modal-body'>
								<form>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Title:
										</label>
										<input
											type='text'
											className='form-control'
											style={{ borderRadius: '20px', height: '3rem' }}
											id='recipient-name'
											value={formValues?.name}
											onChange={(e) =>
												setFormValues({ ...formValues, name: e.target.value })
											}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='message-text' className='col-form-label'>
											Description:
										</label>
										<textarea
											className='form-control'
											id='message-text'
											style={{ height: '100px', borderRadius: '20px' }}
											value={formValues?.description}
											onChange={(e) =>
												setFormValues({
													...formValues,
													description: e.target.value,
												})
											}
										></textarea>
									</div>

									<div className='form-group'>
										<label htmlFor='end-date' className='col-form-label'>
											End Date:
										</label>
										<input
											type='date'
											className='form-control picker-white'
											id='end-date'
											name='date'
											style={{ borderRadius: '20px', height: '3rem' }}
											value={formValues?.date}
											onChange={(e) =>
												setFormValues({
													...formValues,
													date: e.target.value,
												})
											}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='file' className='col-form-label'>
											Upload Image:
										</label>
										<input
											type='file'
											className='form-control'
											id='file'
											style={{ borderRadius: '20px' }}
											onChange={(e) => setImage(e.target.files[0])}
										/>
									</div>
								</form>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn bg-transparent border-0'>
								<button onClick={toggle}>Close</button>
							</div>
							<div className='cmn-btn bg-transparent border-0'>
								<button onClick={sendEditImage}>Save</button>
							</div>
						</ModalFooter>
					</Modal>
				)}
			</div>
			<div className='col-12 px-3'>
				<div className='card  table-card overflow-auto' style={{background:'transparent'}}>
					<div className='d-flex gap-2'>
						<h6 className='text-white'>Upcomming Events</h6>
						<img
							src={icons.clock.default}
							alt=''
							className='profile mb-2'
							width='30px'
						/>
					</div>
					<div className='row'>
						<div className=' col-12 d-flex flex-wrap justify-content-start'>
							{event
								?.filter((item) => new Date(item?.date) >= new Date())
								?.map((item, index) => (
									<div className='row gap-5 m-3' key={index}>
										<div
											className='col-lg-6 col-md-6 col-sm-12 card event_Card position-relative'
											style={{ width: '20rem', backgroundColor: '#141414' }}
										>
											<div
												className='position-absolute d-none flex-column event-action-btns'
												style={{ right: '20px', top: '35px', gap: '10px' }}
											>
												<button
													className='border-0 event_action-btn'
													style={{ width: '40px', height: '40px' }}
													onClick={() => getDelete(item?.id)}
												>
													<i
														className='fa fa-trash'
														style={{
															color: 'red',
															cursor: 'pointer',
															fontSize: '22px',
														}}
													></i>
												</button>
												<button
													className='border-0 event_action-btn'
													style={{ width: '40px', height: '40px' }}
													onClick={() => editToggle(item)}
												>
													<i
														className='fa fa-pen'
														style={{
															color: '#000',
															cursor: 'pointer',
															fontSize: '18px',
														}}
													></i>
												</button>
											</div>
											<img
												src={item?.images[0]?.image}
												className='card-img-top'
												alt='...'
												style={{ objectFit: 'cover', height: '220px' }}
											/>
											<div className='card-body position-relative'>
												<h5 className='card-title text-white d-flex'>
													<span className='span-badge'></span>
													{item?.name}
												</h5>
												<p className='card-text'>
													{item?.description?.slice(0, 80)}{' '}
													{item?.description?.length > 80 ? '...' : ''}
												</p>
												<div className='btn-container'>
													<button
														className='event-btn'
														onClick={() => getDetail(item?.id)}
													>
														Read more
													</button>
													<i>
														<img
															src={
																process.env.PUBLIC_URL +
																'/images/icons/arrow-circle.png'
															}
															alt='images'
															className='event-img'
														/>
													</i>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
			<div className='col-12 px-3'>
				<h6 className='text-white mt-4 ms-4'>Past Events</h6>
				<div className='row'>
					<div className='col-12'>
						<div className='w-100 d-flex flex-wrap justify-content-start'>
							{event
								?.filter((item) => new Date(item?.date) < new Date())
								?.map((item, index) => (
									<div className='row gap-5 m-3 ' key={index}>
										<div
											className='col-lg-6 col-md-6 col-sm-12 card event_Card position-relative'
											style={{ width: '20rem', backgroundColor: '#141414' }}
										>
											<div
												className='position-absolute d-none flex-column event-action-btns'
												style={{ right: '20px', top: '35px', gap: '10px' }}
											>
												<button
													className='border-0 event_action-btn'
													style={{ width: '40px', height: '40px' }}
													onClick={() => getDelete(item?.id)}
												>
													<i
														className='fa fa-trash'
														style={{
															color: 'red',
															cursor: 'pointer',
															fontSize: '18px',
														}}
													></i>
												</button>
												<button
													className='border-0 event_action-btn'
													style={{ width: '40px', height: '40px' }}
													onClick={() => editToggle(item)}
												>
													<i
														className='fa fa-pen'
														style={{
															color: '#000',
															cursor: 'pointer',
															fontSize: '18px',
														}}
													></i>
												</button>
											</div>
											<img
												src={item?.images[0]?.image}
												className='card-img-top'
												alt='...'
												style={{ objectFit: 'cover', height: '220px' }}
											/>
											<div className='card-body position-relative'>
												<h5 className='card-title text-white d-flex'>
													<span className='span-badge'></span>
													{item?.name}
												</h5>
												<p className='card-text'>
													{item?.description?.slice(0, 80)}{' '}
													{item?.description?.length > 80 ? '...' : ''}
												</p>
												<div className='btn-container'>
													<button
														className='event-btn'
														onClick={() => getDetail(item?.id)}
													>
														Read more
													</button>
													<i>
														<img
															src={
																process.env.PUBLIC_URL +
																'/images/icons/arrow-circle.png'
															}
															alt='images'
															className='event-img'
														/>
													</i>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

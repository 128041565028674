import { icons } from '../../../../../contants';
import { useState, useEffect } from 'react'
import { instance } from 'index'
import UserSideBar from './components/UserSideBar'
import Information from './Information'
import { useLocation } from 'react-router-dom'
import UserBankDetail from './UserBankDetail'
import UserDocuments from './UserDocuments'
import { toast } from 'react-toastify'
import Skills from './Skills'
import { useSelector } from 'react-redux';

const ViewProfile = () => {
	const [userDetail, setUserDetail] = useState({})
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(true)
	const [department, setDepartment] = useState('')
	const [deptName, setDpetName] = useState('')
	const [isShow, setIsShow] = useState({
		Information: true,
		UserBankDetail: false,
		UserDocuments: false,
		Skills: false,
	})
	const location = useLocation()
	const message = location.state?.message
	const getUser = async (message) => {
		try {
			const result = await instance.get(`profiles/${message}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setUserDetail(result?.data)
				setDepartment(result?.data?.user?.department)
			}
		} catch (error) {
			setIsLoading(true)
			console.log({ error })
		}
	}

	const updateUserDepartment = async () => {
		try {
			const data ={name: deptName}
			const result = await instance.patch(
				`departments/${userDetail?.user?.department?.id}/`, data,
				{
					
					headers: {
						'Authorization': `Bearer ${access}`,
					  },
				}
			)
			if (result?.status === 200) {
				console.log('User Depart Updated')
			}
		} catch (error) {
			setIsLoading(true)
			console.log({ error })
		}
	}

	const getDepartment = async (department) => {
		try {
			const result = await instance.get(`departments/${department}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setDpetName(result?.data?.name)
			}
		} catch (error) {
			setIsLoading(true)
			console.log({ error })
		}
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target
		if (name === 'depart_name') {
			setDpetName(value)
		} else {
			setUserDetail((prev) => {
				return { ...prev, [name]: value }
			})
		}
	}

	const updateUserBankProfile = async () => {
		try {
			const requestBody = {
				city: userDetail?.city,
				account_iban: userDetail?.account_iban,
				account_holder_name: userDetail?.account_holder_name,
				bank_name: userDetail?.bank_name,
			}

			console.log('requestBody', requestBody)
			const result = await instance.patch(
				`profiles/${userDetail?.id}/`,requestBody,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
				}
			)

			if (result?.status === 200) {
				toast.success('Data Upadted Sucessfully!')
			}
		} catch (error) {
			// toast.error('Oops! Error While Updating Data')
			console.log({ error })
		}
	}

	const updateUserRole = async () => {
		try {
			const data = {
				first_name: userDetail?.user?.first_name,
				email: userDetail?.user?.email,
			}
			const result = await instance.patch(
				`user-role/${userDetail?.user?.id}/`, data,
				{
					headers: {
						'Authorization': `Bearer ${access}`,
					  },
				}
			)

			if (result?.status === 200) {
				updateUserDepartment()
				updateUserProfile()
			}
		} catch (error) {
			toast.error('Oops! Error While Updating Data')
			console.log({ error })
		}
	}

	const updateUserProfile = async () => {
		try {
			const requestBody = {
				gender: userDetail?.gender,
				date_of_birth: userDetail?.date_of_birth,
				phone_no: userDetail?.phone_no,
				experience: userDetail?.experience,
				full_address: userDetail?.full_address,
				joining_date: userDetail?.joining_date,
				intro_text: userDetail?.intro_text,
				employee_status: userDetail?.employee_status,
				age: userDetail?.age,
			}

			// if (imageUpload) {
			// 	requestBody.profile_picture = imageUpload
			// }

			const result = await instance.patch(
				`profiles/${userDetail?.id}/`,
				requestBody,
				{
					headers: {
						'content-type': 'multipart/form-data',
						'Authorization': `Bearer ${access}`,
					},
				}
			)

			if (result?.status === 200) {
				toast.success('Data Upadted Sucessfully!')
			}
		} catch (error) {
			toast.error('Oops! Error While Updating Data')
			console.log({ error })
		}
	}

	const handleUpdate = () => {
		updateUserRole()
	}

	const handleBankUpdate = () => {
		updateUserBankProfile()
	}


	useEffect(() => {
		getUser(message)
	}, [])
	useEffect(() => {
		getDepartment(userDetail?.user?.department?.id)
	}, [userDetail])

	return (
		<>
			<section className='setting__wrapper'>
				<div className='container'>
					<div className='row align-items-start'>
						<UserSideBar
							userDetail={userDetail}
							icons={icons}
							deptName={deptName}
							isShow={isShow}
							setIsShow={setIsShow}
						/>
						{isShow?.Information && (
							<Information
								userDetail={userDetail}
								icons={icons}
								deptName={deptName}
								handleInputChange={handleInputChange}
								setUserDetail={setUserDetail}
								handleUpdate={handleUpdate}
							/>
						)}
						{isShow?.UserBankDetail && (
							<UserBankDetail
								userDetail={userDetail}
								handleBankUpdate={handleBankUpdate}
								handleInputChange={handleInputChange}
							/>
						)}

						{isShow?.UserDocuments && <UserDocuments userDetail={userDetail} />}
						{isShow?.Skills && <Skills userDetail={userDetail} />}
					</div>
				</div>
			</section>
		</>
	)
}
export default ViewProfile

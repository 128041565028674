import React from 'react'
import { Link, useHistory } from 'react-router-dom'

const Aside = ({
	handleDispacth,
	userData,
	icons,
	handleImageChange,
	toggleAuth,
	isShow,
	setIsShow,
}) => {
	const history = useHistory()

	return (
		<div className='col-lg-5 col-md-12 left'>
			<div className='card d-flex position-relative detail-card text-center'>
				<div className='profile-img'>
					<img
						src={userData?.profile_picture}
						alt=''
						className='profile'
						style={{
							width: '100px',
							height: '100px',
							objectFit: 'cover',
						}}
					/>
					<div className='upload-icon position-relative'>
						<img className='portrait' alt='' src={icons.portrait.default} />
						<input
							type='file'
							className='position-absolute'
							style={{ opacity: 0 }}
							onChange={handleImageChange}
						/>
					</div>
				</div>
				<p className='title'>{`${userData?.first_name} ${userData?.last_name}`}</p>
				<p className='title'>{userData?.email}</p>
			</div>

			<div className='card my-lg-5 mt-md-5 functional'>
				<ul className='list-bordered'>
					<li>
						<img
							src={icons.registration.default}
							alt=''
							className='img-fluid id_icon'
						/>

						<button
							onClick={() =>
								setIsShow({
									BankDetail: false,
									SettingDetail: true,
								})
							}
							className={`text-highlighted bg-transparent border-0 ${
								isShow?.SettingDetail && 'active'
							}`}
						>
							Information
						</button>

						<img
							src={icons.forward.default}
							alt=''
							className='img-fluid arrow'
						/>
					</li>
					<li>
						<img
							src={icons.lock.default}
							alt=''
							className='img-fluid id_icon'
						/>

						<button
							onClick={() =>
								setIsShow({
									BankDetail: true,
									SettingDetail: false,
								})
							}
							className={`text-highlighted bg-transparent border-0 ${
								isShow?.BankDetail && 'active'
							}`}
						>
							Bank Details
						</button>

						<img
							src={icons.forward.default}
							alt=''
							className='img-fluid arrow'
						/>
					</li>
					<li>
						<img src={icons.logout.default} alt='' className='img-fluid' />
						<Link
							className='text-highlighted'
							to='/'
							onClick={() => {
								handleDispacth(
									toggleAuth({
										isLogin: false,
										userInfo: null,
									})
								)
								history.push('/')
							}}
						>
							Logout
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Aside

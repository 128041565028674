import React,{useState} from "react";
import { Link } from "react-router-dom";
import { instance } from "index";
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

function ProductDevelopment({ thanksShow, setThanksShow }) {
	const [modal, setModal] = React.useState(false)
	const [isRequestSent, setIsRequestSent] = useState(false)

	const [formValues, setFormValues] = useState({
		how_early: '',
		for_time: '',
		skills: '',
		develop: '',
		platform: '',
		technologies: '',
		how_many_developers: '',
		developers_for_time: '',
		name: '',
		email: '',
		phone_no: '',
	})
	const toggle = () => {
		setModal(false)
	}

	const sendRequest = async (e) => {
		e.preventDefault()
		setIsRequestSent(true)

		try {
			const data = new FormData()
			data.append('develop', formValues?.develop)
			data.append('platform', formValues?.platform)
			data.append('technologies', formValues?.technologies)
			data.append('name', formValues?.name)
			data.append('email', formValues?.email)
			data.append('phone_no', formValues?.phone_no)
			data.append('is_active', true)

			const result = await instance.post('service-pricing/', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			if (result?.status === 201) {
				console.log('Application sent Successful')

				toast.success('Application sent sucessfully!')

				setModal(false)
				setThanksShow(true)
				setFormValues({
					how_early: '',
					for_time: '',
					skills: '',
					develop: '',
					platform: '',
					technologies: '',
					how_many_developers: '',
					developers_for_time: '',
					name: '',
					email: '',
					phone_no: '',
				})
				setIsRequestSent(false)
			}
		} catch (error) {
			setIsRequestSent(false)
			toast.error('Error While Submitting Your Request. Try Again')
			console.log({ error })
		}
	}

	const postData = async (e) => {
		e.preventDefault()
		setModal(true)
	}
	return (
		<>
			<div className='col-md-6 col-lg-4 col-xl-4'>
				<Modal
					isOpen={modal}
					// toggle={toggle}
					className='addtask-popups'
				>
					<form onSubmit={sendRequest}>
						<ModalBody>
							<h3 className='text-center text-white'>Product Development</h3>
							<p className='text-center text-white'>
								Fill in the details below and we'll zone in your project in no
								time!
							</p>
							<div className='addtask-body'>
								<div className='form-group'>
									<label htmlFor='recipient-name' className='col-form-label'>
										Full Name :
									</label>
									<input
										type='text'
										className='form-control'
										value={formValues?.name}
										style={{ borderRadius: '20px', height: '3rem', backgroundColor:'white', color:'black' }}
										onChange={(e) =>
											setFormValues({ ...formValues, name: e.target.value })
										}
										required
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='recipient-name' className='col-form-label'>
										Email :
									</label>
									<input
										type='email'
										className='form-control'
										value={formValues?.email}
										style={{ borderRadius: '20px', height: '3rem', backgroundColor:'white',color:'black' }}
										onChange={(e) =>
											setFormValues({ ...formValues, email: e.target.value })
										}
										required
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='recipient-name' className='col-form-label'>
										Phone No :
									</label>
									<PhoneInput
									placeholder="Enter phone number"
									className='form-control '
									inputStyle={{
										border: 'none',
										color: 'black',
									  }}
									style={{ borderRadius: '20px', height: '3rem', display:'flex',color:'black' }}
									value={formValues?.phone_no}
									onChange={(phone) =>
										setFormValues({ ...formValues, phone_no: phone })
									  }
									required
									/>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn'>
								<button onClick={toggle}>Close</button>
							</div>
							<div className='cmn-btn'>
								<button type='submit' disabled={isRequestSent}>
									{isRequestSent ? 'Submitting' : 'Submit'}
								</button>
							</div>
						</ModalFooter>
					</form>
				</Modal>
				<form onSubmit={postData}>
					<div className='single-price-box'>
						<h3>Product Development</h3>

						<span>Get your products built from scratch</span>

						<h2>Product Development</h2>

						<ul className='feature-list'>
							<li>
								<i className='fas fa-check' />
								Free Cost estimation for your product
							</li>
							<li>
								<i className='fas fa-check' />
								Prototype and wireframing for your project
							</li>
							<li>
								<i className='fas fa-check' />
								Premium code quality
							</li>
							<li>
								<i className='fas fa-check' />
								Free dedicated project management
							</li>
							<li>
								<i className='fas fa-check' />
								Free dedicated quality assurance
							</li>
							<li>
								<i className='fas fa-check' />
								dedicated a Timely delivery
							</li>
						</ul>
						<div className='pos'>
							<div className='question-div'>
								<p className='select-heading'>
									What are you looking to develop?
								</p>
								<select
									className='price-input'
									value={formValues.develop}
									onChange={(e) =>
										setFormValues({ ...formValues, develop: e.target.value })
									}
									required
								>
									<option value='' disabled>
										Select..
									</option>
									<option value='MVP'>MVP</option>
									<option value='Complete Product Development'>
										Complete Product Development
									</option>
									<option value='Ui/Ux Design'>Ui/Ux Design</option>
									<option value='Working Prototype'>Working Prototype</option>
								</select>
							</div>
							<div className='question-div'>
								<p className='select-heading'>For which platform?</p>
								<select
									className='price-input'
									value={formValues.platform}
									onChange={(e) =>
										setFormValues({ ...formValues, platform: e.target.value })
									}
									required
								>
									<option value='' disabled>
										Select..
									</option>
									<option value='Web'>Web</option>
									<option value='Mobile'>Mobile</option>
									<option value='Desktop'>Desktop</option>
									<option value='All of them'>All of them</option>
								</select>
							</div>
							<div className='question-div'>
								<p className='select-heading'>Technologies</p>
								<select
									className='price-input'
									value={formValues.technologies}
									onChange={(e) =>
										setFormValues({
											...formValues,
											technologies: e.target.value,
										})
									}
									required
								>
									<option value='' disabled>
										Select..
									</option>
									<option value='Frontend'>Frontend</option>
									<option value='Backend'>Backend</option>
									<option value='QA'>QA</option>
									<option value='UI/UX'>UI/UX</option>
								</select>
							</div>
							<div className='cmn-btn'>
								<button type='submit'>Get A Free Quote</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default ProductDevelopment;

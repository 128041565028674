import React, { useMemo, useState, useEffect } from 'react'
import Table from './components/MonthylTable'
import { instance } from 'index'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useSelector } from 'react-redux'

export default function Payments() {
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(true)
	const [requestsList, setRequestsList] = useState([])

	const getRequest = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get('employee-payment/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)

				const currentMonth = new Date().toLocaleString('default', {
					month: 'long',
				})
				const filteredRequests = result?.data?.results
					?.filter((item) => {
						const createdAtDate = new Date(item.created_at)
						return (
							createdAtDate.toLocaleString('default', { month: 'long' }) ===
							currentMonth
						)
					})

					?.map((request) => ({
						...request,
						client: `${request?.user?.first_name} ${request?.user?.last_name}`,
					}))

				setRequestsList([...filteredRequests])
			}
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'client',
				filterable: false,
				width: 300,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Title',
				accessor: 'title',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Amount',
				accessor: 'amount',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},

			{
				Header: 'Channel',
				accessor: 'channel',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Date',
				accessor: 'created_at',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
						: '',
			},
		],
		[]
	)

	useEffect(() => {
		getRequest()
	}, [])

	return (
		<>
			<div className='container'>
				<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
					<h6 className='text-white tableHeading'>Monthly Payments Dashbord</h6>
				</div>

				<div className='row'>
					<div className='col-12 px-4'>
						{isLoading ? (
							<div className='w-100 d-flex align-items-center justify-content-center height300'>
								<div className='spinner-border text-danger' role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							<Table
								tableStyles={{ tableLayout: 'fixed' }}
								columns={columns}
								initialState={{
									columnVisibility: { conversion: false },
								}}
								data={requestsList}
								total={requestsList?.length}
								customPageSize={1}
								divclassName='table-responsive mb-1'
								tableclassName='align-middle table-nowrap'
								theadclassName='table-light text-muted'
								getRowId={(row, relativeIndex, parent) => row?.id}
								fetchData={() => console.log('fetchData')}
								pageCount={3}
								currentPage={1}
								isNextPage={2}
								isPrevPage={null}
								nextHandler={() => console.log('Next')}
								prevHandler={() => console.log('Prev')}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

import SidebarMenu from './Sidebar';
import Dashbord from './pages/Dashbord';
import MyTime from './pages/MyTime';
import Payments from './pages/Payments';
import Request from './pages/Request';
import ProjectDetail from './pages/ProjectDetail';
import RequestDetail from '../developerDashboard/RequestDetial';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Projects from './pages/Projects';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { handleRole } from 'contants/helper';
import Loader from 'components/common/Loader';

export default function ManagerDashboard() {
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const { isLogin, userInfo } = useSelector((state) => state?.auth);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!isLogin) history.push('/login');
    if (isLogin) handleRole(userInfo?.user?.role?.name, history);
  }, []);
 
  return (
    <>
      {!isLogin ? (
        <Loader />
      ) : (
        <div className='container-fluid pageTop '>
          <div className='row '>
            <SidebarMenu open={open} />
            <div className='col screenHeight padding-20'>
              <Switch>
                {/* <Route exact path={`${path}/my-time`} component={MyTime} /> */}
                <Route exact path={`${path}/payments`} component={Payments} />
                <Route exact path={`${path}/request`} component={Request} />
                <Route exact path={`${path}/projects`} component={Projects} />
                <Route
                  exact
                  path={`${path}/projectDetail`}
                  component={ProjectDetail}
                />
                <Route
                  exact
                  path={`${path}/request-detail`}
                  component={RequestDetail}
                />  
                {/* <Route
                  path={path}
                  component={() => <Dashbord click={handleClick} />}
                /> */}
                <Route
                  path={path}
                  component={() => <MyTime click={handleClick}/>}
                />

              </Switch>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { handleRole } from "contants/helper";
import Loader from "components/common/Loader";
import Hrdashboard from "./HR/HrDashboard";
import AccountsDashboard from "./Accounts/AccountsDashboard";
import BDdashboard from "./BD/BDdashboard";
import ManagerDashboard from "./ProjectManager/ManagerDashboard";
import UserDashboard from "./User/UserDashbord";
import ProjectDetail from "./ProjectManager/ProjectDetail";
import EventDetail from "../adminDashboard/HR/EventDetailPage";
import JobAnalysisDetail from "./HR/JobAnalysisDetail";
import JobAnalysis from "./HR/JobAnalysis";
import Project from "./Accounts/Projects";
import Salary from "./Accounts/SalariesTable";
import Revenue from "./Accounts/RevenueTable";
import ProjectAnalysisTable from "./BD/ProjectAnalysisTable";
import ProjectResourseTable from "./BD/ProjectResourseTable";

export default function AdminDashboard() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { isLogin, userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isLogin) {
      history.push("/login");
    }
    if (isLogin) {
      handleRole(userInfo?.user?.role?.name, history);
    }
  }, [isLogin, userInfo, history]);
  return (
    <>
      {!isLogin ? (
        <Loader />
      ) : (
        <div className="container-fluid pageTop">
          <div className="row flex-nowrap">
            <Sidebar />
            <div className="col p-5" style={{ backgroundColor: "transparent" }}>
              <Switch>
                <Route
                  exact
                  path={`${path}/payments`}
                  component={AccountsDashboard}
                />
                <Route exact path={`${path}/bd`} component={BDdashboard} />
                <Route
                  exact
                  path={`${path}/manager`}
                  component={ManagerDashboard}
                />
                <Route exact path={`${path}/user`} component={UserDashboard} />
                <Route
                  exact
                  path={`${path}/project-Detail`}
                  component={ProjectDetail}
                />
                <Route
                  exact
                  path={`${path}/event-detail`}
                  component={EventDetail}
                />
                <Route
                  exact
                  path={`${path}/jobAnalysis`}
                  component={JobAnalysisDetail}
                />
                <Route
                  exact
                  path={`${path}/jobAnalysis-detail`}
                  component={JobAnalysis}
                />
                <Route
                  exact
                  path={`${path}/payments/project-table`}
                  component={Project}
                />
                <Route
                  exact
                  path={`${path}/payments/salary-table`}
                  component={Salary}
                />
                <Route
                  exact
                  path={`${path}/payments/revenue-table`}
                  component={Revenue}
                />
                <Route
                  exact
                  path={`${path}/bd/project-table`}
                  component={ProjectAnalysisTable}
                />
                <Route
                  exact
                  path={`${path}/bd/resource-table`}
                  component={ProjectResourseTable}
                />
                <Route path={path} component={Hrdashboard} />
              </Switch>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

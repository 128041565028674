import React from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function PortfolioArea(props) {
  const portfolioSlider = {
    slidesPerView: 5,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      // when window width is >= 640px
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      // when window width is >= 1400px
      1400: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
    },
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
		<>
			<SRLWrapper>
				<div className='portfolio-area sec-mar-top padding-20'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-10 col-lg-10 col-sm-9 col-xl-12 col-9'>
								<div className={`title ${props.black}`}>
									<span>Services</span>

									<h2>Save Your Time And Budget</h2>
									{/* <p>Experience the best global tech talent and enjoy the 
                    tech world without getting exhausted through the hiring 
                    process. Hire an expert developer, designer and an engineer now.</p> */}
								</div>
							</div>
						</div>
					</div>
					<div className='swiper portfolio-slider'>
						<Swiper
							{...portfolioSlider}
							pagination={{
								type: 'bullets',
								clickable: true,
								// el:".swiper-pagination"
							}}
							className='swiper-wrapper'
						>
							<SwiperSlide className='swiper-slide'>
								<div className='single-portfolio'>
									<div className='portfolio-data'>
										<button className='text-white bg-transparent border-0'>
											<img
												src={
													process.env.PUBLIC_URL +
													'/images/portfolio/portfolio-1.jpg'
												}
												alt='images'
											/>
										</button>
									</div>
									<div className='portfolio-inner'>
										<span>Website</span>
										<h4>Hire a Web Developer</h4>

										<div className='portfolio-hover'>
											<div className='d-flex flex-column'>
												<p>
													Exploring a reliable software development company?
													You’re at the right place. Hire a Developer now, we at
													Bugdev comprise secure software solutions for any
													device, browser and OS.
												</p>
												<div className='d-flex flex-row justify-content-between'>
													<Link
														onClick={scrollTop}
														to={`${process.env.PUBLIC_URL}/hire-now`}
														className='case-btn'
													>
														Hire a Pro
													</Link>
													<button
														className='text-white bg-transparent border-0'
														data-lightbox='image1'
														href={
															process.env.PUBLIC_URL +
															'/images/portfolio/portfolio-1.jpg'
														}
													>
														{/* <i
															alt='images'
															src={
																process.env.PUBLIC_URL +
																'/images/portfolio/search-2.svg'
															}
															className='fas fa-search'
														/> */}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="card-container">
                 <div className="div_img">
                 <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/web-development.png"
                        }
                        alt="images"
                        width='50px'
                      />
                 </div>
                 <div className="card_data">
                  <h5 className="text-white">Hire a Web Developer</h5>
                  <p>Exploring a reliable software development company? 
                    You’re at the right place. Hire a Developer now, we 
                    at Bugdev comprise secure software solutions for any 
                    device, browser and OS.</p>
                 </div>
                </div> */}
							</SwiperSlide>
							<SwiperSlide className='swiper-slide'>
								<div className='single-portfolio'>
									<div className='portfolio-data'>
										<button className='text-white bg-transparent border-0'>
											<img
												src={
													process.env.PUBLIC_URL +
													'/images/portfolio/portfolio-2.jpg'
												}
												alt='images'
											/>
										</button>
									</div>
									<div className='portfolio-inner'>
										<span>App</span>
										<h4>Hire a App Developer</h4>
										<div className='portfolio-hover'>
											<div className='d-flex flex-column'>
												<p>
													Our skilled app developer can help you create an
													innovative and user-friendly mobile or web application
													that meets your company’s needs. Build custom apps
													from scratch or modify existing ones.
												</p>
												<div className='d-flex flex-row justify-content-between'>
													<Link
														onClick={scrollTop}
														to={`${process.env.PUBLIC_URL}/hire-now`}
														className='case-btn'
													>
														Hire a Pro
													</Link>
													<button
														className='text-white bg-transparent border-0'
														data-lightbox='image1'
														href={
															process.env.PUBLIC_URL +
															'/images/portfolio/portfolio-2.jpg'
														}
													>
														{/* <i
															alt='images'
															src={
																process.env.PUBLIC_URL +
																'/images/portfolio/search-2.svg'
															}
															className='fas fa-search'
														/> */}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="card-container">
                 <div className="div_img">
                 <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/app-development.png"
                        }
                        alt="images"
                        width='50px'
                      />
                 </div>
                 <div className="card_data">
                  <h5 className="text-white">Hire a App Developer</h5>
                  <p>Our skilled app developer can help you create 
                    an innovative and user-friendly mobile or web 
                    application that meets your company’s needs. 
                    Build custom apps from scratch or modify existing ones.</p>
                 </div>
                </div> */}
							</SwiperSlide>
							<SwiperSlide className='swiper-slide'>
								<div className='single-portfolio'>
									<div className='portfolio-data'>
										<button className='text-white bg-transparent border-0'>
											<img
												src={
													process.env.PUBLIC_URL +
													'/images/portfolio/portfolio-3.jpg'
												}
												alt='images'
											/>
										</button>
									</div>
									<div className='portfolio-inner'>
										<span>QA</span>
										<h4>Hire a QA Engineer</h4>
										<div className='portfolio-hover'>
											<div className='d-flex flex-column'>
												<p>
													Bugdev comprises 45+ QA engineers who improve software
													development processes and avoid issues in various
													fields. Get started with our QA engineer today to
													avoid bugs.
												</p>
												<div className='d-flex flex-row justify-content-between'>
													<Link
														onClick={scrollTop}
														to={`${process.env.PUBLIC_URL}/hire-now`}
														className='case-btn'
													>
														Hire a Pro
													</Link>
													<button
														className='text-white bg-transparent border-0'
														data-lightbox='image1'
														href={
															process.env.PUBLIC_URL +
															'/images/portfolio/portfolio-3.jpg'
														}
													>
														{/* <i
															alt='images'
															src={
																process.env.PUBLIC_URL +
																'/images/portfolio/search-2.svg'
															}
															className='fas fa-search'
														/> */}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="card-container">
                 <div className="div_img">
                 <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/qa_icon.png"
                        }
                        alt="images"
                        width='50px'
                      />
                 </div>
                 <div className="card_data">
                  <h5 className="text-white">Hire a QA Engineer</h5>
                  <p>Bugdev comprises 45+ QA engineers who improve 
                    software development processes and avoid issues 
                    in various fields. Get started with our QA engineer 
                    today to avoid bugs.</p>
                 </div>
                </div> */}
							</SwiperSlide>
							<SwiperSlide className='swiper-slide'>
								<div className='single-portfolio'>
									<div className='portfolio-data'>
										<button className='text-white bg-transparent border-0'>
											<img
												src={
													process.env.PUBLIC_URL +
													'/images/portfolio/portfolio-4.jpg'
												}
												alt='images'
											/>
										</button>
									</div>
									<div className='portfolio-inner'>
										<span>UI/UX</span>
										<h4>Hire a UI/UX Designer</h4>
										<div className='portfolio-hover'>
											<div className='d-flex flex-column'>
												<p>
													Hire UI/UX designers to develop responsive pages and
													select the set of interactive elements to be
													displayed. We comprise skilled UI/UX engineers with a
													thorough knowledge of HTML and CSS.
												</p>
												<div className='d-flex flex-row justify-content-between'>
													<Link
														onClick={scrollTop}
														to={`${process.env.PUBLIC_URL}/hire-now`}
														className='case-btn'
													>
														Hire a Pro
													</Link>
													<button
														className='text-white bg-transparent border-0'
														data-lightbox='image1'
														href={
															process.env.PUBLIC_URL +
															'/images/portfolio/portfolio-4.jpg'
														}
													>
														{/* <i
															alt='images'
															src={
																process.env.PUBLIC_URL +
																'/images/portfolio/search-2.svg'
															}
															className='fas fa-search'
														/> */}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="card-container">
                 <div className="div_img">
                 <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/Ai-icon.png"
                        }
                        alt="images"
                        width='50px'
                      />
                 </div>
                 <div className="card_data">
                  <h5 className="text-white">Hire an AI Engineer</h5>
                  <p>Looking for a data science engineer? Try to hire 
                    a machine learning engineer to build AI products. 
                    Hiring an AI engineer helps to automate your business 
                    in many ways.</p>
                 </div>
                </div> */}
							</SwiperSlide>
							<SwiperSlide className='swiper-slide'>
								<div className='single-portfolio'>
									<div className='portfolio-data'>
										<button className='text-white bg-transparent border-0'>
											<img
												src={
													process.env.PUBLIC_URL +
													'/images/portfolio/portfolio-5.jpg'
												}
												alt='images'
											/>
										</button>
									</div>
									<div className='portfolio-inner'>
										<span>Ai</span>
										<h4>Hire an AI Engineer</h4>
										<div className='portfolio-hover'>
											<div className='d-flex flex-column'>
												<p>
													Looking for a data science engineer? Try to hire a
													machine learning engineer to build AI products. Hiring
													an AI engineer helps to automate your business in many
													ways.
												</p>
												<div className='d-flex flex-row justify-content-between'>
													<Link
														onClick={scrollTop}
														to={`${process.env.PUBLIC_URL}/hire-now`}
														className='case-btn'
													>
														Hire a Pro
													</Link>
													<button
														className='text-white bg-transparent border-0'
														data-lightbox='image1'
														href={
															process.env.PUBLIC_URL +
															'/images/portfolio/portfolio-5.jpg'
														}
													>
														{/* <i
															alt='images'
															src={
																process.env.PUBLIC_URL +
																'/images/portfolio/search-2.svg'
															}
															className='fas fa-search'
														/> */}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="card-container">
                 <div className="div_img">
                 <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/Marketing.png"
                        }
                        alt="images"
                        width='50px'
                      />
                 </div>
                 <div className="card_data">
                  <h5 className="text-white">Hire a Digital Marketer</h5>
                  <p>Hire an expert digital marketer specializing in email, 
                    content, inbound, social media, SEO, PR and PPC. We 
                    deliver top-performing marketers to fulfill your needs.</p>
                 </div>
                </div> */}
							</SwiperSlide>
							<SwiperSlide className='swiper-slide'>
								<div className='single-portfolio'>
									<div className='portfolio-data'>
										<button className='text-white bg-transparent border-0'>
											<img
												src={
													process.env.PUBLIC_URL +
													'/images/portfolio/portfolio-3.jpg'
												}
												alt='images'
											/>
										</button>
									</div>
									<div className='portfolio-inner'>
										<span>Markting</span>
										<h4>Hire a Digital Marketer</h4>
										<div className='portfolio-hover'>
											<div className='d-flex flex-column'>
												<p>
													Hire an expert digital marketer specializing in email,
													content, inbound, social media, SEO, PR and PPC. We
													deliver top-performing marketers to fulfill your
													needs.
												</p>
												<div className='d-flex flex-row justify-content-between'>
													<Link
														onClick={scrollTop}
														to={`${process.env.PUBLIC_URL}/hire-now`}
														className='case-btn'
													>
														Hire a Pro
													</Link>
													<button
														className='text-white bg-transparent border-0'
														data-lightbox='image1'
														href={
															process.env.PUBLIC_URL +
															'/images/portfolio/portfolio-3.jpg'
														}
													>
														{/* <i className='fas fa-search' /> */}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="card-container">
                 <div className="div_img">
                 <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/ui_icon.png"
                        }
                        alt="images"
                        width='50px'
                      />
                 </div>
                 <div className="card_data">
                  <h5 className="text-white">Hire a UI/UX Designer</h5>
                  <p>Hire UI/UX designers to develop responsive pages and 
                    select the set of interactive elements to be displayed. 
                    We comprise skilled UI/UX engineers with a thorough knowledge 
                    of HTML and CSS.</p>
                 </div>
                </div> */}
							</SwiperSlide>
						</Swiper>
						<div className='swiper-pagination' />
						<div className='swiper-button-next home-next' />
						<div className='swiper-button-prev home-prev' />
					</div>
				</div>
			</SRLWrapper>
		</>
	)
}

export default PortfolioArea;

import React, { useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { instance } from 'index'
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
} from 'chart.js'
import { useSelector } from 'react-redux'

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
)

export function TransactionChart() {
	const [isLoading, setIsLoading] = useState(false)
	const [userPayment, setUserPayment] = useState([])
	const {access} = useSelector((state)=> state.auth.userInfo)

	const getAllPayments = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get('employee-payment/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				const paymentDataResponse = result?.data.results
				const monthlyPayments = {}
				const currentYear = new Date().getFullYear()

				paymentDataResponse.forEach((payment) => {
					const createdDate = new Date(payment.created_at)
					const paymentYear = createdDate.getFullYear()

					if (paymentYear === currentYear) {
						const month = createdDate.toLocaleString('en-US', { month: 'long' })
						const salary = parseFloat(payment.amount.replace(/[^0-9.-]+/g, ''))

						if (monthlyPayments[month]) {
							monthlyPayments[month] += salary
						} else {
							monthlyPayments[month] = salary
						}
					}
				})

				const allMonths = Array.from({ length: 12 }, (_, i) => {
					const monthName = new Date(null, i).toLocaleString('en-US', {
						month: 'long',
					})
					return {
						month: monthName,
						totalSalary: monthlyPayments[monthName] || 0,
					}
				})

				setUserPayment(allMonths)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	useEffect(() => {
		getAllPayments()
	}, [])

	const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1
	// console.log('payment data',userPayment)
	const data = {
		labels: userPayment.slice(0, currentMonth)?.map((label) => label?.month),
		datasets: [
			{
				type: 'line',
				label: 'Salaries (Pkr)',
				borderColor: 'rgb(255, 99, 132)',
				borderWidth: 2,
				fill: false,
				data: userPayment
					.slice(0, currentMonth)
					?.map((label) => label?.totalSalary),
			},
		],
	}

	return (
		<>
		{console.log("data console", data)}
			<Chart type='bar' data={data} />
		</>
	)
}

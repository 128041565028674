import { instance } from 'index';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { icons } from '../../../../../contants';
import Loader from 'components/common/Loader'
import { useSelector } from 'react-redux'

export default function Users() {
  const history = useHistory();
  const {access} = useSelector((state)=> state.auth.userInfo)
  const [isLoading, setIsLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get('profiles/',{
		headers: {
			'Authorization': `Bearer ${access}`,
		  },
	  });
      if (result?.status === 200) {
        setIsLoading(false);
        setUsersList([
          ...result?.data?.results?.map((user) => {
            return {
              ...user,
              name: `${user?.user?.first_name} ${user?.user?.last_name}`,
              email: user?.user?.email,
            };
          }),
        ]);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  const viewPage = (userID) => {
		history.push({
			pathname: '/viewProfile',
			state: { message: userID },
		})
		scrollTop()
	}
	const userPage = () => {
		history.push('/hr-dashboard/addUser')
	}

	const deleteUser = async (userID) => {
		try {
			const result = await instance.delete(`profiles/${userID}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 204) {
				getUsers()
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	useEffect(() => {
		getUsers()
	}, [])

	return (
		<>
			{isLoading && <Loader />}
			<section className='dashboard__task__wrapper'>
				<div className='myTime d-flex justify-content-between align-items-center mb-5'>
					<h6 className='text-white tableHeading'>Users Dashboard</h6>
					<div className='cmn-btn'>
						<button className='mb-0 mt-1' onClick={userPage}>
							Add User
						</button>
					</div>
				</div>
				<div className='container px-md-0'>
					<div className='row mb-5'>
						{isLoading ? (
							<div className='w-100 d-flex align-items-center justify-content-center height300'>
								<div className='spinner-border text-danger' role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							usersList?.map((user) => {
								return (
									<div className='col-md-6 gy-4 ' key={user?.id}>
										<div className='userCard position-relative'>
											<div
												className='position-absolute d-none user-action_btn'
												style={{ right: '25px' }}
											>
												<button
													className='border-0 bg-transparent'
													onClick={() => deleteUser(user?.id)}
												>
													<i
														className='fa fa-trash'
														style={{
															color: 'white',
															cursor: 'pointer',
															fontSize: '22px',
														}}
													></i>
												</button>
											</div>
											<div className='title justify-content-center'>
												<img
													src={user?.profile_picture || icons.userImg.default}
													alt=''
													className='profile'
													style={{
														width: '70px',
														height: '70px',
														borderRadius: '50%',
														objectFit: 'cover',
													}}
												/>
											</div>
											<div className='mainCard'>
												<div className='contentCard'>
													<h6>{user?.name}</h6>
													<p>{user?.position}</p>
												</div>
												<div className='infoContainer'>
													<div className='infoCard'>
														<h6>Department</h6>
														<p>{user?.user?.department?.name}zxzczxz</p>
													</div>
													<div className='infoCard'>
														<h6>Hire Date</h6>
														<p>{user?.joining_date}</p>
													</div>
												</div>
												<div className='emailInfo'>
													<img
														src={icons.email_icon.default}
														alt=''
														className='Email'
														width='20px'
													/>
													<p>{user?.email}</p>
												</div>
												<div className='cmn-btn justify-content-center'>
													<button
														className='mb-0 mt-3 '
														onClick={() => viewPage(user?.id)}
													>
														View
													</button>
												</div>
											</div>
										</div>
									</div>
								)
							})
						)}
					</div>
				</div>
			</section>
		</>
	)
}
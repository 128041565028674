/* eslint-disable react/jsx-no-target-blank */
import Table from 'components/common/table/Table';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';
import { instance } from 'index';
import { useSelector } from 'react-redux';
import Loader from 'components/common/Loader';

export default function JobAnalysisDetail() {
  const { auth } = useSelector((state) => state);
  const {access} = useSelector((state) => state.auth.userInfo)
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const history = useHistory()
  const columns = useMemo(
    () => [
      {
        Header: 'Employee Name',
        accessor: 'name',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
      },
      {
        Header: 'Position',
        accessor: 'position',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },Cell: (cellProps) => {
            return (
              <div
                key={cellProps.row.original?.id}
                
              >
                {cellProps.row.original?.career?.title}
              </div>
            );
          },
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
            >
              {cellProps.row.original.status}
            </div>
          );
        },
      },
      
      {
        Header: 'Recipiet',
        accessor: 'file',
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
        Cell: (cellProps) => {
          return (
            <div className='d-flex align-items-center justify-content-center'>
            <a
              href={cellProps.row.original.file}
              target='_blank'
              className='text-white text-decoration-underline bg-transparent border-0'
            >
              Download
            </a>
          </div>
          );
        },
      },

      {
        Header: 'Action',
        filterable: false,
        width: 350,
        cellStyle: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: 'break-word',
        },
        Cell: (cellProps) => {
            return (
                <div className='d-flex align-items-center justify-content-center gap-2'>
                  
                        <>
                            <button
                                className='border-0 bg-transparent'
                                onClick={() => getDelete(cellProps.row.original.id)}
                            >
                                <img
                                    src={process.env.PUBLIC_URL + '/images/delete-icon.png'}
                                    alt='images'
                                    style={{ cursor: 'pointer' }}
                                />
                            </button>
                        </>
                  
                    <button
                        className='border-0 bg-transparent'
                        onClick={() => getRequestItem(cellProps.row.original.id)}
                    >
                        <i
                            className='fa fa-eye'
                            style={{
                                color: 'white',
                                cursor: 'pointer',
                                fontSize: '22px',
                            }}
                        ></i>
                    </button>
                </div>
            )
        },
    },
    ],
    []
  );

  const getAllApplications = async () => {
    
    setIsLoading(true);
    try {
     
      const result = await instance.get(`job-application/`,{
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      
      if (result?.status === 200) {
        setIsLoading(false);
        setApplication(result?.data?.results); 
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const getDelete = async (itemId) => {
    setIsLoading(true)
    try {
        const result = await instance.delete(`job-application/${itemId}/`,{
            headers: {
                'Authorization': `Bearer ${access}`,
              },
        })
        setIsLoading(false)
        if (result?.status === 204) {
            setIsLoading(false)
            getAllApplications();
        }
    } catch (error) {
        setIsLoading(false)
        console.log({ error })
    }
}

const getRequestItem = async (id) => {
    history.push({
        pathname: '/admin-dashboard/jobAnalysis-detail',
        state: { message: id },
    })
}

  useEffect(() => {
    getAllApplications();
  }, []);

  return (
		<>
            {isLoading && <Loader />}
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white PaytableHeading'>Job Applications Dashboard</h6>
			</div>

			<div className='row'>
				<div className='col-12'>
					{application && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={application}
							total={application?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={
								() => console.log('Next')
							}
							prevHandler={
								() => console.log('Prev')
							}
						/>
					)}
				</div>
			</div>
		</>
	)
}

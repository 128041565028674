import React from 'react';
import { Bar } from 'react-chartjs-2';
import { instance } from 'index';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

const ProjectChart = () => {
  const {access} = useSelector((state) => state.auth.userInfo)
  const [projects, setProjects] = useState([]);
  
  const getProjects = async()=>{
    try {
      const result = await instance.get('projects/',{
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      })
      if (result.status === 200)
      {
        const projectDataResponse = result.data.results;
        const monthlyProjects = {};
        const currentYear = new Date().getFullYear();

        projectDataResponse.forEach((project) => {
          const createdDate = new Date(project.created_at);
          const eventYear = createdDate.getFullYear();
  
          if (eventYear === currentYear) {
            const month = createdDate.toLocaleString('en-US', { month: 'long' });
  
            if (monthlyProjects[month]) {
              monthlyProjects[month]++; // Increment the count for the month
            } else {
              monthlyProjects[month] = 1; // Initialize count to 1 for the month
            }
          }
        });
        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(currentYear, i).toLocaleString('en-US', {
            month: 'long',
          });
          return {
            month: monthName,
            totalProjects:monthlyProjects[monthName] || 0,
          };
        });
        setProjects(allMonths);
      }
    } catch(error){
      console.log({error})
    }
  }

  useEffect(()=>{
    getProjects();
  },[])

  const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1

  const data = {
   labels: projects.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: 'Total Projects',
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Color of the bars
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1, // Thin border for the bars
        data: projects.slice
        (0, currentMonth)
        ?.map((label) => label?.totalProjects),
      },
    ],
  };

  const options = {
    scales: {
      x: {
        barPercentage: 0.4, // Adjust the width of the bars (0.4 = 40% of the available space)
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="slim-column-chart">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProjectChart;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false,
  userInfo: null,
  comment:{},
  currenttab:'my time',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleAuth: (state, { payload }) => {
      state.isLogin = payload?.isLogin;
      state.userInfo = payload?.userInfo;
    },
    Comment: (state, action)=> {
      state.comment = action.payload
    },
    CurrentTab: (state, action)=> {
      state.currenttab = action.payload
    }
  },
});

export const { toggleAuth, Comment, CurrentTab } = authSlice.actions;

export default authSlice.reducer;

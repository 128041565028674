export const handleRole = (role, route) => {
  if (role?.toLowerCase() === 'developer') {
    return route?.push('/developer-dashboard');
  }
  if (role?.toLowerCase() === 'project manager') {
    return route?.push('/manager-dashboard');
  }
  if (role?.toLowerCase() === 'business developer') {
    return route?.push('/business-dashboard');
  }
  if (role?.toLowerCase() === 'accounts') {
    return route?.push('/account-dashboard');
  }
  if (role?.toLowerCase() === 'hr') {
    return route?.push('/hr-dashboard');
  }
  if (role?.toLowerCase() === 'admin') {
    return route?.push('/admin-dashboard');
  }
  return route?.push('/login');
};

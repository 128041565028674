import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function TestimonialArea() {
  const testimonialSlider = {
    slidesPerView: 1,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <>
      {/* <section className="testimonial-area padding-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
               <div className="swiper testimonial-slider">
                <Swiper
                  pagination={{
                    type: "fraction",
                  }}
                  {...testimonialSlider}
                  className="swiper-wrapper"
                >
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        <div className="client-pic">
                          <img
                            src={process.env.PUBLIC_URL + "/images/ceo.jpeg"}
                            alt="images"
                          />
                        </div>
                        <div className="client-details">
                          <h4>Usama Haider</h4>
                          <span>Executive CEO</span>
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> 
                        Having 5+ years of experience working
                         with full-stack technologies, Android & iOS (Native + Hybrid), 
                         database and back-end systems architecture & development, and AI & ML.{" "}
                        <i className="fas fa-quote-right" />
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        <div className="client-pic">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/cfo.png"
                            }
                            alt="images"
                          />
                        </div>
                        <div className="client-details">
                          <h4>Irfan Ali</h4>
                          <span>CTO Founder</span>
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> 
                        I’m a professional MEAN Stack / MERN Stack / MEVN Stack
                         / Front End Developer / user interface designer with a 
                         keen eye for detail, and a determination to deliver the 
                         very highest quality. I have working experience on both 
                         Project Base and Products Base Projects.{" "}
                        <i className="fas fa-quote-right" />
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        <div className="client-pic">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/head.png"
                            }
                            alt="images"
                          />
                        </div>
                        <div className="client-details">
                          <h4>Ahtasham ALi</h4>
                          <span>Designer Head</span>
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> 
                        I am a highly creative & multitalented Senior UX/UI
                         and Graphic Designer with hands on experience in | 
                         Blockchain Products | Metaverse | NFTs | User Experience
                          | User Interface | Mobile Applications | Product Design 
                          | Branding and much more I always try to focus on the 
                          Business USPs (Unique selling points) on user end and 
                          on the key business indicators (and KPI) with a strong 
                          analytical mindset and great problem-solving skills. {" "}
                        <i className="fas fa-quote-right" />
                      </p>
                      
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="swiper-button-next" />
                <div className="swiper-button-prev" />
                <div className="swiper-pagination" />
              </div> 
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default TestimonialArea;

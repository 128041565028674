import React from 'react'

const SettingDetail = ({ userData, handleInputChange, handleUpdate }) => {
	return (
		<div className='col-lg-7 col-md-12 right'>
			<div className='card'>
				<p className='title'>Welcome to the information</p>
				<p className='description'>
					{' '}
					Check or change your information as you want
				</p>
				<div className='row'>
					<div className='col-md-6 col-sm-12'>
						<label id='first-name' className='form-label'>
							First Name
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.first_name}
							name='first_name'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='last-name' className='form-label'>
							Last Name
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.last_name}
							name='last_name'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='email' className='form-label'>
							Email
						</label>
						<input
							className='form-control bd-input'
							type={'email'}
							value={userData?.email}
							name='email'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='birthday' className='form-label'>
							Birthday
						</label>
						<input
							className='form-control bd-input'
							type={'date'}
							value={userData?.date_of_birth}
							name='date_of_birth'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='phone' className='form-label'>
							Phone
						</label>
						<input
							className='form-control bd-input'
							type={'text'}
							value={userData?.phone_no}
							name='phone_no'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							Gender
						</label>
						<select
							className='form-control bd-input'
							name='gender'
							value={userData?.gender}
							onChange={handleInputChange}
						>
							<option value=''>Select gender</option>
							<option value='MALE'>Male</option>
							<option value='FEMALE'>Female</option>
							<option value='OTHER'>Other</option>
						</select>
					</div>
				</div>
				<div className='cmn-btn'>
					<button onClick={handleUpdate}>Update</button>
				</div>
			</div>
		</div>
	)
}

export default SettingDetail

import React,{useState} from "react";
import { Link } from "react-router-dom";
import { instance } from "index";
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


function PriceBox({ thanksShow, setThanksShow }) {
	const [modal, setModal] = React.useState(false)
	const [isRequestSent, setIsRequestSent] = useState(false)
	const [value, setValue] = useState()
	const [formValues, setFormValues] = useState({
		how_early: '',
		for_time: '',
		skills: '',
		develop: '',
		platform: '',
		technologies: '',
		how_many_developers: '',
		developers_for_time: '',
		name: '',
		email: '',
		phone_no: '',
	})
	const toggle = () => {
		setModal(false)
	}

	const sendRequest = async (e) => {
		setIsRequestSent(true)
		e.preventDefault()
		try {
			const data = new FormData()
			data.append('how_early', formValues?.how_early)
			data.append('for_time', formValues?.for_time)
			data.append('skills', formValues?.skills)
			data.append('name', formValues?.name)
			data.append('email', formValues?.email)
			data.append('phone_no', formValues?.phone_no)
			data.append('is_active', true)

			const result = await instance.post('service-pricing/', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			if (result?.status === 201) {
				console.log('Application sent Successful')

				toast.success('Application sent sucessfully!')

				setModal(false)
				setThanksShow(true)
				setFormValues({
					how_early: '',
					for_time: '',
					skills: '',
					develop: '',
					platform: '',
					technologies: '',
					how_many_developers: '',
					developers_for_time: '',
					name: '',
					email: '',
					phone_no: '',
				})
				setIsRequestSent(true)
			}
		} catch (error) {
			setIsRequestSent(false)
			toast.error('Error While Submitting Your Request. Try Again')
			console.log({ error })
		}
	}

	const postData = async (e) => {
		e.preventDefault()
		setModal(true)
	}

	return (
		<>
			<Modal
				isOpen={modal}
				// toggle={toggle}
				className='addtask-popups'
			>
				<form onSubmit={sendRequest}>
					<ModalBody>
						<h3 className='text-center text-white'>
							Staff/Resource Augmentation
						</h3>
						<p className='text-center text-white'>
							Fill in the details below and we'll zone in your project in no
							time!
						</p>
						<div className='addtask-body'>
							<div className='form-group'>
								<label htmlFor='recipient-name' className='col-form-label'>
									Full Name :
								</label>
								<input
									type='text'
									className='form-control'
									value={formValues?.name}
									style={{ borderRadius: '20px', height: '3rem', backgroundColor:'white', color:'black' }}
									onChange={(e) =>
										setFormValues({ ...formValues, name: e.target.value })
									}
									required
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='recipient-name' className='col-form-label'>
									Email :
								</label>
								<input
									type='email'
									className='form-control'
									value={formValues?.email}
									style={{ borderRadius: '20px', height: '3rem', backgroundColor:'white',color:'black' }}
									onChange={(e) =>
										setFormValues({ ...formValues, email: e.target.value })
									}
									required
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='recipient-name' className='col-form-label'>
									Phone No :
								</label>
								
								 <PhoneInput
									placeholder="Enter phone number"
									className='form-control '
									inputStyle={{
										border: 'none',
										color: 'black',
									  }}
									style={{ borderRadius: '20px', height: '3rem', display:'flex',color:'black' }}
									value={formValues?.phone_no}
									onChange={(phone) =>
										setFormValues({ ...formValues, phone_no: phone })
									  }
									required
									/>
									
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='cmn-btn'>
							<button onClick={toggle}>Close</button>
						</div>
						<div className='cmn-btn'>
							<button type='submit' disabled={isRequestSent}>
								{isRequestSent ? 'Submitting' : 'Submit'}
							</button>
						</div>
					</ModalFooter>
				</form>
			</Modal>

			<div className='col-md-6 col-lg-4 col-xl-4'>
				<form onSubmit={postData}>
					<div className='single-price-box'>
						<h3>Staff/Resource Augmentation</h3>

						<span>Hire top talent at a fraction of the cost</span>

						<h2>Staff/Resource Augmentation</h2>

						<ul className='feature-list'>
							<li>
								<i className='fas fa-check' />
								Vetted developer profiles in 48 hours
							</li>
							<li>
								<i className='fas fa-check' />
								Staffing developer within 1-2 weeks
							</li>
							<li>
								<i className='fas fa-check' />
								Availability of developers in every tech stack
							</li>
							<li>
								<i className='fas fa-check' />
								No hiring and retention stress
							</li>
							<li>
								<i className='fas fa-check' />
								Management and payroll freedom
							</li>
							<li>
								<i className='fas fa-check' />
								Schedule a 7-day trial 
							</li>
						</ul>
						<div className='pos'>
							<div className='question-div'>
								<p className='select-heading'>
									How early do you need a developer?
								</p>
								<select
									className='price-input'
									value={formValues.how_early}
									onChange={(e) =>
										setFormValues({ ...formValues, how_early: e.target.value })
									}
									required
								>
									<option value='' disabled>
										Select..
									</option>
									<option value='Within 7 days'>Within 7 days</option>
									<option value='Within 2 weeks'>Within 2 weeks</option>
									<option value='in a month'>in a month</option>
									<option value='Not Sure'>Not Sure</option>
								</select>
							</div>
							<div className='question-div'>
								<p className='select-heading'>For how long?</p>
								<select
									className='price-input'
									value={formValues.for_time}
									onChange={(e) =>
										setFormValues({ ...formValues, for_time: e.target.value })
									}
									required
								>
									<option value='' disabled>
										Select..
									</option>
									<option value='1-3 months'>1-3 months</option>
									<option value='3-6 months'>3-6 months</option>
									<option value='6-10 months'>6-10 months</option>
									<option value='10+ months'>10+ months</option>
								</select>
							</div>
							<div className='question-div'>
								<p className='select-heading'>Skills</p>
								<select
									className='price-input'
									value={formValues.skills}
									onChange={(e) =>
										setFormValues({ ...formValues, skills: e.target.value })
									}
									required
								>
									<option value='' disabled>
										Select..
									</option>
									<option value='Frontend'>Frontend</option>
									<option value='Backend'>Backend</option>
									<option value='QA'>QA</option>
									<option value='UI/UX'>UI/UX</option>
								</select>
							</div>
							<div className='cmn-btn'>
								<button type='submit'>Get A Free Quote</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default PriceBox;

import Sidebar from './Sidebar';
import Dashbord from './pages/Dashbord';
import MyTime from './pages/MyTime';
import Payments from './pages/Payments';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Users from './pages/Users';
import Events from './pages/Events';
import Benefits from './pages/Benefits';
import Attendance from './pages/Attendance';
import EventDetail from './pages/EventDetail';
import AddUser from './pages/userDetail/AddUser';
import BankDetail from './pages/userDetail/BankDetail';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { handleRole } from 'contants/helper';
import Loader from 'components/common/Loader';
import RequestDetail from './RequestDetail';
import JobApplications from './pages/JobApplications';
import Request from './pages/Request';
import Jobs from './pages/Jobs';
import JobDetail from './JobDetail';

export default function HrDashboard() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { isLogin, userInfo } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (!isLogin) history.push('/login');
    if (isLogin) handleRole(userInfo?.user?.role?.name, history);
  }, []);

  return (
		<>
			{!isLogin ? (
				<Loader />
			) : (
				<div className='container-fluid pageTop'>
					<div className='row flex-nowrap'>
						<Sidebar />
						<div
							className='col px-0 py-5 p-md-5 padding-20'
							style={{ backgroundColor: 'transparent' }}
						>
							<Switch>
								<Route exact path={`${path}/my-time`} component={MyTime} />
								<Route exact path={`${path}/payments`} component={Payments} />
								<Route exact path={`${path}/request`} component={Request} />
								<Route exact path={`${path}/job-application`} component={JobApplications} />
								<Route exact path={`${path}/users`} component={Users} />
								<Route exact path={`${path}/events`} component={Events} />
								<Route exact path={`${path}/benefits`} component={Benefits} />
								<Route exact path={`${path}/jobs`} component={Jobs} />
								<Route
									exact
									path={`${path}/attendance`}
									component={Attendance}
								/>
								<Route
									exact
									path={`${path}/eventDetail`}
									component={EventDetail}
								/>
								<Route exact path={`${path}/addUser`} component={AddUser} />
								<Route
									exact
									path={`${path}/userBankDetail`}
									component={BankDetail}
								/>
								<Route
									exact
									path={`${path}/request-detail`}
									component={RequestDetail}
								/>
								<Route
									exact
									path={`${path}/job-detail`}
									component={JobDetail}
								/>
								<Route path={path} component={Dashbord} />
							</Switch>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

import React from 'react'

import Breadcrumb from '../../common/Breadcrumb'
import Footer from 'components/common/Footer'
import HeroForm from './HeroForm'


const ContactUs = () => {
	return (
		<>
			<Breadcrumb pageName='Contact Us' />
			<div className='contact-area sec-mar'>
				<HeroForm />
			</div>
			<Footer />
		</>
	)
}

export default ContactUs

import React from 'react';
import { icons } from '../../../../../contants';

export default function EventDetail() {
  return (
    <>
      <div className='col-12 d-flex align-items-center justify-content-between ms-3'>
        <p className='title'>Gaming Night</p>
      </div>
      <div className='d-flex justify-content-center mt-3 '>
        <img src={icons.game_event} alt='' className='event_fulimg' />
      </div>
      <div className='col-lg-4 col-md-6 col-sm-12 w-100 mt-5 ms-3'>
        <p className='title'>Description</p>
        <p className='description'>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged
        </p>
      </div>
      <div className='col-12 d-flex align-items-center justify-content-between ms-3 mt-5'>
        <p className='title'>Tags</p>
      </div>
      <div className='Container_eventDetail'>
        <div className='event_Tag'>
          <p>Gamming</p>
        </div>
        <div className='event_Tag'>
          <p>Project</p>
        </div>
        <div className='event_Tag'>
          <p>Programing</p>
        </div>
      </div>
      <div className='d-flex justify-content-between ms-3'>
        <div className='d-flex gap-2 mt-5'>
          <img
            src={icons.clock.default}
            alt=''
            className='profile mb-2'
            width='20px'
          />
          <h6 className='text-white mt-2'>Start Date</h6>
        </div>
        <div className='d-flex gap-2 mt-5'>
          <img
            src={icons.clock.default}
            alt=''
            className='profile mb-2'
            width='20px'
          />
          <h6 className='text-white mt-2'>End Date</h6>
        </div>
      </div>
    </>
  );
}

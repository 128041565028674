import React from 'react'
import ProjectDetailPage from './components/ProjectDetailPage';
import { useLocation } from 'react-router-dom';
export default function ProjectDetail(props) {
  
  const location = useLocation();
  
  const message = location.state?.message;
  return (
    <>
    <div className='myTime d-flex justify-content-between  align-items-center mb-5'>
        <h6 className='text-white PaytableHeading'>Projects Dashboard</h6>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card  table-card overflow-auto' style={{backgroundColor:'transparent'}}>
            <ProjectDetailPage message={message}/>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react';
import ReactDOM from 'react-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { Provider } from 'react-redux';
import { persistor, store } from 'app/store';
import App from 'App';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
// all css import
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from 'components/common/Loader';

export const instance = axios.create({
  baseURL: 'https://api.bugdev.co.uk/api/',
//   baseURL: 'https://sea-turtle-app-thumu.ondigitalocean.app/api/',
  headers: { 
	'Content-Type': 'application/json'
},
});

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={<Loader />} persistor={persistor}>
			<SimpleReactLightbox>
				<App />
				<ToastContainer />
			</SimpleReactLightbox>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
)

import React from 'react'

const UserBankDetail = ({
	userDetail,
	handleInputChange,
	handleBankUpdate,
}) => {
	return (
		<div className='col-lg-7 col-md-12 right px-3'>
			<div className='card'>
				<div className='row'>
					<div className='col-md-6 col-sm-12'>
						<label id='first-name' className='form-label'>
							Account Title
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.account_holder_name}
							name='account_holder_name'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='last-name' className='form-label'>
							Bank Name
						</label>
						<input
							className='view_profile profile-input'
							type={'email'}
							value={userDetail?.bank_name}
							name='bank_name'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='email' className='form-label'>
							IBAN
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.account_iban}
							onChange={handleInputChange}
							name='account_iban'
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='birthday' className='form-label'>
							City
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.city}
							name='city'
							onChange={handleInputChange}
						/>
					</div>
				</div>
				<div className='cmn-btn'>
					<button onClick={handleBankUpdate}>Update</button>
				</div>
			</div>
		</div>
	)
}

export default UserBankDetail

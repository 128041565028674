import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Sidebar from './Sidebar';
import Dashbord from './pages/Dashbord';
import MyTime from './pages/MyTime';
import Payments from './pages/Payments';
import MonthlySalary from './pages/MonthlySalary'
import Request from './pages/Request';
import Salaries from './pages/Salaries';
import OverTime from './pages/OverTime';
import Employee from './pages/Employee';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { handleRole } from 'contants/helper';
import Loader from 'components/common/Loader';
import RequestDetail from './RequestDetail';
import Projects from './pages/Projects'
import ProjectDetail from '../managerDashboard/pages/ProjectDetail'
import PaymentDetail from './pages/PaymentDetail'
import PaymentDetails from './pages/PaymentDetails'

export default function AccountDashboard() {
	const { path } = useRouteMatch()
	const history = useHistory()
	const { isLogin, userInfo } = useSelector((state) => state?.auth)

	useEffect(() => {
		if (!isLogin) history.push('/login')
		if (isLogin) handleRole(userInfo?.user?.role?.name, history)
	}, [])

	return (
		<>
			{!isLogin ? (
				<Loader />
			) : (
				<div className='container-fluid pageTop'>
					<div className='row flex-nowrap'>
						<Sidebar />
						<div className='col p-5' style={{ backgroundColor: 'transparent' }}>
							<Switch>
								<Route exact path={`${path}/my-time`} component={MyTime} />
								<Route exact path={`${path}/payments`} component={Payments} />
								<Route exact path={`${path}/request`} component={Request} />
								<Route exact path={`${path}/salaries`} component={Salaries} />
								<Route exact path={`${path}/over-time`} component={OverTime} />
								<Route exact path={`${path}/employee`} component={Employee} />
								<Route exact path={`${path}/projects`} component={Projects} />
								<Route
									exact
									path={`${path}/projectDetail`}
									component={ProjectDetail}
								/>
								<Route
									exact
									path={`${path}/payment-detail`}
									component={PaymentDetails}
								/>
								<Route
									exact
									path={`${path}/monthly-detail`}
									component={MonthlySalary}
								/>
								<Route
									exact
									path={`${path}/request-detail`}
									component={RequestDetail}
								/>
								<Route path={path} component={Dashbord} />
							</Switch>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

import React, { useMemo, useState, useEffect } from 'react';
import Table from 'components/common/table/Table'
import { useSelector } from 'react-redux'
import { instance } from 'index'
import { toast } from 'react-toastify'
import Loader from 'components/common/Loader'
import { useHistory } from 'react-router-dom'

export default function Payments() {
	const [modal, setModal] = React.useState(false)
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(true)
	const [requestsList, setRequestsList] = useState([])
	const history = useHistory()
	const [formValues, setFormValues] = useState({ title: '', description: '' })
	

	const getRequest = async (userId) => {
		setIsLoading(true)
		try {
			const result = await instance.get('employee-payment/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setRequestsList([
					...result?.data?.results
						?.filter((item) => item.user.id === userId)
						?.map((request) => {
							return {
								...request,
								client: `${request?.user?.first_name} ${request?.user?.last_name}`,
							}
						}),
				])
			}
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	const getEmployeeRequest = async (userId) => {
		setIsLoading(true)
		try {
			const result = await instance.get('employee-payment/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				setRequestsList([
					...result?.data?.results
						?.filter((item) => item.user.id !== userId)
						?.map((request) => {
							return {
								...request,
								client: `${request?.user?.first_name} ${request?.user?.last_name}`,
							}
						}),
				])
			}
		} catch (error) {
			setIsLoading(false)
			toast.error(error.message)
		}
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'client',
				filterable: false,
				width: 300,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Title',
				accessor: 'title',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Amount',
				accessor: 'amount',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
						>
							{cellProps.row.original.status}
						</div>
					)
				},
			},
			{
				Header: 'Channel',
				accessor: 'channel',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Recipiet',
				accessor: 'receipt',
				filterable: false,
				width: 350,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div className='d-flex align-items-center justify-content-center'>
							<button className='text-white text-decoration-underline bg-transparent border-0'>
								Download
							</button>
						</div>
					)
				},
			},
		],
		[]
	)

	const sendRequest = async () => {
		if (!formValues?.title || !formValues?.description)
			return toast.error('All fields are required!')
		try {
			const result = await instance.post('internal-requests/', {
				...formValues,
				user: auth?.userInfo?.user?.id,
			})
			if (result?.status === 201) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getRequest(auth?.userInfo?.user?.id)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	useEffect(() => {
		getRequest(auth?.userInfo?.user?.id)
	}, [])

	return (
		<>
			{isLoading && <Loader />}
			<div className='container'>
				<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
					<h6 className='text-white tableHeading'>Payments Dashbord</h6>
				</div>
				{/* <div style={{ display: 'block' }}>
					<Modal
						isOpen={modal}
						toggle={() => setModal(true)}
						className='addtask-popups'
					>
						<ModalBody>
							<h3 className='text-center text-white'>Submit Your Request</h3>
							<div className='addtask-body'>
								<div className='form-group'>
									<label htmlFor='recipient-name' className='col-form-label'>
										Title:
									</label>
									<input
										type='text'
										className='form-control'
										value={formValues?.title}
										style={{ borderRadius: '20px', height: '3rem' }}
										onChange={(e) =>
											setFormValues({ ...formValues, title: e.target.value })
										}
										id='recipient-name'
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='message-text' className='col-form-label'>
										Description:
									</label>
									<textarea
										className='form-control'
										value={formValues?.description}
										onChange={(e) =>
											setFormValues({
												...formValues,
												description: e.target.value,
											})
										}
										style={{ height: '150px', borderRadius: '20px' }}
										id='message-text'
									></textarea>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn'>
								<button onClick={toggle}>Close</button>
							</div>
							<div className='cmn-btn'>
								<button onClick={sendRequest}>Submit</button>
							</div>
						</ModalFooter>
					</Modal>
				</div> */}
				<div className='row'>
					<div className='col-12 px-4'>
						{isLoading ? (
							<div className='w-100 d-flex align-items-center justify-content-center height300'>
								<div className='spinner-border text-danger' role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							<Table
								tableStyles={{ tableLayout: 'fixed' }}
								columns={columns}
								initialState={{
									columnVisibility: { conversion: false },
								}}
								data={requestsList}
								total={requestsList?.length}
								customPageSize={1}
								divclassName='table-responsive mb-1'
								tableclassName='align-middle table-nowrap'
								theadclassName='table-light text-muted'
								getRowId={(row, relativeIndex, parent) => row?.id}
								fetchData={() => console.log('fetchData')}
								pageCount={3} 
								currentPage={1}
								isNextPage={2}
								isPrevPage={null}
								nextHandler={() => console.log('Next')}
								prevHandler={() => console.log('Prev')}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

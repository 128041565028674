import Sidebar from './Sidebar';
import Dashbord from './pages/Dashbord';
import MyTime from './pages/MyTime';
import Payments from './pages/Payments';
import Request from './pages/Request';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Projects from './pages/Projects';
import Clients from './pages/Clients';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { handleRole } from 'contants/helper';
import Loader from 'components/common/Loader';
import RequestDetail from './RequestDetail';
import ProjectDetail from './pages/ProjectDetail';
import PendingProject from './pages/PendingProjects'
import DeliveredProject from './pages/DeliveredProjects'

export default function BusinessDashboard() {
	const { path } = useRouteMatch()
	const history = useHistory()
	const { isLogin, userInfo } = useSelector((state) => state?.auth)

	useEffect(() => {
		if (!isLogin) history.push('/login')
		if (isLogin) handleRole(userInfo?.user?.role?.name, history)
	}, [])

	return (
		<>
			{!isLogin ? (
				<Loader />
			) : (
				<div className='container-fluid pageTop '>
					<div className='row flex-nowrap'>
						<Sidebar />
						<div
							className='col p-5 padding-20'
							style={{ backgroundColor: 'transparent' }}
						>
							<Switch>
								<Route exact path={`${path}/my-time`} component={MyTime} />
								<Route exact path={`${path}/payments`} component={Payments} />
								<Route exact path={`${path}/request`} component={Request} />
								<Route
									exact
									path={`${path}/pending-projects`}
									component={PendingProject}
								/>
								<Route
									exact
									path={`${path}/delivered-projects`}
									component={DeliveredProject}
								/>
								<Route exact path={`${path}/projects`} component={Projects} />
								{/* <Route exact path={`${path}/clients`} component={Clients} /> */}
								<Route
									exact
									path={`${path}/request-detail`}
									component={RequestDetail}
								/>
								<Route
									exact
									path={`${path}/projectDetail`}
									component={ProjectDetail}
								/>
								<Route path={path} component={Dashbord} />
							</Switch>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentTab } from 'redux/auth/authSlice';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function SidebarMenu({ open }) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch= useDispatch()
  const currenttab = useSelector((state)=> state.auth)
  const [menus] = useState([
    // {
    //   name: 'Dashbord',
    //   href: '/manager-dashboard',
    // },
    {
      name: 'My Time',
      href: '/manager-dashboard/my-time',
    },
    { 
      name: 'Payments',
      href: '/manager-dashboard/payments',
    },
    {
      name: 'Request',
      href: '/manager-dashboard/request',
    },
    {
      name: 'Projects',
      href: '/manager-dashboard/projects',
    },
  ]);
  const location = useLocation(); 

  useEffect(() => {}, [location]);

  useEffect(() => {
    document.body.classList.add('grey');
    return () => {
      document.body.classList.remove('grey');
    };
  }, []);

  useEffect(()=>{
    dispatch(CurrentTab('my time'))
  },[])

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`${isOpen ? "toggle-button2" : "toggle-button"}`} onClick={toggleSidebar} style={{width:"30px",height:"30px"}}>
        <MdOutlineKeyboardArrowRight className={`${!isOpen ? "arrowbtnrotate" : "arrowbtn"}`} />
      </div>
      <div
        className={` ${
          isOpen ? " open" : "unopen"
        }  col-auto col-lg-3 col-xl-2 px-0 `}
        style={{
          minHeight: "100vh",
          backgroundColor: "black",
          borderRight: "1px solid",
          borderColor: "red",
          marginTop:"-50px"
        }}
      >
        <div className="d-flex flex-column align-items-center align-items-sm-start ps-3 pt-2 text-white min-vh-100" style={{marginTop:"65px"}}>
          <ul
            className='w-100 nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start'
            id='menu'
          >
            {menus?.map((item, index) => {
              // console.log("data....",location?.pathname );
              // console.log("Current tab..", currenttab.currenttab);

              return (
								<li
									className='w-100'
									key={`${item?.name?.toLowerCase()}${index}`}
									onClick={() => dispatch(CurrentTab(item.name.toLowerCase()))}
								>
									<Link
										to={item?.href}
										className={`${
											item?.name.toLowerCase() === currenttab.currenttab
												? 'active'
												: ''
										} sidebar_btn px-0 align-middle w-100 `}
									>
										<span className='ms-1 d-inline'>
											{item?.name}
										</span>
									</Link>
								</li>
							)   
            })}
          </ul>
          <hr />
        </div>
      </div>
      {/* <div className='sidebar_container'>
        <div className='toggle_btn'>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className='nav_container'>
          <li >
            <a href='#' className='text-white'>Dashboard</a></li>
          <li ><a href='#' className='text-white'>My Time</a></li>
          <li ><a href='#' className='text-white'>Request</a></li>
          <li ><a href='#' className='text-white'>Payment</a></li>
        </ul>
        
      </div> */}
    </>
  );
}

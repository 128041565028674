import React, { useState, useEffect, useRef } from 'react'
import { instance } from 'index'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import dropdown from '../../../../../../assets/images/drop-down.png'
import moment from 'moment'
import Loader2 from 'components/common/Loader2'
import { toast } from 'react-toastify'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'


const RequestDetailPage = ({ message }) => {
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(false)
	const [cardDetail, setCradDetail] = useState(null)
	const [formValues, setFormValues] = useState({ title: '', description: '' })
	const [modal, setModal] = useState(false)
	const { path } = useRouteMatch()
	const [isOpen, setIsOpen] = useState(false)

	const history = useHistory()
	const toggle = () => {
		setModal(!modal)

		setFormValues({
			...formValues,
			description: '',
			title: '',
		})
	}
	const getEdit = async () => {
		const data = {...formValues,
			is_active:true
		}
		try {
			const result = await instance.patch(`internal-requests/${message}/`,data, {
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})

			if (result?.status === 200) {
				getAllDetail(message)
				toast.success('Request sent sucessfully!')
				setModal(!modal)

				setFormValues({
					...formValues,
					description: '',
					title: '',
				})
			}
		} catch (error) {
			console.log({ error })
		}
	}
	const getAllDetail = async (message) => {
		setIsLoading(true)
		try {
			const result = await instance.get(`internal-requests/${message}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})

			if (result?.status === 200) {
				setIsLoading(false)
				setCradDetail(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	const getDelete = async (itemId) => {
		try {
			const result = await instance.delete(`internal-requests/${itemId}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 204) {
				history.push(`${path}/request`)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	const editToggle = () => {
		setFormValues({
			...formValues,
			description: cardDetail?.description,
			title: cardDetail?.title,
		})

		setModal(!modal)
	}

	const handleStatus = async (e) => {
		setCradDetail((prev) => ({ ...prev, status: e.target.id }))
		const updatedStatus = e.target.id
		const data = new FormData()
		data.append('status', updatedStatus)
		try {
			const result = await instance.patch(`internal-requests/${message}/`, data,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				toast.success('Status Updated sucessfully!')
				history.goBack();
			}
		} catch (error) {
			toast.error('Oops! Error while updating status')
		}
	}

	useEffect(() => {
		getAllDetail(message)
	}, [])
	return (
		<>
			{isLoading && <Loader2 />}
			<div style={{ display: 'block' }}>
				<Modal
					isOpen={modal}
					toggle={() => setModal(true)}
					className='addtask-popups'
				>
					<ModalBody>
						<h3 className='text-center text-white'>Submit Your Request</h3>
						<div className='addtask-body'>
							<div className='form-group'>
								<label htmlFor='recipient-name' className='col-form-label'>
									Title:
								</label>
								<input
									type='text'
									className='form-control'
									value={formValues?.title}
									style={{ borderRadius: '20px', height: '3rem' }}
									onChange={(e) =>
										setFormValues({ ...formValues, title: e.target.value })
									}
									id='recipient-name'
								/>
							</div>
							<div className='form-group'>
								<label htmlFor='message-text' className='col-form-label'>
									Description:
								</label>
								<textarea
									className='form-control'
									value={formValues?.description}
									onChange={(e) =>
										setFormValues({
											...formValues,
											description: e.target.value,
										})
									}
									style={{ height: '150px', borderRadius: '20px' }}
									id='message-text'
								></textarea>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='cmn-btn'>
							<button onClick={toggle}>Close</button>
						</div>
						<div className='cmn-btn'>
							<button onClick={getEdit}>Submit</button>
						</div>
					</ModalFooter>
				</Modal>
			</div>
			<section className='tasks-content data-table position-relative'>
				<div className='row'>
					<div className='col-md-12 col-12 tasks-heading d-flex flex-column gap-5'>
						<h4 className='task'> {cardDetail?.title}</h4>

						<div className='d-flex justify-content-between margin-left-40'>
							<div className='justify-content-between'>
								<p className='title mb-3'>Date</p>
								<p className='me-3'>
									{moment
										.utc(cardDetail?.created_at.toString())
										.format('MM/DD/YYYY')}
								</p>
							</div>
							<div className='status-transtion'>
								<p className='title mb-3'>Status </p>
								<div className='position-relative'>
									<p
										className={`status text-capitalize ${cardDetail?.status}`}
										onClick={() => {
											setIsOpen((prevState) => !prevState)
										}}
									>
										{cardDetail?.status}<span>{cardDetail?.user?.id !== auth?.userInfo?.user?.id ? <img src={dropdown} alt='img'/> : ''}</span>
									</p>

									{cardDetail?.user?.id !== auth?.userInfo?.user?.id && (
										<div
											className='status-dropdown'
											style={{
												opacity: isOpen ? 1 : 0,
												visibility: isOpen ? 'visible' : 'hidden',
											}}
										>
											<p
												className=' text-capitalize approved '
												id='approved'
												onClick={handleStatus}
											>
												Approved
											</p>
											<p
												className=' text-capitalize pending'
												id='pending'
												onClick={handleStatus}
											>
												Pending
											</p>
											<p
												className=' text-capitalize  rejected'
												id='rejected'
												onClick={handleStatus}
											>
												Rejected
											</p>
										</div>
									)}
								</div>
							</div>

							{cardDetail?.user?.id === auth?.userInfo?.user?.id && (
								<div>
									<p className='title mb-5'>Action</p>
									<div className='d-flex align-items-start justify-content-start gap-2 me-4'>
										<button
											className='border-0 bg-transparent'
											onClick={() => getDelete(message)}
										>
											<img
												src={process.env.PUBLIC_URL + '/images/delete-icon.png'}
												alt='images'
												style={{ cursor: 'pointer' }}
											/>
										</button>
										<button
											className='border-0 bg-transparent'
											onClick={() => editToggle()}
										>
											<img
												src={process.env.PUBLIC_URL + '/images/edit-icon.png'}
												alt='images'
												style={{ cursor: 'pointer' }}
											/>
										</button>
									</div>
								</div>
							)}
						</div>

						<div className='margin-left-40'>
							<p className='title mb-3'>Description</p>
							<p className='text-white mt-4'>{cardDetail?.description}</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default RequestDetailPage

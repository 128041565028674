/* eslint-disable react/jsx-no-target-blank */
import { instance } from 'index'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

const UserDocuments = ({ userDetail }) => {
	const [documentsData, setDocumentsData] = useState([])
	const [modalName, setModalName] = useState('Video')
	const [file, setFile] = useState({})
	const [formKey, setFormKey] = useState('')

	const [modal, setModal] = useState(false)
	const userID = userDetail?.user?.id;
	
	const toggle = (e) => {
		setModalName(e.target.name)
		setFormKey(e.target.id)
		setModal(!modal)
	}

	const handleSave = async () => {
		try {
			const result = await instance.patch(
				`user-docs/${documentsData[0]?.id}/`,
				{
					[formKey]: file,
				},
				{
					headers: {
						'content-type': 'multipart/form-data',
					},
				}
			)
			if (result?.status === 200) {
				getDocumentsData()
				setModal(!modal)
				toast.success('Data Upadted Sucessfully!')
			}
		} catch (error) {
			toast.error('Oops! Error Updating Data')
			console.log({ error })
		}
	}
	const getDocumentsData = async () => {
		try {
			const result = await instance.get('user-docs/')
			if (result?.status === 200) {
				const filteredResults = result?.data?.results?.filter((item) => {
					return item?.user?.id === userID
				})

				setDocumentsData(filteredResults)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	useEffect(() => {
		getDocumentsData()
	}, [])
	return (
		<div className='col-lg-7 col-md-12 right px-3'>
			<Modal isOpen={modal} toggle={toggle} className='modal-popups'>
				<ModalBody>
					<h3 className='text-center text-white'>Edit {modalName}</h3>
					<div className='modal-body'>
						<form>
							<div className='form-group'>
								<label htmlFor='file' className='col-form-label'>
									Upload File:
								</label>
								<input
									type='file'
									className='form-control'
									id='file'
									style={{ borderRadius: '20px' }}
									onChange={(e) => setFile(e.target.files[0])}
									multiple={false}
								/>
							</div>
						</form>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className='cmn-btn bg-transparent border-0'>
						<button onClick={toggle}>Close</button>
					</div>
					<div className='cmn-btn bg-transparent border-0'>
						<button onClick={handleSave}>Save</button>
					</div>
				</ModalFooter>
			</Modal>
			<div className='card' style={{ gap: '50px' }}>
				<div className='row ' style={{ alignItems: 'baseline' }}>
					<div className='col-md-6 col-sm-12'>
						<p className='document-text'> Video</p>
					</div>
					<div className='col-md-6 col-sm-12'>
						<div
							className='cmn-btn justify-content-end document-buttons'
							style={{ margin: '0px' }}
						>
							<a
								className='mb-0  '
								href={documentsData[0]?.video_docs}
								target='_blank'
							>
								View
							</a>
							<button
								className='mb-0  '
								id='video_docs'
								name='Video'
								onClick={toggle}
							>
								Edit
							</button>
						</div>
					</div>
				</div>
				<div className='row ' style={{ alignItems: 'baseline' }}>
					<div className='col-md-6 col-sm-12'>
						<p className='document-text'> Transcript</p>
					</div>
					<div className='col-md-6 col-sm-12'>
						<div
							className='cmn-btn justify-content-end document-buttons'
							style={{ margin: '0px' }}
						>
							<a
								className='mb-0  '
								id='degree'
								href={documentsData[0]?.degree}
								target='_blank'
							>
								View
							</a>
							<button
								className='mb-0  '
								id='degree'
								name='Degree'
								onClick={toggle}
							>
								Edit
							</button>
						</div>
					</div>
				</div>
				<div className='row ' style={{ alignItems: 'baseline' }}>
					<div className='col-md-6 col-sm-12'>
						<p className='document-text'> Experience</p>
					</div>
					<div className='col-md-6 col-sm-12'>
						<div
							className='cmn-btn justify-content-end document-buttons'
							style={{ margin: '0px' }}
						>
							<a
								className='mb-0  '
								id='experience_letter'
								href={documentsData[0]?.experience_letter}
								target='_blank'
							>
								View
							</a>
							<button
								className='mb-0  '
								id='experience_letter'
								name='Experience'
								onClick={toggle}
							>
								Edit
							</button>
						</div>
					</div>
				</div>

				<div className='row ' style={{ alignItems: 'baseline' }}>
					<div className='col-md-6 col-sm-12'>
						<p className='document-text'> Contract</p>
					</div>
					<div className='col-md-6 col-sm-12'>
						<div
							className='cmn-btn justify-content-end document-buttons'
							style={{ margin: '0px' }}
						>
							<a
								className='mb-0  '
								href={documentsData[0]?.contract}
								target='_blank'
							>
								View
							</a>
							<button
								className='mb-0  '
								id='contract'
								name='Contract'
								onClick={toggle}
							>
								Edit
							</button>
						</div>
					</div>
				</div>
				<div className='row ' style={{ alignItems: 'baseline' }}>
					<div className='col-md-6 col-sm-12'>
						<p className='document-text'> CNIC</p>
					</div>
					<div className='col-md-6 col-sm-12'>
						<div
							className='cmn-btn justify-content-end document-buttons'
							style={{ margin: '0px' }}
						>
							<a
								className='mb-0  '
								id='cnic_copy'
								href={documentsData[0]?.cnic_copy}
								target='_blank'
							>
								View
							</a>
							<button
								className='mb-0  '
								id='cnic_copy'
								name='CNIC'
								onClick={toggle}
							>
								Edit
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UserDocuments

/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import Modal from 'react-bootstrap/Modal';
import { instance } from 'index';
import { Fade, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import CountUp from 'react-countup';
import Lottie from 'lottie-react';
import Process from './animation/process.json';
import { useHistory } from 'react-router-dom';
// import Loader from './Loader'
import Loader2 from './Loader2';
const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '600px',
  backgroundRepeat: 'no-repeat',
  width: '100%',
};
function CartFilter() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [view, setView] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [getProjects, setGetProjects] = useState();
  const history = useHistory();
  const [projectid, setProjectId] = useState();
  const portfolioSlider = {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      992: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const getAllProject = async () => {
    try {
      const result = await instance.get('projects/');
      if (result?.status === 200) {
        setGetProjects(result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(true);
      console.log({ error });
    }
  };
  const projectDetail = (id) => {
    history.push({
      pathname: '/project-details',
      state: { message: id },
    });
    scrollTop();
  };
  useEffect(() => {
    getAllProject();
  }, []);
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          style={{ marginBottom: '20px' }}
          className='modal-popups '
        >
          <Modal.Body style={{ padding: '0px', overflow: 'hidden' }}>
            <div className='d-flex col-12 '>
              {view?.images.length > 1 ? (
                <>
                  <div className='img-container col-6'>
                    <div className='slide-container'>
                      <Fade>
                        {view?.images?.map((fadeImage, index) => (
                          <div key={index}>
                            <img
                              style={{ width: '90%', height: '500px' }}
                              src={fadeImage?.image}
                            />
                          </div>
                        ))}
                      </Fade>
                    </div>
                  </div>
                </>
              ) : (
                <div className='img-container col-6'>
                  <img
                    style={{ width: '90%', height: '500px' }}
                    src={view?.images[0]?.image}
                  />
                </div>
              )}
              <div className='data-container col-6'>
                <div className='model_header d-flex justify-content-end '>
                  <button
                    className='border-0 bg-transparent mb-3 pt-4 me-3'
                    onClick={handleClose}
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/images/close.png'}
                      alt='images'
                      style={{ cursor: 'pointer' }}
                      height='30px'
                      width='30px'
                    />
                  </button>
                </div>
                <h4 style={{ marginTop: '-20px' }}>{view?.name}</h4>
                <div className='description-div'>
                  <p>{view?.description}</p>
                </div>
                <div className='project-btn'>
                  <a href={view?.live_url}>
                    <p className='text-white pt-1'>Learn more</p>
                  </a>
                </div>
                <div className='Technologies'>
                  <h5>Technologies</h5>
                  <div className='d-flex flex-row  col-12'>
                    {view?.technologies?.map((item) => {
                      return (
                        <div className='d-flex flex-column col-3'>
                          <h6>{item?.name}</h6>
                          <img
                            src={item?.icon}
                            alt='images'
                            height='40px'
                            width='40px'
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <SRLWrapper>
        <div className='portfolio-area sec-mar-top position-relative'>
          {isLoading && <Loader2 />}
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-7 col-xl-5'>
                <div className='title '>
                  <span>Projects</span>
                  <h2>Doing more for your success</h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className='swiper portfolio-slider project-slider'
            style={{ padding: '60px 0 50px 0' }}
          >
            <Swiper
              {...portfolioSlider}
              pagination={{
                type: 'bullets',
                clickable: true,
              }}
              className='swiper-wrapper'
            >
              {getProjects?.results?.map((data, index) => {
                return (
                  <SwiperSlide className='swiper-slide' key={index}>
                    <div
                      id='carouselExampleCaptions'
                      className='carousel slide mt-5'
                      data-bs-ride='carousel'
                      data-bs-interval='false'
                    >
                      <div className='carousel-inner'>
                        <div className='carousel-item active'>
                          <div className='d-flex  flex-lg-column flex-md-column flex-sm-column flex-xs-column flex-column'>
                            <div className='card-body col-sm-5 col-md-12 col-lg-12 px-lg-5'>
                              <Slide arrows={true}>
                                {data?.images?.map((slideImage, index) => (
                                  <div
                                    key={index}
                                    className='slider-img--container'
                                    style={{
                                      ...divStyle,
                                    }}
                                  >
                                    <img
                                      src={slideImage?.image}
                                      alt={data?.name}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'fill',
                                      }}
                                    />
                                  </div>
                                ))}
                              </Slide>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12 '>
                              <div className='card-body px-lg-5'>
                                <h5 className='text-white'>{data?.name}</h5>
                                <p className='text-white'>
                                  {data?.description}
                                </p>
                                <div className='cmn-btn'>
                                  <a
                                    onClick={() => projectDetail(data?.id)}
                                    className='my-2'
                                  >
                                    Learn more
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className='swiper-pagination' />
            <div className='swiper-button-next project-swiper-next' />
            <div className='swiper-button-prev project-swiper-prev' />
          </div>
        </div>
      </SRLWrapper>
      <div className='single-step mt-5'>
        <div className='row'>
          <div className='col-md-4 col-xl-6'>
            <div className='step-img'>
              <img
                src={process.env.PUBLIC_URL + '/images/VR-glasess.png'}
                alt='images'
              />
            </div>
          </div>
          <div className='col-md-8 col-xl-6'>
            <div className='step mt-5'>
              <h2 className='text-white'>
                Expert of Your Software Programming
              </h2>
              <span className='text-danger'>
                The Best Tech Solutions for Our Customers.
              </span>
              <p className='mt-3 text-white'>
                Website development is a catch-all term for the work that goes
                into building a website. This includes everything from markup
                and coding to scripting, network configuration, and CMS
                development. While web development typically refers to web
                markup and coding, website development includes all related
                development tasks, such as client-side scripting, server-side
                scripting, server and network security configuration, eCommerce
                development, and content management system (CMS) development.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='single-step'>
        <div className='row'>
          <div className='col-md-8 col-xl-6 or2'>
            <div className='step'>
              <div className='title '>
                <span>Our Approach</span>
                <h2>
                  Speed up the <br />
                  Process
                </h2>
              </div>
              <p className='text-white'>
                We applied a skilled approach to speed up the development of new
                software to cover architectural enhancements and new
                capabilities and develop a solution within the timeline decided.
              </p>
            </div>
          </div>
          <div className='col-md-4 col-xl-6 or1'>
            <div className='step-img'>
              <Lottie loop={true} animationData={Process} />
            </div>
          </div>
        </div>
      </div>
      <div className='features-count' style={{ marginTop: '50px' }}>
        <div className='row'>
          <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3'>
            <div className='single-count'>
              <i>
                <img
                  src={process.env.PUBLIC_URL + '/images/icons/count-1.png'}
                  alt='images'
                />
              </i>
              <div className='counter'>
                <CountUp end={250} delay={2} duration={5} /> <sup>+</sup>
              </div>
              <p>Project Completed</p>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3'>
            <div className='single-count'>
              <i>
                <img
                  src={process.env.PUBLIC_URL + '/images/icons/count-2.png'}
                  alt='images'
                />
              </i>
              <div className='counter'>
                <CountUp end={150} delay={1} duration={5} /> <sup>+</sup>
              </div>
              <p>Satisfied Clients</p>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3'>
            <div className='single-count'>
              <i>
                <img
                  src={process.env.PUBLIC_URL + '/images/icons/count-3.png'}
                  alt='images'
                />
              </i>
              <div className='counter'>
                <CountUp end={150} delay={3} duration={5} /> <sup>+</sup>
              </div>
              <p>Expert Teams</p>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3'>
            <div className='single-count xsm'>
              <i>
                <img
                  src={process.env.PUBLIC_URL + '/images/icons/count-4.png'}
                  alt='images'
                />
              </i>
              <div className='counter'>
                <CountUp end={100} delay={4} duration={5} /> <sup>+</sup>
              </div>
              <p>Win Awards</p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}
export default CartFilter;

import React, { useState } from 'react'
import { useEffect } from 'react'

const ScrollButton = () => {
	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 800) {
			setVisible(true)
		} else if (scrolled <= 100) {
			setVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	useEffect(() => {
		setVisible(true)
		window.addEventListener('scroll', toggleVisible)
		return () => {
			window.removeEventListener('scroll', toggleVisible)
		}
	}, [])

	return (
		<i
			className='bi bi-arrow-up'
			onClick={scrollToTop}
			style={{ display: visible ? 'inline' : 'none' }}
		>
			<span>top</span>
		</i>
	)
}

export default ScrollButton

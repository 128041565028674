import Loader from 'components/common/Loader'
import Table from 'components/common/table/Table'
import { timeTable } from 'contants/data'
import { instance } from 'index'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export default function MyTime() {
	const [tableList, setTableList] = useState(timeTable)
	const [time, setTime] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)

	const getAttendance = async (userId) => {
		setIsLoading(true)

		try {
			const result = await instance.get('employee-attendance/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)

				const UserAttendanceData = result?.data.results.filter(
					(item) => item.user.id === userId
				)
				setTime(UserAttendanceData)
			}
		} catch (error) {
			setIsLoading(true)
			console.log({ error })
		}
	}

	useEffect(() => {
		if (auth?.userInfo?.user?.id) {
			getAttendance(auth?.userInfo?.user?.id)
		}
	}, [])

	const columns = useMemo(
		() => [
			{
				Header: 'Weekly Hours',
				accessor: 'week',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Over Time',
				accessor: 'overtime_hours',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
			},
			{
				Header: 'Working hours',
				accessor: 'number_of_hours',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className='dotDiv d-flex align-items-center justify-content-center w-100'
						>
							<span className='span-badge'></span>
							{cellProps.row.original.number_of_hours}
						</div>
					)
				},
			},
			{
				Header: 'Date',
				accessor: 'created_at',
				filterable: false,
				width: 250,
				Cell: (cell) =>
					cell?.value && cell?.value !== null && cell?.value !== ''
						? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
						: '',
			},
			{
				Header: 'Status',
				accessor: 'status',
				filterable: false,
				width: 250,
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
					return (
						<div
							key={cellProps.row.original?.id}
							className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
						>
							{cellProps.row.original.status}
						</div>
					)
				},
			},
		],
		[]
	)

	return (
		<>
			{isLoading && <Loader />}
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white tableHeading'>My Time Dashbord</h6>
				{/* <div className='cmn-btn'>
					<button className='mb-0 mt-1' onClick={handleClick}>
						{buttonText}
					</button>
				</div> */}
			</div>

			<div className='row'>
				<div className='col-12'>
					{time && (
						<Table
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={time}
							total={time?.length}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) => row?.id}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={() => console.log('Next')}
							prevHandler={() => console.log('Prev')}
						/>
					)}
				</div>
			</div>
		</>
	)
}

import React from 'react'
import Header from './Header'
import Footer from './Footer'
import LetsTalkArea from './LetsTalkArea'
import Breadcrumb from './Breadcrumb'
const TermsandCondition = () => {
  return (
    <>
    {/* <Header/> */}
    <Breadcrumb pageName="Terms and Conditions"/>
    <div className="container">
        <div className="col-md-12 col-lg-12 col-xl-12">
            <div className='title mt-4'>
            <h1>Welcome to Bugdev!</h1>
            </div>
            <p className='text-white'>These terms and conditions outline the rules and regulations 
            for the use of Bugdev Website, located at<span>
            <a href="https://www.bugdev.co.uk/">{''} https://www.bugdev.co.uk/</a></span>
            . By accessing this website, we assume you accept these terms and conditions. Do not 
            continue to use Bugdev Website if you do not agree to take all of the terms and 
            conditions stated on this page. The following terminology applies to these Terms and 
            Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, 
            “You” and “Your” refers to you, the person logged on this website and compliant to 
            the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, 
            refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and 
            ourselves. All terms refer to the offer, acceptance and consideration of payment 
            necessary to undertake the process of our assistance to the Client in the most 
            appropriate manner for the express purpose of meeting the Client’s needs in respect 
            of the provision of the Company’s stated services.Any use of the above terminology 
            or other words in the singular, plural, capitalization and/or he/she or they, are 
            taken as interchangeable and therefore as referring to same.
            </p>

            <div className='title mt-4'>
            <h1>Cookies</h1>
            </div>
            <p className='text-white'>We employ the use of cookies. By accessing Bugdev Website, 
            you agreed to use cookies in agreement with the bugdev <span><a href='/privacy-policy'>Privacy Policy. {" "}</a></span>
            Most interactive websites use cookies to let us retrieve the user’s details for each visit. 
            Cookies are used by our website to enable the functionality of certain areas to make it 
            easier for people visiting our website. Some of our affiliate partners may also use cookies.
            </p>

            <div className='title mt-4'>
            <h1>License</h1>
            </div>
            <p className='text-white'>Unless otherwise stated, Bugdev and/or its licensors own 
            the intellectual property rights for all material on Bugdev Website. All intellectual 
            property rights are reserved. You may access this from Bugdev Website for own personal 
            use subjected to restrictions set in these terms and conditions.:</p>
            <ul className='text-white'>
                <li >Republish material from Bugdev Website</li>
                <li>Sell, rent or sub-license material from Bugdev Website</li>
                <li>Reproduce, duplicate or copy material from Bugdev Website</li>
                <li>Redistribute content from Bugdev Website</li>
                <li>This Agreement shall begin on the date hereof.</li>
            </ul>
            <p className='text-white mt-3'>Parts of this website offer an opportunity for users to 
            post and exchange opinions and information in certain areas of the website. Bugdev 
            reserves the right to filter, publish or review Comments before their presence on 
            the website. Comments do not reflect the views and opinions of Bugdev, its agents 
            and/or affiliates. Comments reflect the views and opinions of the person who post 
            their views and opinions. To the extent permitted by applicable laws, Bugdev shall 
            not be liable for the Comments or any liability, damages or expenses caused and/or 
            suffered as a result of any use of and/or posting of and/or appearance of the Comments 
            on this website.</p>

            <div className='title mt-4'>
            <h1>Hyperlinking To Our Content</h1>
            </div>
            <p className='text-white'>The following organizations may link to our Website without 
            prior written approval:</p>
            <span className='text-white'>
                <ul>
                    <li>Government agencies.</li>
                    <li>Search engines.</li>
                    <li>News organizations.</li>
                </ul>
            </span>
            <p className='text-white mb-5'>
            Online directory distributors may link to our Website in the same manner as they 
            hyperlink to the Websites of other listed businesses. And system-wide accredited 
            businesses except soliciting non-profit organizations, charity shopping malls, 
            and charity fundraising groups which may not hyperlink to our Website.<br/>
            These organizations may link to our home page, to publications or other Website 
            information so long as the link:<br/>
            (a) is not in any way deceptive;<br/>
            (b) does not falsely imply sponsorship, endorsement or approval of the linking 
             party and its products and/or services; <br/>
             (c) fits within the context of the linking party’s site.
            </p>

            <div className='title mt-4'>
            <h1>iFrames</h1>
            </div>
            <p className='text-white'>
            Without prior approval and written permission, you may not create frames 
            around our Webpages that alter in any way the visual presentation or 
            appearance of our Website.
            </p>

            <div className='title mt-4'>
            <h1>Content Liability</h1>
            </div>
            <p className='text-white '>
            We shall not be held responsible for any content that appears on your Website. 
            You agree to protect and defend us against all claims that are rising on your 
            Website. No link(s) should appear on any Website that may be interpreted as 
            libellous, obscene or criminal, or which infringes, otherwise violates, or 
            advocates the infringement or other violation of, any third-party rights.
            </p>

            <div className='title mt-4'>
            <h1>Reservation Of Rights</h1>
            </div>
            <p className='text-white '>
            We reserve the right to request that you remove all links or any particular 
            link to our Website. You approve to immediately remove all links to our 
            Website upon request. We also reserve the right to amend these terms and 
            conditions and it’s linking policy at any time. By continuously linking 
            to our Website, you agree to be bound to and follow these linking terms 
            and conditions.
            </p>

            <div className='title mt-4'>
            <h1>Removal Of Links From Our Website</h1>
            </div>
            <p className='text-white '>
            If you find any link on our Website that is offensive for any reason, 
            you are free to contact and inform us any moment. We will consider 
            requests to remove links but we are not obligated to or so or to 
            respond to you directly.  <br/>
            We do not ensure that the information on this website is correct, 
            we do not warrant its completeness or accuracy; nor do we promise 
            to ensure that the website remains available or that the material 
            on the website is kept up to date.
            </p>

            <div className='title mt-4'>
            <h1>Disclaimer</h1>
            </div>
            <p className='text-white mb-5'>
            To the maximum extent permitted by applicable law, we exclude all 
            representations, warranties and conditions relating to our website 
            and the use of this website. Nothing in this disclaimer will:</p>
            <span className='text-white'>
                <ul>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ul>
            </span>
            <p className='text-white mb-5'>
            The limitations and prohibitions of liability set in this Section and elsewhere 
            in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern 
            all liabilities arising under the disclaimer, including liabilities arising in 
            contract, in tort, and for breach of statutory duty.
            </p>
        </div>
        </div>
    <LetsTalkArea/>
    <Footer/>
    </>
  )
}
export default TermsandCondition
export const icons = {
  userImg: require('../assets/images/user-img.svg'),
  portrait: require('../assets/images/Portrait.svg'),
  lock: require('../assets/images/Lock.svg'),
  registration: require('../assets/images/Registration.svg'),
  logout: require('../assets/images/Logout.svg'),
  forward: require('../assets/images/Forward.svg'),
  clip: require('../assets/images/attach-clip.png'),
  paid: require('../assets/images/paid_leave.svg'),
  medical: require('../assets/images/medical.svg'),
  gym: require('../assets/images/gym.svg'),
  advance: require('../assets/images/advance.svg'),
  annual_increament: require('../assets/images/annual_increament.svg'),
  flexible_time: require('../assets/images/flexible_time.svg'),
  filter: require('../assets/images/filter.svg'),
  email_icon: require('../assets/images/Envelope.svg'),
  clock: require('../assets/images/Time Machine.svg'),
  game_event: require('../assets/images/gamming.png'),
  labour_day:require('../assets/images/labour-day.png'),
  pakistan_day:require('../assets/images/pakistan.png'),
  flexible_timing:require('../assets/images/flexible-timing.png'),
  bg_image:require('../assets/images/bg-image.png'),
  detial:require('../assets/images/Details.png'),
  calander:require('../assets/images/Calendar.png'),
  time:require('../assets/images/Time Machine.png'),
  anual_increment:require('../assets/images/annual-increment.png'),
  medical_coverage:require('../assets/images/medical-coverage.svg'),
  paid_leaves:require('../assets/images/paid-leaves_pic.svg'),
  advance_salary:require('../assets/images/advance-salary.svg'),
  delete_icon:require('../assets/images/delete-icon.png'),
  edit_icon:require('../assets/images/edit-icon.png'),
  star:require('../assets/images/Star_Filled.png')
  
};

import React, {useState, useEffect} from "react";
import { instance } from 'index';
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

function OurPartnerArea() {
	const [isLoading, setIsLoading] = useState(false)
	const [getProjects, setGetProjects] = useState()
	const [isRequestSent, setIsRequestSent] = useState(false)

	const [formValues, setFormValues] = useState({
		email: '',
		phone_number: '',
		query: '',
	})

	const getAllProject = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get('projects/')
			if (result?.status === 200) {
				setIsLoading(false)
				setGetProjects(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	


	const sendQuerry = async (e) => {
		e.preventDefault()
		setIsRequestSent(true)
		try {
			const result = await instance.post('query/', formValues)
			console.log(result)

			if (result?.status === 201) {
				console.log('Application sent Successful')

				toast.success('Application sent sucessfully!')

				setIsRequestSent(false)
			}
			setFormValues({
				...formValues,
				email: '',
				phone_number: '',
				query: '',
			})
		} catch (error) {
			setIsRequestSent(false)
			toast.error('Error While Submitting Your Request. Try Again')
			console.log({ error })
		}
	}

	useEffect(() => {
		getAllProject()
	}, [])


	return (
		<>
			<section className='our-partner padding-20'>
				<div className='container-fluid g-0 overflow-hidden'>
					<div className='row align-items-center g-0'>
						<div className='col-12 col-xl-6'>
							<div className='newsletter'>
								<div className='subscribes'>
									<span style={{color:'white'}}>Get In Touch</span>
									<h1>Submit your</h1>
									<h2>Query</h2>
									<div className='subscribe-form'>
										<form
											onSubmit={sendQuerry}
											style={{ display: 'flex', flexDirection: 'column' }}
										>
											<input
												type='email'
												name='email'
												placeholder='Enter your email '
												value={formValues?.email}
												required
												onChange={(e)=> setFormValues({...formValues, email:e.target.value})}
											/>

											<PhoneInput
											type='text'
											value={formValues?.phone_number}
											name='phone_number'
											placeholder='Enter your phone'
											required
											onChange={(value) => setFormValues({ ...formValues, phone_number: value })}
											style={{ display: 'flex', background: 'white', borderRadius: '20px', padding: '0px 10px' }}
											/>

											<textarea
											name='query'
											cols='20'
											rows='10'
											placeholder='Enter your query'
											value={formValues?.query}
											required
											style={{
												borderRadius: '20px',
												margin: '10px 0',
												padding: '20px',
												height: '130px',
											}}
											onChange={(e) => setFormValues({ ...formValues, query: e.target.value })}
											/>
											<div className='cmn-btn justify-content-start'>
												<button
													type='submit'
													className='my-2'
													disabled={isRequestSent}
												>
													{isRequestSent ? 'Submitting' : 'Submit Request'}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className='col-12 col-xl-6'>
							<div className='our-clients'>
								<div className='col-md-6 col-lg-8 col-xl-12'>
									<div className='title'>
										<span>Our partner</span>
										<h3>Join our Bugdev community.</h3>
									</div>
								</div>
								<div className='row align-items-center'>
									{getProjects?.results
										?.filter((data) => data?.live_url !== '')
										?.map((data) => {
											return (
												<div
													className='col-sm-6 col-md-6 col-lg-4 col-xl-3'
													key={data?.id}
												>
													<div className='single-client'>
														<img src={data?.icon} alt='images' />
														<div className='client-hover'>
															<a href={data?.live_url} target='_blank'>
																<span>{data?.live_url}</span>
															</a>
														</div>
													</div>
												</div>
											)
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default OurPartnerArea;

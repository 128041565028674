import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import LetsTalkArea from '../../common/LetsTalkArea';
import TestimonialArea from '../../common/TestimonialArea';
import WhyChooseUsArea from '../../common/WhyChooseUsArea';
import AboutArea from '../Home/AboutArea';
import InsideStoryArea from './InsideStoryArea';
import LatesNewsArea from './LatesNewsArea';
import Footer from 'components/common/Footer';

function AboutPage() {
  return (
    <>
      <Breadcrumb pageName='about' />
      <InsideStoryArea />
      <AboutArea />
      {/* <TestimonialArea /> */}
      <WhyChooseUsArea black='black' lable='' />
      {/* <LatesNewsArea /> */}
      <LetsTalkArea />
      <Footer/>
    </>
  );
}

export default AboutPage;

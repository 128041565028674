import React, { useMemo, useState, useEffect } from 'react';
import ProjectTable from './components/ProjectTable';
import moment from 'moment';
import { instance } from 'index';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from 'components/common/Loader';

export default function Projects() {
  const { auth } = useSelector((state) => state);
  const {access} = useSelector((state)=> state.auth.userInfo)
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [itemproject, setItemProject] = useState();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: 'Project',
        accessor: 'name',
        filterable: false,
        width: 300,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
      },
      {
				Header: 'Live URL',
				accessor: 'live_url',
				filterable: false,
				width: 350,
				cellStyle: {
				  overflow: 'hidden',
				  textOverflow: 'ellipsis',
				  wordWrap: 'break-word',
				},
				Cell: (cellProps) => {
				  
				  return (
					<div className='d-flex align-items-center justify-content-center'>
					  <a href={cellProps?.cell?.row?.original?.live_url} className='text-white'>
						Open in Browser
						</a>
					  
					</div>
				  );
				},
			  },
      {
        Header: 'Due Date',
        accessor: 'deadline',
        filterable: false,
        width: 300,
        Cell: (cell) =>
          cell?.value && cell?.value !== null && cell?.value !== ''
            ? moment.utc(cell?.value?.toString()).format('MM/DD/YYYY')
            : '',
      },
      {
        Header: 'Status',
        accessor: 'status', 
        filterable: false,
        width: 400,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        },
        Cell: (cell) => {
          return (
              <div
                className={`dotDiv d-flex justify-content-center align-items-center w-100 text-white`}
              >
                <span className={cell?.value?.toLowerCase()} />
                {cell?.value}
              </div>
          );
        },
      },
      
    ],
    []
  );

  const getAllProjects = async (userId) => {
    setIsLoading(true);
    try {
      const result = await instance.get(`projects/?user=${userId}`,{
        headers: {
					'Authorization': `Bearer ${access}`,
				  },
      });
      if (result?.status === 200) {
        setIsLoading(false);
        setProject(result?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const getProjectItem = async (id) => {
    try {
      const result = await instance.get(`projects/${id}/`);
      if (result?.status === 200) {
        setIsLoading(false);
        setItemProject(result?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }

    history.push({
      state: { message: id },
    });
  };
  useEffect(() => {
    if (auth?.userInfo?.user?.id) getAllProjects(auth?.userInfo?.user?.id);
  }, []);

  return (
		<>
                {isLoading && <Loader />}
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-3'>
				<h6 className='text-white PaytableHeading'>Projects Dashboard</h6>
			</div>
			<div className='row'>
				<div className='col-12'>
					{project && (
						<ProjectTable
							tableStyles={{ tableLayout: 'fixed' }}
							columns={columns}
							initialState={{
								columnVisibility: { conversion: false },
							}}
							data={project?.results}
							total={project?.length}
							loading={isLoading}
							customPageSize={1}
							divclassName='table-responsive mb-1'
							tableclassName='align-middle table-nowrap'
							theadclassName='table-light text-muted'
							getRowId={(row, relativeIndex, parent) =>
								console.log('ROw id.....', row?.id)
							}
							fetchData={() => console.log('fetchData')}
							pageCount={3}
							currentPage={1}
							isNextPage={2}
							isPrevPage={null}
							nextHandler={
								() => console.log('Next')
							}
							prevHandler={
								() => console.log('Prev')
							}
						/>
					)}
				</div>
			</div>
		</>
	)
}

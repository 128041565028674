import React from 'react'

const Information = ({
	userDetail,
	deptName,
	handleInputChange,
	setUserDetail,
	handleUpdate
}) => {

	return (
		<div className='col-lg-7 col-md-12 right px-3'>
			<div className='card'>
				<div className='row'>
					<div className='col-md-6 col-sm-12'>
						<label id='first-name' className='form-label'>
							First Name
						</label>
						<input
							className='view_profile profile-input'
							type='text'
							value={userDetail?.user?.first_name}
							name='first_name'
							onChange={(e) =>
								setUserDetail((prev) => ({
									...prev,
									user: {
										...prev.user,
										first_name: e.target.value,
									},
								}))
							}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='last-name' className='form-label'>
							Email
						</label>
						<input
							className='view_profile profile-input'
							type={'email'}
							value={userDetail?.user?.email}
							name='email'
							onChange={(e) =>
								setUserDetail((prev) => ({
									...prev,
									user: {
										...prev.user,
										email: e.target.value,
									},
								}))
							}
						/>
					</div>

					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							Gender
						</label>
						<select
							className='view_profile profile-input bg-input'
							name='gender'
							value={userDetail?.gender}
							onChange={handleInputChange}
						>
							<option value=''>Select gender</option>
							<option value='MALE'>Male</option>
							<option value='FEMALE'>Female</option>
							<option value='OTHER'>Other</option>
						</select>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='email' className='form-label'>
							Age
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.age}
							name='age'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='email' className='form-label'>
							Department
						</label>
						{console.log('depart', userDetail?.user?.department?.name)}
						<input
							className='view_profile profile-input'
							type={'text'}
							// value={userDetail?.user?.department?.name}
							value={deptName}
							name='depart_name'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='birthday' className='form-label'>
							Hire Date
						</label>
						<input
							className='view_profile profile-input'
							type={'date'}
							value={userDetail?.joining_date}
							name='joining_date'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='phone' className='form-label'>
							Position
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.intro_text}
							name='intro_text'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							Employement Status
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.employee_status}
							name='employee_status'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							Phone
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.phone_no}
							name='phone_no'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							DOB
						</label>
						<input
							className='view_profile profile-input'
							type={'date'}
							value={userDetail?.date_of_birth}
							name='date_of_birth'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							Address
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.full_address}
							name='full_address'
							onChange={handleInputChange}
						/>
					</div>
					<div className='col-md-6 col-sm-12'>
						<label id='gender' className='form-label'>
							Experience
						</label>
						<input
							className='view_profile profile-input'
							type={'text'}
							value={userDetail?.experience}
							name='experience'
							onChange={handleInputChange}
						/>
					</div>
				</div>

				<div className='cmn-btn'>
					<button onClick={handleUpdate}>Update</button>
				</div>
			</div>
		</div>
	)
}

export default Information

import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { Modal } from 'react-bootstrap';

function WhyChooseUsArea(props) {
  const [isOpen, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="why-choose-us sec-mar padding-20">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className={`title ${props.black}`}>
                <span>Why Choose Bugdev</span>
                <h2 className="mb-15 text-white">
                  success is just around the next online corner
                </h2>
              </div>
              <div className="video-demo">
                <img
                  src={process.env.PUBLIC_URL + "/images/play-video.jpg"}
                  alt="images"
                />
                <div className="play-btn">
                  <div onClick={ ()=>setOpen(true)} className="popup-video">
                    <i className="fas fa-play" /> Play now
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 ">
              <div className={`valuable-skills bg-transparent ${props.light}`}>
                <img
                  src={process.env.PUBLIC_URL + "/images/valuable-skill.jpg"}
                  alt="images"
                />
                <div className="signle-bar pt-0">
                  <h6>Web Development</h6>
                  <ProgressBar
                    bgColor="#D90A2C"
                    baseBgColor="#d90a2c80"
                    height="5px"
                    completed={95}
                    labelAlignment="outside"
                    labelColor="#fff"
                    animateOnRender={true}
                    labelClassName={`${props.lable}`}
                  />
                </div>
                <div className="signle-bar">
                  <h6>App Development</h6>
                  <ProgressBar
                    bgColor="#D90A2C"
                    baseBgColor="#d90a2c80"
                    height="5px"
                    completed={98}
                    labelAlignment="outside"
                    labelColor="#fff"
                    animateOnRender={true}
                    labelClassName={`${props.lable}`}
                  />
                </div>
                <div className="signle-bar">
                  <h6>Backend</h6>
                  <ProgressBar
                    bgColor="#D90A2C"
                    baseBgColor="#d90a2c80"
                    height="5px"
                    completed={98}
                    labelAlignment="outside"
                    labelColor="#fff"
                    animateOnRender={true}
                    labelClassName={`${props.lable}`}
                  />
                </div>
                <div className="signle-bar">
                  <h6>Ui/Ux Design</h6>
                  <ProgressBar
                    // className="barsss"
                    bgColor="#D90A2C"
                    baseBgColor="#d90a2c80"
                    height="5px"
                    completed={96}
                    labelClassName={`${props.lable}`}
                    labelAlignment="outside"
                    labelColor="#fff"
                    animateOnRender={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <React.Fragment>
        <ModalVideo
          channel='youtube'
          isOpen={isOpen}
          videoId="jpHj3N5Mi_w"
          onClose={() => setOpen(false)}
        />
        
        {/* <Modal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
  <video controls>
    <source src={process.env.PUBLIC_URL + "/images/Bugdev_Video.mp4"} type="video/mp4" />
  </video>
  <button onClick={() => setOpen(false)}>Close</button>
</Modal> */}

{/* <Modal
 show={show} 
 onHide={handleClose}
 size="lg"
 aria-labelledby="contained-modal-title-vcenter"
 centered
 >
        <Modal.Header closeButton>
          <Modal.Title>Video Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" height="auto" controls>
            <source src={process.env.PUBLIC_URL + "/images/Bugdev_Video.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
      </Modal> */}
      </React.Fragment>
    </>
  );
}

export default WhyChooseUsArea;

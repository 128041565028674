import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
export default function AddUser() {
	const history = useHistory()
	const [step, setStep] = useState(1);
	const [formValues, setFormValues] = useState({
		
	})

	const handleNext = () => {
		setStep(step + 1);
	  };
	
	  const handleBack = () => {
		setStep(step - 1);
	  };

	const RegisterUser = async ()=>{
		try{

		} catch (error){
			console.log({error})
		}
	}
	return (
		<>
			<div className='myTime d-flex justify-content-between mb-2 align-items-center mb-5'>
				<h6 className='text-white tableHeading'>User Dashbord</h6>
			</div>

			<div className='row'>
				<div className='col-12'>
					{
						step === 1 && (
						<>
						<form onSubmit={handleNext}>
						<div className='card bg-dark table-card overflow-auto'>
						<h6 className='text-white tableHeading d-flex justify-content-center'>
							User Personal Information
						</h6>
						<div className='row d-flex justify-content-around'>
							<div className='col-md-5 col-sm-12 '>
								<label id='name' className='form-label text-white ms-4'>
									Name
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='email' className='form-label text-white ms-4'>
									Email
								</label>
								<input className='form-control bd-input' type={'email'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='department' className='form-label text-white ms-4'>
									Department
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='hireDate' className='form-label text-white ms-4'>
									Hire Date
								</label>
								<input className='form-control bd-input' type={'date'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='position' className='form-label text-white ms-4'>
									Position
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='status' className='form-label text-white ms-4'>
									Employment Status
								</label>
								<input className='form-control bd-input' type={'text'} required />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='phone' className='form-label text-white ms-4'>
									Phone
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='dob' className='form-label text-white ms-4'>
									DOB
								</label>
								<input className='form-control bd-input' type={'date'} required />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='address' className='form-label text-white ms-4'>
									Address
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='experience' className='form-label text-white ms-4'>
									Experience
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
						</div>
						<div className='d-flex justify-content-end gap-3'>
							<div className='cmn-btn'>
								<button className=''>
									Next
								</button>
							</div>
						</div>
					    </div>
						</form>
						</>
						)
					}
					{
						step === 2 && (
							<>
						<form onSubmit={RegisterUser}>
						<div className='card bg-dark table-card overflow-auto'>
						<h6 className='text-white tableHeading d-flex justify-content-center'>
							User Bank Detail
						</h6>
						<div className='row d-flex justify-content-around'>
							<div className='col-md-5 col-sm-12 '>
								<label id='name' className='form-label text-white ms-4'>
								Account Title
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='email' className='form-label text-white ms-4'>
								Bank Name
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='department' className='form-label text-white ms-4'>
								IBAN
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='hireDate' className='form-label text-white ms-4'>
								City
								</label>
								<input className='form-control bd-input' type={'text'} required/>
							</div>
							{/* <div className='col-md-5 col-sm-12'>
								<label id='position' className='form-label text-white ms-4'>
									Position
								</label>
								<input className='form-control bd-input' type={'text'} />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='status' className='form-label text-white ms-4'>
									Employment Status
								</label>
								<input className='form-control bd-input' type={'text'} />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='phone' className='form-label text-white ms-4'>
									Phone
								</label>
								<input className='form-control bd-input' type={'text'} />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='dob' className='form-label text-white ms-4'>
									DOB
								</label>
								<input className='form-control bd-input' type={'date'} />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='address' className='form-label text-white ms-4'>
									Address
								</label>
								<input className='form-control bd-input' type={'text'} />
							</div>
							<div className='col-md-5 col-sm-12'>
								<label id='experience' className='form-label text-white ms-4'>
									Experience
								</label>
								<input className='form-control bd-input' type={'text'} />
							</div> */}
						</div>
						<div className='d-flex justify-content-end gap-3'>
							<div className='cmn-btn'>
								<button className='' onClick={handleBack}>Back</button>
							</div>
							<div className='cmn-btn'>
								<button className=''  onClick={RegisterUser}>
									Register
								</button>
							</div>
						</div>
					    </div>
						</form>
							</>
						)
					}
					
				</div>
			</div>
		</>
	)
}

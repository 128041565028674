import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { instance } from 'index'
import { useHistory } from 'react-router-dom'
import Loader from 'components/common/Loader'
import Loader2 from 'components/common/Loader2'

function WhatWeDoArea() {
	const [service, setService] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const history = useHistory()

	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	const getService = async () => {
		try {
			const result = await instance.get('services/')

			if (result?.status === 200) {
        setService(result?.data)
				setIsLoading(false)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getServiceDetail = (id) => {
		history.push({
			pathname: '/service-details',
			state: { message: id },
		})
		scrollTop()
	}

	useEffect(() => {
		getService()
	}, [])
	return (
		<>
			{isLoading && <Loader />}
			<section className='service-area sec-pad padding-20'>
				<div className='container'>
					<div className='col-md-12 col-lg-12 col-xl-12'>
						<div className='title'>
							<span>what we do</span>
							<h2>we work performed for client happy. </h2>
							{/* <div className="cmn-btn">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/service`}
                  >
                    view all services
                  </Link>
                </div> */}
						</div>
					</div>
					<div className='row g-4'>
						<div className='col-md-12 col-lg-12 col-xl-12'>
							<div className='row g-4'>
								{service?.results.map((item, index) => {
									return (
										<div
											className='col-sm-6 col-md-6 col-lg-4 col-xl-4'
											key={index}
										>

                      {isLoading && <Loader />}
											<a onClick={() => getServiceDetail(item?.id)}>
												<div className='single-service'>
													<span className='count'>{index + 1}</span>
													<div className='service-icon'>
														<i>
															<img
																src={
																	process.env.PUBLIC_URL +
																	'/images/icons/service-icon-1.png'
																}
																alt='images'
															/>
														</i>
													</div>
													<div className='service-content'>
														<h4>{item?.name}</h4>
														<p>
															{item?.description.slice(0, 70)}{' '}
															{item?.description.length > 70 ? '...' : ''}
														</p>
														<b
															className='read-btnn'
															onClick={() => getServiceDetail(item?.id)}
														>
															read more
															<i>
																<img
																	src={
																		process.env.PUBLIC_URL +
																		'/images/icons/arrow-circle.png'
																	}
																	alt='images'
																/>
															</i>
														</b>
													</div>
												</div>
											</a>
										</div>
									)
								})}
								{/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
                  <div className="single-service">
                    <span className="count">02</span>
                    <div className="service-icon">
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/service-icon-2.png"
                          }
                          alt="images"
                        />
                      </i>
                    </div>
                    <div className="service-content">
                      <h4>App development</h4>
                      <p>
                      Consultation on updating existing mobile applications..
                      </p>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
                        read more
                        <i>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/arrow-circle.png"
                            }
                            alt="images"
                          />
                        </i>
                      </Link>
                    </div>
                  </div>
                  </Link>
                </div> */}
								{/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
                  <div className="single-service">
                    <span className="count">03</span>
                    <div className="service-icon">
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/service-icon-3.png"
                          }
                          alt="images"
                        />
                      </i>
                    </div>
                    <div className="service-content">
                      <h4>Software Development</h4>
                      <p>
                      Our Software Architects will ensure that the right...
                      </p>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
                        read more
                        <i>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/arrow-circle.png"
                            }
                            alt="images"
                          />
                        </i>
                      </Link>
                    </div>
                  </div>
                  </Link>

                </div> */}
								{/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
                  <div className="single-service">
                    <span className="count">04</span>
                    <div className="service-icon">
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/service-icon-4.png"
                          }
                          alt="images"
                        />
                      </i>
                    </div>
                    <div className="service-content">
                      <h4>Graphic design</h4>
                      <p>
                      Graphic design is the creation of visual compositions to solve...
                      </p>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
                        read more
                        <i>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/arrow-circle.png"
                            }
                            alt="images"
                          />
                        </i>
                      </Link>
                    </div>
                  </div>
                  </Link>
                </div> */}
							</div>
						</div>
						{/* <div className="col-md-6 col-lg-4 col-xl-4">
            <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
              <div className="single-service">
                <span className="count">05</span>
                <div className="service-icon">
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/service-icon-5.png"
                      }
                      alt="images"
                    />
                  </i>
                </div>
                <div className="service-content">
                  <h4>Video Animation</h4>
                  <p>
                  Animation is a method by which still figures are manipulated to...
                  </p>
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/service-details`}
                  >
                    read more
                    <i>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/arrow-circle.png"
                        }
                        alt="images"
                      />
                    </i>
                  </Link>
                </div>
              </div>
              </Link>
            </div> */}
						{/* <div className="col-md-6 col-lg-4 col-xl-4">
            <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
              <div className="single-service">
                <span className="count">06</span>
                <div className="service-icon">
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/service-icon-6.png"
                      }
                      alt="images"
                    />
                  </i>
                </div>
                <div className="service-content">
                  <h4>3D Design</h4>
                  <p>
                  3D design is the computer-modeling software to create...
                  </p>
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/service-details`}
                  >
                    read more
                    <i>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/arrow-circle.png"
                        }
                        alt="images"
                      />
                    </i>
                  </Link>
                </div>
              </div>
              </Link>
            </div> */}
						{/* <div className="col-md-6 col-lg-4 col-xl-4">
            <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service-details`}
                      >
              <div className="single-service">
                <span className="count">07</span>
                <div className="service-icon">
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/service-icon-7.png"
                      }
                      alt="images"
                    />
                  </i>
                </div>
                <div className="service-content">
                  <h4>UI/UX Design</h4>
                  <p>
                  We use most Popular tools for the best designs we can..
                  </p>
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/service-details`}
                  >
                    read more
                    <i>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/arrow-circle.png"
                        }
                        alt="images"
                      />
                    </i>
                  </Link>
                </div>
              </div>
              </Link>
            </div> */}
					</div>
				</div>
			</section>
		</>
	)
}

export default WhatWeDoArea

import Sidebar from './Sidebar'
import Dashbord from './pages/Dashbord'
import MyTime from './pages/MyTime'
import Payments from './pages/Payments'
import Request from './pages/Request'
import RequestDetail from './RequestDetial'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { handleRole } from 'contants/helper'
import { useEffect, useState } from 'react'
import Loader from 'components/common/Loader'

export default function DeveloperDashboard() {
	const { path } = useRouteMatch()
	const history = useHistory()
	const { isLogin, userInfo } = useSelector((state) => state?.auth)
	// const [open, setOpen] = useState(true)
	useEffect(() => {
		if (!isLogin) history.push('/login')
		if (isLogin) handleRole(userInfo?.user?.role?.name, history)
	}, [])

	// const handleClick = () => {
	//   setOpen(!open)
	// }
	return (
		<>
			{!isLogin ? (
				<Loader />
			) : (
				<div className='container-fluid pageTop'>
					<div className='row '>
						<Sidebar />
						<div className='col screenHeight padding-20'>
							<Switch>
								{/* <Route exact path={`${path}/my-time`} component={MyTime} /> */}
								<Route exact path={`${path}/payments`} component={Payments} />
								<Route exact path={`${path}/request`} component={Request} />
								<Route
									exact
									path={`${path}/request-detail`}
									component={RequestDetail}
								/>
								<Route path={path} component={MyTime} />
							</Switch>
						</div>
					</div>
					
				</div>
			)}
		</>
	)
}






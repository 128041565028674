import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Layout from 'components/App'
import SecoundLayout from 'components/layout/SecoundLayout'
import AboutPage from 'components/pages/aboutUs/AboutPage'
import BlogPage from 'components/pages/blog/BlogPage'
import BlogDetailsPage from 'components/pages/blogDetails/BlogDetailsPage'
import BlogStandardPage from 'components/pages/blogStandard/BlogStandardPage'
import CommingSoonPage from 'components/pages/commingSoon/CommingSoonPage'
import ContactPage from 'components/pages/contact/ContactPage'
import ContactUs from 'components/pages/contactUs/ContactUs'
import ErrorPage from 'components/pages/Error/ErrorPage'
import ProjectDetailsPage from 'components/pages/projectDetails/ProjectDetailsPage'
import ProjectsPage from 'components/pages/projects/ProjectsPage'
import ServicesPage from 'components/pages/service/Serevices'
import Login from 'components/pages/Form/Login'
import PolicyPage from 'components/common/PolicyPage'
import Support from 'components/common/Support'
import TermsandCondition from 'components/common/TermsandCondition'
import Payments from 'components/pages/dashboard/managerDashboard/pages/Payments'
import Request from 'components/pages/dashboard/managerDashboard/pages/Request'
import ManagerDashboard from 'components/pages/dashboard/managerDashboard/ManagerDashboard'
import Setting from 'components/pages/setting/setting'
import Career from 'components/pages/career/Career'
import Event from 'components/pages/event/Event'
import EventDetail from 'components/pages/event/EventDetail'
import ServiceDetail from 'components/pages/serviceDetail/ServiceDetail'
import BusinessDashboard from 'components/pages/dashboard/businessDashboard/BusinessDashboard'
import AccountDashboard from 'components/pages/dashboard/accountDashboard/AccountDashboard'
import ViewProfile from 'components/pages/dashboard/hrDashboard/pages/ViewProfile'
import DeveloperDashboard from 'components/pages/dashboard/developerDashboard/DeveloperDashboard'
import HrDashboard from 'components/pages/dashboard/hrDashboard/HrDashboard'
import Application from 'components/pages/career/Application'
import Header from 'components/common/Header'
import LoanForm from 'components/pages/LoanForm/LoanForm'
import AdminDashboard from 'components/pages/dashboard/adminDashboard/AdminDashboard'

function App() {
	return (
		<>
			<BrowserRouter basename='/'>
				<Header />
				<Switch>
					<Route exact path='/' component={SecoundLayout} />
					{/* <Route exact path='/home' component={SecoundLayout} /> */}
					{/* <Route exact path='/error' component={ErrorPage} /> */}
					{/* <Route component={ ErrorPage} /> */}
					<Layout>
						<Switch>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/home`}
								component={SecoundLayout}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/about`}
								component={AboutPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/service`}
								component={ServicesPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/service-details`}
								component={ServiceDetail}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/project`}
								component={ProjectsPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/project-details`}
								component={ProjectDetailsPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/feed`}
								component={BlogPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/blog-standard`}
								component={BlogStandardPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/Blog-details`}
								component={BlogDetailsPage}
							/>

							<Route
								exact
								path={`${process.env.PUBLIC_URL}/contact`}
								component={ContactPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/contact-us`}
								component={ContactUs}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/privacy-policy`}
								component={PolicyPage}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/support-policy`}
								component={Support}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/terms-conditions`}
								component={TermsandCondition}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/login`}
								component={Login}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/payments`}
								component={Payments}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/request`}
								component={Request}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/settings`}
								component={Setting}
							/>

							<Route
								exact
								path={`${process.env.PUBLIC_URL}/viewProfile`}
								component={ViewProfile}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/career`}
								component={Career}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/career/application`}
								component={Application}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/event`}
								component={Event}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/eventDetail`}
								component={EventDetail}
							/>
							<Route path={`${process.env.PUBLIC_URL}/developer-dashboard`}>
								<DeveloperDashboard />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/hire-now`}>
								<ContactUs />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/loan-form`}>
								<LoanForm />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/manager-dashboard`}>
								<ManagerDashboard />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/business-dashboard`}>
								<BusinessDashboard />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/account-dashboard`}>
								<AccountDashboard />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/hr-dashboard`}>
								<HrDashboard />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/admin-dashboard`}>
								<AdminDashboard />
							</Route>

							<Route component={ErrorPage} />
						</Switch>
					</Layout>

					<Route
						exact
						path={`${process.env.PUBLIC_URL}/commingsoon`}
						component={CommingSoonPage}
					/>
				</Switch>
			</BrowserRouter>
		</>
	)
}

export default App

import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import Quality from "../Home/animation/quality-standard.json"

function About2Area() {
  const Singlepercentage = 95;
  const singleXmPercentage = 98;
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section className="about-area sec-mar bg-transparent padding-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="about-left dark">
                <div className="title">
                  <span>About us</span>
                  <h2 className="mb-15">Our Quality Standards</h2>
                </div>
                <p>
                 Bugdev is a technology consulting organization that 
                connects strategy, design and engineering services 
                into a seamless workflow devised to support clients every step of the way.
                </p>
                <div className="our-mission">
                  <div className="msn-icon">
                    <i>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/mission-icon.png"
                        }
                        alt="images"
                      />
                    </i>
                  </div>
                  <div className="msn-content">
                    <h5>Our Mission</h5>
                    <p>
                    Our mission is committed 
                    to helping our customers bring their products to 
                    market faster while reducing overall system
                    </p>
                  </div>
                  {/* <div className="cto">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/ctoFounder-dark.png"
                      }
                      alt="imgs"
                    />
                  </div> */}
                </div>
                <div className="cmn-btn justify-content-center">
                  <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/about`}>About more</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="about-right">
                <div className="group-images">
                <Lottie className="lottie-img" loop={true} animationData={Quality} style={{width:'600px', marginTop:'-8rem'}}/>
                  {/* <div className="about-skills">
                    <div className="signle-skill">
                      <CircularProgressbar
                        value={Singlepercentage}
                        text={`${Singlepercentage}%`}
                        className="progress-bar-circle"
                      />

                      <div className="skill-content">
                        <h6>web</h6>
                        <p>Clean Design</p>
                      </div>
                    </div>
                    <div className="signle-skill xsm">
                      <CircularProgressbar
                        value={singleXmPercentage}
                        text={`${singleXmPercentage}%`}
                        className="progress-bar-circle"
                      />
                      <div className="skill-content">
                        <h6>App</h6>
                        <p>Developing</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="features-count" style={{marginTop:'-50px'}}>
            <div className="row">
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div className="single-count">
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/count-1.png"}
                      alt="images"
                    />
                  </i>
                  <div className="counter">
                    <CountUp end={250} delay={2} duration={5} /> <sup>+</sup>
                  </div>
                  <p>Project Completed</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div className="single-count">
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/count-2.png"}
                      alt="images"
                    />
                  </i>
                  <div className="counter">
                    <CountUp end={150} delay={1} duration={5} /> <sup>+</sup>
                  </div>
                  <p>Satisfied Clients</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div className="single-count">
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/count-3.png"}
                      alt="images"
                    />
                  </i>
                  <div className="counter">
                    <CountUp end={150} delay={3} duration={5} /> <sup>+</sup>
                  </div>
                  <p>Expert Teams</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div className="single-count xsm">
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/count-4.png"}
                      alt="images"
                    />
                  </i>
                  <div className="counter">
                    <CountUp end={100} delay={4} duration={5} /> <sup>+</sup>
                  </div>
                  <p>Win Awards</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About2Area;

import React from 'react'

const TimeDifference = ({ createdAt }) => {
	const getTimeDifference = (createdAt) => {
		const currentTime = new Date()
		const createdAtTime = new Date(createdAt)
		const timeDifference = currentTime - createdAtTime

		const minutes = Math.floor(timeDifference / (1000 * 60))
		const hours = Math.floor(timeDifference / (1000 * 60 * 60))
		const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
		const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7))

		if (minutes < 60) {
			return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
		} else if (hours < 24) {
			return `${hours} hour${hours !== 1 ? 's' : ''} ago`
		} else if (days < 7) {
			return `${days} day${days !== 1 ? 's' : ''} ago`
		} else {
			return `${weeks} week${weeks !== 1 ? 's' : ''} ago`
		}
	}

	return (
		<span className='text-white-50 time'>{getTimeDifference(createdAt)}</span>
	)
}

export default TimeDifference

const UserSideBar = ({ userDetail, icons, deptName, isShow, setIsShow }) => {
	return (
		<div className='col-lg-5 col-md-12 left'>
			<div className='card d-flex position-relative detail-card text-center'>
				<div className='profile-img'>
					<img src={userDetail?.profile_picture} alt='' className='profile' />
				</div>
				<div className='ProfileCard'>
					<div className='ViewCard'>
						<h6>{`${userDetail?.user?.first_name} ${userDetail?.user?.last_name}`}</h6>
						<p>{userDetail?.intro_text}</p>
					</div>
					<div className='ProfileContainer'>
						<div className='infoCard'>
							<h6>Department</h6>
							<p>{userDetail?.user?.department?.name}</p>
						</div>
						<div className='infoCard'>
							<h6>Hire Date</h6>
							<p>{userDetail?.joining_date}</p>
						</div>
						<div className='infoCard'>
							<h6>Email</h6>
							<p>{userDetail?.user?.email}</p>
						</div>
						<div className='infoCard'>
							<h6>Employment Status</h6>
							<p>{userDetail?.employee_status}</p>
						</div>
					</div>
				</div>
			</div>

			<div className='card mt-lg-5 functional'>
				<ul className='list-bordered'>
					<li>
						<img
							src={icons.registration.default}
							alt=''
							className='img-fluid id_icon'
						/>
						<button
							onClick={() =>
								setIsShow({
									Information: true,
									UserBankDetail: false,
									UserDocuments: false,
									Skills: false,
								})
							}
							className={`text-highlighted bg-transparent border-0 ${
								isShow?.Information && 'active'
							}`}
						>
							Information
						</button>
						<img
							src={icons.forward.default}
							alt=''
							className='img-fluid arrow'
							style={{ cursor: 'pointer' }}
						/>
					</li>
					<li>
						<img
							src={icons.lock.default}
							alt=''
							className='img-fluid id_icon'
						/>
						<button
							onClick={() =>
								setIsShow({
									Information: false,
									UserBankDetail: false,
									UserDocuments: true,

									Skills: false,
								})
							}
							className={`text-highlighted bg-transparent border-0 ${
								isShow?.UserDocuments && 'active'
							}`}
						>
							Documents
						</button>
						<img
							src={icons.forward.default}
							alt=''
							className='img-fluid arrow'
							style={{ cursor: 'pointer' }}
						/>
					</li>
					<li>
						<img
							src={icons.lock.default}
							alt=''
							className='img-fluid id_icon'
						/>
						<button
							onClick={() =>
								setIsShow({
									Information: false,
									UserBankDetail: true,
									UserDocuments: false,
									Skills: false,
								})
							}
							className={`text-highlighted bg-transparent border-0 ${
								isShow?.UserBankDetail && 'active'
							}`}
						>
							Bank Detail
						</button>
						<img
							src={icons.forward.default}
							alt=''
							className='img-fluid arrow'
							style={{ cursor: 'pointer' }}
						/>
					</li>
					<li>
						<img src={icons.logout.default} alt='' className='img-fluid' />
						<button
							onClick={() =>
								setIsShow({
									Information: false,
									UserBankDetail: false,
									UserDocuments: false,
									Skills: true,
								})
							}
							className={`text-highlighted bg-transparent border-0 ${
								isShow?.Skills && 'active'
							}`}
						>
							Skills
						</button>
						<img
							src={icons.forward.default}
							alt=''
							className='img-fluid arrow'
							style={{ cursor: 'pointer' }}
						/>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default UserSideBar

import React, { useState, useEffect } from 'react'
import { instance } from 'index'
import moment from 'moment'
import Loader2 from 'components/common/Loader2'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

const PaymentDetail = () => {
	const location = useLocation()

	const message = location.state?.message
	const { auth } = useSelector((state) => state)
	const {access} = useSelector((state)=> state.auth.userInfo)
	const [isLoading, setIsLoading] = useState(false)
	const [cardDetail, setCradDetail] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [modal, setModal] = React.useState(false)
    const [isCheck, setIsCheck] = useState(true)
	const [getId, setGetId] = useState('')
	const [user, setUser] = useState()
	const [formValues, setFormValues] = useState({ 
	
		total_paid_loan:'',
		this_month_paid_loan:'',
		
	  })

	  const toggle = () => {
		setModal(!modal)
		setIsCheck(true)
		setFormValues({
			
			total_paid_loan:'',
			this_month_paid_loan:'',
			
		})
	}

	const editToggle = (idData) => {
		setFormValues({
		  ...formValues,
		  total_paid_loan: cardDetail?.total_paid_loan,
		  this_month_paid_loan: cardDetail?.this_month_paid_loan,
		});
		setGetId(idData);
		setModal(!modal);
		setIsCheck(true);
	  };

	const getAllDetail = async (message) => {
		setIsLoading(true)
		try {
			const result = await instance.get(`employee-payment/${message}/`,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})

			if (result?.status === 200) {
				setIsLoading(false)
				setCradDetail(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	const handleStatus = async (e) => {
		setCradDetail((prev) => ({ ...prev, status: e.target.id }))
		const updatedStatus = e.target.id
		try {
			const result = await instance.patch(`employee-payment/${message}/`, {
				status: updatedStatus,
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				toast.success('Status Updated sucessfully!')
			}
		} catch (error) {
			toast.error('Oops! Error while updating status')
		}
	}

	const getEdit = async (id) => {
		try {
			const data = {
				...formValues,
				is_active:true
			}
			const result = await instance.patch(
				`employee-payment/${id}/`,data,{
					headers: {
						'content-type': 'multipart/form-data',
						'Authorization': `Bearer ${access}`,
					},
					}
			)
	
			if (result?.status === 200) {
				toast.success('Request sent sucessfully!')
				setModal(false)
				getAllDetail()
				setFormValues({
					...formValues,
					week: '', 
		            overtime_hours: '',
		            number_of_hours:'',
		            user:null ,
		            status: null,
				})
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getUser = async () =>{
		try{
			const result = await instance.get('user-role/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if(result.status === 200)
			{
				setUser(result?.data)
				console.log("user data :", result?.data)
			}
		} catch(error){
			console.log({error})
		}
	  }
	
	useEffect(() => {
		getAllDetail(message)
	}, [])

	useEffect(()=>{
		getUser();
	},[])
	return (
		<>
			{isLoading && <Loader2 />}

			<section className='tasks-content data-table position-relative'>
				<div className='row'>
					<div className='col-md-12 col-12 tasks-heading d-flex flex-column gap-5'>
						<div className='d-flex justify-content-between '>
							<h4 className='task'> {cardDetail?.title}</h4>
							<div className='cmn-btn'>
					     <button className='mb-0 mt-1' onClick={editToggle}>
						   Add Loan 
					     </button>
				       </div>
						</div>
						{isCheck && (
					<div style={{ display: 'block' }}>
						<Modal
							isOpen={modal}
							toggle={() => setModal(true)}
							className='addtask-popups'
						>
							<ModalBody>
								<h3 className='text-center text-white'>Add Employe Loan</h3>
								<div className='addtask-body'>
									{/* <div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Title:
										</label>
										<input
											type='text'
											className='form-control'
											value={formValues?.title}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, title: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='message-text' className='col-form-label'>
											Description:
										</label>
										<textarea
											className='form-control'
											value={formValues?.description}
											onChange={(e) =>
												setFormValues({
													...formValues,
													description: e.target.value,
												})
											}
											style={{ height: '150px', borderRadius: '20px' }}
											id='message-text'
										></textarea>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Amount:
										</label>
										<input
											type='number'
											className='form-control'
											value={formValues?.amount}
											style={{ borderRadius: '20px', height: '3rem' }}
											onChange={(e) =>
												setFormValues({ ...formValues, amount: e.target.value })
											}
											id='recipient-name'
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Channel:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.channel}
											style={{ 
												borderRadius: '20px', 
												height: '3rem',  
												border:'none' ,
												background:'#212121',
												color:'white'
												}}
											onChange={(e) =>
												setFormValues({ ...formValues, channel: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>paypal</option>
                                        <option style={{color:'white'}}>wise</option>
                                        <option style={{color:'white'}}>remitly</option>
                                        <option style={{color:'white'}}>direct bank</option>
										<option style={{color:'white'}}>other</option>
									</select>
									
									</div>
									<div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Status:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.status}
											style={{ 
												borderRadius: '20px', 
												height: '3rem',  
												border:'none' ,
												background:'#212121',
												color:'white'
												}}
											onChange={(e) =>
												setFormValues({ ...formValues, status: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>completed</option>
                                        <option style={{color:'white'}}>in-progress</option>
                                        <option style={{color:'white'}}>paused</option>
                                        <option style={{color:'white'}}>cancel</option>
									</select>
									
									</div> */}
									<div className='form-group'>
                                      <label htmlFor='recipient-name' className='col-form-label'>
                                        Total Loan:
                                       </label>
                                     <input
                                        type='number'
                                        className='form-control'
                                        value={formValues?.total_paid_loan}
                                        style={{ borderRadius: '20px', height: '3rem' }}
                                        onChange={(e) =>
                                        setFormValues({ ...formValues, total_paid_loan: e.target.value })
                                      }
                                     id='recipient-name'
                                      />
                                    </div>
									<div className='form-group'>
                                    <label htmlFor='recipient-name' className='col-form-label'>
                                     This month paid:
                                    </label>
                                    <input
                                      type='number'
                                      className='form-control'
                                      value={formValues?.this_month_paid_loan}
                                      style={{ borderRadius: '20px', height: '3rem' }}
                                      onChange={(e) =>
                                     setFormValues({ ...formValues, this_month_paid_loan: e.target.value })
                                      }
                                     id='recipient-name'
                                      />
                                    </div>
									{/* <div className='form-group'>
                                    <label htmlFor='recipient-name' className='col-form-label'>
                                      Remaining Loan:
                                    </label>
                                    <input
                                      type='number'
                                      className='form-control'
                                      value={formValues?.remaining_loan}
                                      style={{ borderRadius: '20px', height: '3rem' }}
                                      onChange={(e) =>
                                      setFormValues({ ...formValues, remaining_loan: e.target.value })
                                      }
                                      id='recipient-name'
                                      />
                                    </div> */}
									{/* <div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											User:
										</label>
										<select
											type='text'
											className='form-control'
											value={formValues?.user}
											style={{ 
										    borderRadius: '20px', 
											height: '3rem',  
											border:'none' ,
											background:'#212121',
											color:'white'
										    }}
											onChange={(e) =>
												setFormValues({ ...formValues, user: e.target.value })
											}
											id='recipient-name'
										>
										<option style={{color:'white'}}>select user</option>
										{
											user?.results?.map((item,id)=>{
												return( 
												<option value={item?.id} style={{color:'white'}}>
												{item?.first_name}
												</option>
											)})
										}
									</select>
									
									</div> */}
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='cmn-btn'>
									<button onClick={toggle}>Close</button>
								</div>
								<div className='cmn-btn'>
									<button onClick={()=> getEdit (cardDetail?.id)}>Submit</button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				)}
						<div className='d-flex justify-content-between margin-left-40'>
							<div>
								<p className='title  mb-3'>Date</p>
								<p className='me-3'>
									{moment
										.utc(cardDetail?.created_at.toString())
										.format('MM/DD/YYYY')}
								</p>
							</div>

							<div>
								<p className='title  mb-3'>Amount</p>
								<p className='me-3'>{cardDetail?.amount}</p>
							</div>
							<div>
								<p className='title  mb-3'>Channel</p>
								<p className='me-3'>{cardDetail?.channel}</p>
							</div>
							<div>
								<p className='title  mb-3'>Total Loan</p>
								<p className='me-3'>
									{cardDetail?.total_paid_loan === 'field'
										? '0.0'
										: cardDetail?.total_paid_loan}
								</p>
							</div>
							<div>
								<p className='title  mb-3'>Paid Loan</p>
								<p className='me-3'>
									{cardDetail?.this_month_paid_loan === 'payment fields'
										? '0.0'
										: cardDetail?.this_month_paid_loan}
								</p>
							</div>
							<div>
								<p className='title  mb-3'>Remaining Loan</p>
								{console.log('loan', cardDetail?.total_paid_loan)}
								<p className='me-3'>
									{cardDetail?.remaining_loan}
								</p>
							</div>
						</div>

						<div className='margin-left-40'>
							<div>
								<div className='d-flex justify-content-between'>
								<p className='title  mb-3'>Description </p>
								<div className='status-transtion'>
								<div className='position-relative'>
									<p
										className={`status text-capitalize ${cardDetail?.status}`}
										onClick={() => {
											setIsOpen((prevState) => !prevState)
										}}
									>
										{cardDetail?.status}
									</p>

									{cardDetail?.user?.id !== auth?.userInfo?.user?.id && (
										<div
											className='status-dropdown'
											style={{
												opacity: isOpen ? 1 : 0,
												visibility: isOpen ? 'visible' : 'hidden',
												left: ' -90%',
												bottom: '-100%',
											}}
										>
											<p
												className=' text-capitalize completed '
												id='completed'
												onClick={handleStatus}
											>
												Completed
											</p>
											<p
												className=' text-capitalize in-pending'
												id='in-Pending'
												onClick={handleStatus}
											>
												Pending
											</p>
											<p
												className=' text-capitalize  canceled'
												id='canceled'
												onClick={handleStatus}
											>
												Canceled
											</p>
											<p
												className=' text-capitalize  paused'
												id='paused'
												onClick={handleStatus}
											>
												Paused
											</p>
										</div>
									)}
								</div>
							</div>
							 </div>
								<p className='text-white'>{cardDetail?.description}</p>
								
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default PaymentDetail

import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import ServiceDetailsWrapper from './ServiceDetailsWrapper';
import Footer from 'components/common/Footer';

function ServiceDetail() {
  return (
    <>
      <Breadcrumb pageName='Service Details' />
      <ServiceDetailsWrapper />
      <Footer/>
    </>
  );
}

export default ServiceDetail;

import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { instance } from 'index';
import { useSelector } from 'react-redux';

const ApplicationChart = () => {
 const {access} = useSelector((state)=> state.auth.userInfo)
 const [application, setApplication] = useState([])
 const [months, setMonths] = useState([])

 const getApplication = async ()=>{
  try{
    const result = await instance.get('job-application/',{
      headers:{
        authorization:`Bearer ${access}`
      },
    })
    if(result.status === 200)
    {
      const applicationdataResponse = result?.data?.results;
      const currentYear = new Date().getFullYear();
      const monthlyPayments = {}
      
      const monthlyApplications={
        frontend : Array(12).fill(0),
        backend : Array(12).fill(0),
        bd:Array(12).fill(0),
        hr:Array(12).fill(0),
      };

    applicationdataResponse.forEach((application)=> {
      const createdDate = new Date(application.created_at)
      const applicationYear = createdDate.getFullYear();

      if(applicationYear === currentYear){
        const month = createdDate.getMonth();
        const status = application.career?.position_type;

        if(monthlyApplications.hasOwnProperty(status)){
          monthlyApplications[status][month] ++;
        }
      }
    });
     setApplication(monthlyApplications)
      const allMonths = Array.from({ length: 12 }, (_, i) => {
					const monthName = new Date(null, i).toLocaleString('en-US', {
						month: 'long',
					})
					return {
						month: monthName,
						totalPayment: monthlyPayments[monthName] || 0,
						
					}
				})

        setMonths(allMonths)
    }

  } catch(error){
    console.log({error})
  }
 }

 useEffect(()=>{
  getApplication();
 },[])

 const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1
  
  const data = {
    labels: months.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: 'Frontend',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: application?.frontend,
      },
      {
        label: 'Backend',
        backgroundColor: 'rgba(192, 75, 75, 0.2)',
        borderColor: 'rgba(192, 75, 75, 1)',
        borderWidth: 1,
        data: application?.backend,
      },
      {
        label: 'BD',
        backgroundColor: 'green)',
        borderColor: 'green',
        borderWidth: 1,
        data: application?.bd,
      },
    ],
  };

  // Define chart options
  const options = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        position: 'right',
      },
    },
  };

  return (
    <div className="unit-sold-vs-profit-chart" style={{width:'95%'}}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ApplicationChart;

import React from 'react'

const Skills = ({ userDetail }) => {
	return (
		<div className='col-lg-7 col-md-12 right px-3'>
			<div className='card'>
				<div className='skills-container'>
					{userDetail?.skills?.map((skill, index) => (
						<div className='skills-item' key={index}>
							<span className='skill-text'>{skill?.name}</span>
							<span className='skill-close' id={skill?.id}>
								<i className='fas fa-times'></i>
							</span>
						</div>
					))}
						{/* <input
							type='text'
							placeholder='Add new skill'
							className='skills-input'
						/> */}
				</div>
			</div>
		</div>
	)
}

export default Skills

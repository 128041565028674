import React from "react";
import { Pie } from "react-chartjs-2";

const MapChart = () => {
  // Sample data for ction
  const data = {
    labels: ["Satisfied", "Neutral", "Dissatisfied"],
    datasets: [
      {
        data: [80, 15, 5], // Replace with your actual data
        backgroundColor: ["green", "yellow", "red"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="satisfaction-chart" style={{width:"95%"}}>
      <Pie data={data} options={options} height={450} />
    </div>
  );
};

export default MapChart;

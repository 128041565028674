import React from 'react'
import Breadcrumb from 'components/common/Breadcrumb'
import Footer from 'components/common/Footer'
import Form from './Form'

const LoanForm = () => {
	return (
		<>
			<Breadcrumb pageName='Loan Form' />
			<div className='contact-area sec-mar'>
                <Form />
            </div>
			<Footer />
		</>
	)
}

export default LoanForm

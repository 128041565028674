import React from 'react'
import LetsTalkArea from '../../common/LetsTalkArea'
import TestimonialArea from '../../common/TestimonialArea'
import WhyChooseUsArea from '../../common/WhyChooseUsArea'
import HeroArea from '../Home/HeroArea'
import OurPartnerArea from '../Home/OurPartnerArea'
import PortfolioArea from '../Home/PortfolioArea'
import ServiceArea from '../Home/ServiceArea'
import About2Area from './About2Area'
import ServicePrice from '../service/ServicePrice'

function HomePage2(props) {
	return (
		<>
			<HeroArea />
			<ServiceArea />
			<About2Area />
			<OurPartnerArea />
			<PortfolioArea black='' />
			{/* <WhyChooseUsArea black='' light='dark' lable='progressbar-label' /> */}
			<ServicePrice/>
			<TestimonialArea />
			<LetsTalkArea />
		</>
	)
}

export default HomePage2

import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ProjectProcess from "./ProjectProcess";
import ReletedProject from "./ReletedProject";
import Footer from "components/common/Footer";
import WhyChooseUsArea from "components/common/WhyChooseUsArea";
import LetsTalkArea from "components/common/LetsTalkArea";

function ProjectDetailsPage() {
  return (
    <>
      <Breadcrumb pageName="Project Details" />
      <div className="project-details-area sec-mar padding-20">
        <div className="container">
          <ProjectProcess />
          {/* <ReletedProject /> */}
          <WhyChooseUsArea/>
          
        </div>
      </div>
      <LetsTalkArea/>
      <Footer/>
    </>
  );
}

export default ProjectDetailsPage;
